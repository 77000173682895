import { useMemo } from "react";
import Styles from "./style.module.scss";
import { Grid, InfoCard, Section } from "../Second";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import moment from "moment";
import { IDR } from "@Helpers/IDR";

export default function FourthSection({
  positionsForm,
  selectedButtonBPJS,
  selectedValueBPU,
}) {
  const template = useMemo(() => {
    return [
      {
        title: "Position",
        component: (
          <Grid gridTemplateColumns="1fr">
            {positionsForm?.map((p, pI) => (
              <PositionCard
                key={pI}
                positionName={p?.positionName}
                dedicatedRO={p?.dedicatedROName}
                qualifications={p?.qualifications}
                citiesData={p?.citiesData?.filter((obj) => obj?.isApplied)}
                selectedButtonBPJS={selectedButtonBPJS}
                selectedValueBPU={selectedValueBPU}
              />
            ))}
          </Grid>
        ),
      },
    ];
  }, [positionsForm, selectedButtonBPJS, selectedValueBPU]);

  return (
    <div className={Styles.container}>
      {template?.map((t, tI) => (
        <Section key={tI} title={t?.title} component={t?.component} />
      ))}
    </div>
  );
}

export const DeploymentCard = ({ role, delegations }) => {
  return (
    <div className={Styles.dCard}>
      <span>{role}</span>
      {delegations?.length ? (
        <div className={Styles.dContent}>
          {delegations?.map((u, uI) => (
            <DelegationUserCard
              key={uI}
              name={u?.name}
              profilePicture={""}
              role={`${role} ${uI + 1}`}
            />
          ))}
        </div>
      ) : (
        <div className={Styles.dEmpty}>
          <img src={Images.FILE_AND_FOLDER} alt="" />
          <span>Informasi delegasi masih kosong</span>
        </div>
      )}
    </div>
  );
};

export const DelegationUserCard = ({ profilePicture, name, role, isPIC }) => {
  return (
    <div className={Styles.duCard}>
      <img src={profilePicture || Images.AVA_DEFAULT} alt="" />
      <div>
        <span>{name}</span>
        <p>{role}</p>
      </div>
      {/* {
        isPIC
        &&
        <p>PIC</p>
      } */}
    </div>
  );
};

export const PositionCard = ({
  positionName,
  dedicatedRO,
  qualifications = [],
  citiesData = [],
  selectedButtonBPJS,
  selectedValueBPU,
}) => {
  const template = useMemo(() => {
    return [
      {
        title: "Job Qualification",
        component: (
          <div className={Styles.qContainer}>
            <div className={`${Styles.qHeader} ${Styles.qGrid}`}>
              <span>Title</span>
              <span>Qualification</span>
            </div>
            <div className={Styles.qContent}>
              {qualifications?.map((q, qI) => (
                <div key={qI} className={`${Styles.qEach} ${Styles.qGrid}`}>
                  <apan className={`${Styles.qName}`}>{q?.name}</apan>
                  <span>
                    {q?.value} {q?.denom}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ),
      },
      {
        title: "City",
        component: (
          <div className={Styles.cWrapper}>
            {citiesData?.map((c, cI) => (
              <div key={cI} className={Styles.cEach}>
                <div className={Styles.cHeader}>
                  <Icon icon={"frame-4683"} size={22} />
                  <span>{c?.cityName}</span>
                </div>

                <Grid gridTemplateColumns="1fr">
                  <Grid gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))">
                    <InfoCard
                      title="Total"
                      description={c?.amount}
                      backgroundColor="#f5f5f5"
                    />
                    <InfoCard
                      title="Salary"
                      description={`${
                        c?.salaryValue ? IDR.format(c?.salaryValue) : "RP 0"
                      } • ${c?.salaryDenom || "Bulanan"}`}
                      backgroundColor="#f5f5f5"
                    />
                    <InfoCard
                      title="Expected date"
                      description={moment(c?.expectedDate)?.format("LL")}
                      backgroundColor="#f5f5f5"
                    />
                  </Grid>
                  <Grid>
                    {[
                      {
                        title: "Allowance",
                        values: c?.allowances?.filter(
                          (all) => all?.type !== "BPU"
                        ),
                      },
                      {
                        title: "Personal Work Equipment",
                        values: c?.personalEquipments,
                      },
                    ]?.map((a, aI) => (
                      <div key={aI} className={Styles.aCard}>
                        <span>{a?.title}</span>
                        {a?.values?.length ? (
                          <div className={Styles.aWrapper}>
                            {a?.values?.map((o, oI) => (
                              <div key={oI}>
                                <span>{o?.name}</span>
                                <span>
                                  {IDR.format(o?.value)}{" "}
                                  {a.title === "Allowance" ? "•" : null}{" "}
                                  {a.title === "Allowance"
                                    ? o?.denom || "Bulanan"
                                    : null}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className={Styles.dEmpty}>
                            <img src={Images.FILE_AND_FOLDER} alt="" />
                            <span>Data masih kosong</span>
                          </div>
                        )}
                      </div>
                    ))}
                  </Grid>

                  {selectedButtonBPJS === "BPU" ? (
                    <div className={Styles.BoxBPU}>
                      <span>BPJS • {selectedValueBPU}</span>
                      <div className={Styles.bpjsBox}>
                        <div>
                          <span>
                            {selectedValueBPU?.includes("Tagih Ke Perusahaan")
                              ? "Perusahaan"
                              : "Karyawan"}
                          </span>
                          <div>
                            <span>JKK</span>
                            <span>{c?.jkk ? IDR.format(c?.jkk) : "Rp 0"}</span>
                          </div>

                          <div>
                            <span>JKM</span>
                            <span>{c?.jkm ? IDR.format(c?.jkm) : "Rp 0"}</span>
                          </div>

                          <div>
                            <span>JHT</span>
                            <span>{c?.jht ? IDR.format(c?.jht) : "Rp 0"}</span>
                          </div>
                        </div>

                        {selectedValueBPU ===
                        "Tagih Ke Perusahaan & Karyawan" ? (
                          <div>
                            <span>Karyawan</span>
                            <div>
                              <span>JKK</span>
                              <span>
                                {c?.jkk1 ? IDR.format(c?.jkk1) : "Rp 0"}
                              </span>
                            </div>

                            <div>
                              <span>JKM</span>
                              <span>
                                {c?.jkm1 ? IDR.format(c?.jkm1) : "Rp 0"}
                              </span>
                            </div>

                            <div>
                              <span>JHT</span>
                              <span>
                                {c?.jht1 ? IDR.format(c?.jht1) : "Rp 0"}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </Grid>
              </div>
            ))}
          </div>
        ),
      },
    ];
  }, [citiesData, qualifications, selectedButtonBPJS, selectedValueBPU]);

  return (
    <div className={Styles.pCard}>
      <div className={Styles.pHeader}>
        <div className={Styles.leftPHeader}>
          <Icon icon={"contracted"} size={22} color={"#9E9E9E"} />
          <span>{positionName}</span>
        </div>

        <div className={Styles.dedicatedRO}>
          <span>Recruitment Officer Dedicated :</span>
          <div className={Styles.Ro}>
            <img src={Images.AVA_DEFAULT} alt="" />
            <p>{dedicatedRO}</p>
          </div>
        </div>
      </div>
      <div className={Styles.pContent}>
        {template?.map((t, tI) => (
          <div key={tI} className={Styles.pEach}>
            <span>{t?.title}</span>
            {t?.component}
          </div>
        ))}
      </div>
    </div>
  );
};
