import Spinner from "@Atom/Spinner";
import Styles from "./styles.module.scss";
// import Images from "@Theme/Images";
import DoneGif from "../../../../../../../assets/Gifs/done.gif";
// import Error from "../../../../../../../assets/Images/warning-red.png";
import Icon from "@Atom/Icon";

export default function NotifMessageModal({
  isSuccessSendCredential,
  isSuccessData,
  errorData,
  handleClose,
}) {
  return (
    <div
      className={`${Styles.modal} ${
        !isSuccessSendCredential || errorData ? Styles.modalGap : ""
      }`}
    >
      <div className={Styles.imagesBox}>
        {!isSuccessSendCredential ? (
          <Spinner type={"contract"} />
        ) : errorData ? (
          <Icon icon={"cross-circle"} size={100} color={"#ff3e13"} />
        ) : (
          <img
            src={DoneGif}
            style={{
              width: "150px",
              height: "150px",
              aspectRatio: 1,
              objectFit: "cover",
            }}
            alt=""
          />
        )}
      </div>

      <div className={Styles.descBox}>
        <span className={`${errorData && Styles.red}`}>
          {!isSuccessSendCredential
            ? "Loading..."
            : errorData
            ? "Oppss..."
            : isSuccessData?.name === "send-credential" ||
              isSuccessData?.name === "send-survey"
            ? "Terkirim"
            : "Hapus kontak berhasil"}
        </span>
        {!isSuccessSendCredential ? (
          <span>Mohon tunggu sebentar, data sedang di proses</span>
        ) : errorData ? (
          <span className={`${Styles.error}`}>{errorData?.error || "-"}</span>
        ) : isSuccessData?.name === "send-credential" ||
          isSuccessData?.name === "send-survey" ? (
          <span>
            {isSuccessData?.name === "send-credential"
              ? "Berhasil mengirim akun ke"
              : "Berhasil mengirim survey ke"}{" "}
            <span>{isSuccessData?.data?.name || "-"}</span> klien{" "}
            <span>{isSuccessData?.data?.Client?.name || "-"}</span>
          </span>
        ) : isSuccessData?.name === "delete-account" ? (
          <span>
            Akun <span>{}</span>
            {isSuccessData?.data?.name || "-"} telah berhasil dihapus dari
            daftar kontak
          </span>
        ) : (
          <span>Mohon tunggu sebentar, data sedang di proses</span>
        )}
      </div>

      {isSuccessSendCredential ? (
        <div
          className={`${Styles.buttonSection} ${errorData && Styles.noMargin}`}
          onClick={handleClose}
        >
          Tutup
        </div>
      ) : null}
    </div>
  );
}
