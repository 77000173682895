import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useState } from "react";
import Dropdown from "@Atom/DropDown";
import useWindowSize from "@Hooks/useWindowSize";
// import { fileToBase64 } from "@Helpers/convertFileToBase64";
import InputField from "@Molecule/InputField";

export default function ModalOptionalDocument({
  handleClose,
  syncData,
  files,
  setValue,
  value,
}) {
  const [type, setType] = useState("");
  const [otherType, setOtherType] = useState("");
  const { width } = useWindowSize();

  const handleDocument = () => {
    setValue((prev) => ({
      ...prev,
      optionalDocument: [
        ...prev.optionalDocument,
        {
          type: type?.toLowerCase() === "lainnya" ? otherType : type,
          file: files,
          name: files?.name,
        },
      ],
    }));

    // fileToBase64(files, (base64Data) => {
    //   // const prev = JSON.parse(sessionStorage.getItem("optionalDocument"));
    //   // if (prev) {
    //   //   sessionStorage.setItem(
    //   //     "optionalDocument",
    //   //     JSON.stringify([
    //   //       ...prev,
    //   //       {
    //   //         name: files?.name,
    //   //         file: base64Data,
    //   //         type: type?.toLowerCase() === "lainnya" ? otherType : type,
    //   //       },
    //   //     ])
    //   //   );
    //   // } else {
    //   //   sessionStorage.setItem(
    //   //     "optionalDocument",
    //   //     JSON.stringify([
    //   //       {
    //   //         name: files?.name,
    //   //         file: base64Data,
    //   //         type: type?.toLowerCase() === "lainnya" ? otherType : type,
    //   //       },
    //   //     ])
    //   //   );
    //   // }
    // });

    handleClose();
    setType("");
    setOtherType("");
  };

  return (
    <div className={Styles.containerModal}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <span>Dokumen Opsional</span>
          <Icon
            icon={"cross"}
            size={24}
            className={Styles.iconCross}
            onClick={() => {
              handleClose();
              setType("");
              setOtherType("");
            }}
          />
        </div>

        <div className={Styles.bottom}>
          Silahkan pilih jenis file yang anda unggah sebelum melakukan
          penyimpanan file tersebut
        </div>
      </div>

      <div className={Styles.documentBox}>
        <div className={Styles.iconDocument}>
          <Icon icon={"document-text"} color={"#1571DE"} size={24} />
        </div>
        <span>{files?.name || "-"}</span>
      </div>

      <div className={Styles.dropdownOptional}>
        <span>Pilih Jenis Dokumen</span>
        <div className={Styles.dropdown}>
          <Dropdown
            value={type}
            setValue={setType}
            placeholder={"Belum Dipilih"}
            options={[
              ...(syncData
                ?.filter(
                  (sync) =>
                    sync?.type === "Document" &&
                    !value?.optionalDocument?.some(
                      (d) =>
                        d?.type?.toLowerCase() === sync?.value?.toLowerCase()
                    )
                )
                ?.map((el, idx) => ({
                  id: idx,
                  name: el?.value,
                })) || []),
              { id: -1, name: "Lainnya" }, // Data hardcoded
            ]}
            autoClose
            overFlow
            top={width > 768 ? false : true}
          />
        </div>
      </div>

      {type?.toLowerCase() === "lainnya" && (
        <div className={Styles.dropdownOptional}>
          <span>Tuliskan Nama File</span>
          <div className={Styles.dropdown}>
            <InputField
              value={otherType}
              onChange={(e) => setOtherType(e?.target?.value)}
              placeholder={"Nama File"}
              required
            />
          </div>
        </div>
      )}

      <div className={Styles.buttonSection}>
        <div
          onClick={() => {
            handleClose();
            setType("");
            setOtherType("");
          }}
        >
          Batal
        </div>
        <div
          className={`${
            !type
              ? Styles.disabled
              : type?.toLowerCase() === "lainnya"
              ? !files ||
                !otherType ||
                value?.optionalDocument?.find(
                  (d) => d?.type?.toLowerCase() === otherType?.toLowerCase()
                )
                ? Styles.disabled
                : ""
              : !files
              ? Styles.disabled
              : ""
          }`}
          onClick={() => {
            if (!type) {
              return;
            } else {
              if (type?.toLowerCase() === "lainnya") {
                if (
                  !files ||
                  !otherType ||
                  value?.optionalDocument?.find(
                    (d) => d?.type?.toLowerCase() === otherType?.toLowerCase()
                  )
                ) {
                  return;
                } else {
                  handleDocument();
                }
              } else {
                if (!files) {
                  return;
                } else {
                  handleDocument();
                }
              }
            }
          }}
        >
          Simpan
        </div>
      </div>
    </div>
  );
}
