import Images from "@Theme/Images";
import PerArea from "./PerArea";
import Styles from "./style.module.scss";

export default function CostEstimationSection({
  data,
  selectedLogs,
  isHC,
  updateLogs,
}) {
  return (
    <div className={Styles.container}>
      <div className={Styles.header} id="Management Fee">
        <h3>Cost Estimation</h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.areas}>
          {data?.costEstimation?.areaCosts?.map((each, i) => (
            <PerArea
              key={i}
              data={each}
              manfee={data?.order?.manfee}
              ppn={data?.order?.orderComprehensives?.PPN?.toLowerCase()}
              pph21={data?.order?.orderComprehensives?.PPH21?.toLowerCase()}
              selectedLogs={selectedLogs}
              isHC={isHC}
              updateLogs={updateLogs}
            />
          ))}
        </div>
        {/* <div className={Styles.areas}>
          <SubTotal />
        </div> */}

        <div
          className={`${Styles.TOP} ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Term of Payment"
            ) &&
            Styles.borderYellow
          } ${
            isHC &&
            selectedLogs?.data?.remark === "Perubahan Term of Payment" &&
            Styles.yellow
          }`}
          id="Term of Payment"
        >
          <img src={Images?.PAYDAY} alt="top" />
          <span>Term Of Payment</span>
          <span>
            TOP {data?.orderDetail?.termOfPayment}{" "}
            {data?.orderDetail?.termOfPayment > 1 ? "Days" : "Day"}{" "}
          </span>
        </div>
      </div>
    </div>
  );
}
