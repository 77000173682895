/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import StaticProgressBar from "@Atom/StaticProgressBar";
import Tooltip from "@Atom/Tooltip";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
import useWindowSize from "@Hooks/useWindowSize";
import DashboardSyncComponent from "@Molecule/DashboardSyncComponent";
import Pagination from "@Molecule/Pagination";
import LoadingSkeleton from "@Pages/Auth/Manager/Client/ClientList/Skeleton";
import Images from "@Theme/Images";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDetailModal from "./ViewDetailModal";
import Styles from "./style.module.scss";
// import Images from "@Theme/Images";
// import ModalWrapper from "@Atom/ModalWrapper";
// import OwnerShipModal from "@Molecule/_modal/OwnerShipModal";

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     height: 30
//   }
// }))(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, firstColMinWidth, rbmView } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const TABLE_HEAD = useMemo(() => {
    if (!rbmView) {
      return [
        { id: "name", label: "Client", alignRight: false },
        // { id: "location", label: "Location", alignRight: false },
        { id: "email", label: "Email & Phone Number", alignRight: false },
        // { id: "phone", numeric: true, label: "Phone", alignRight: false },
        {
          id: "quota",
          numeric: true,
          label: "Jumlah Permintaan & Terpenuhi",
          alignRight: false,
        },
      ];
    } else {
      return [
        { id: "name", label: "Client", alignRight: false },
        // { id: "location", label: "Location", alignRight: false },
        { id: "email", label: "Email & Phone Number", alignRight: false },
        // { id: "phone", numeric: true, label: "Phone", alignRight: false },
        {
          id: "quota",
          numeric: true,
          label: "Jumlah Permintaan & Terpenuhi",
          alignRight: false,
        },
        {
          id: "action",
          // numeric: true,
          label: "",
          alignRight: false,
        },
      ];
    }
  }, [rbmView]);

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#fff",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align={headCell.id === "totalManPower" ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              index === 0 ? `${Styles.firstHeadCell}` : `${Styles.hideOnMobile}`
            }
            sx={{
              minWidth: index === 0 ? firstColMinWidth : "",
              backgroundColor: "#fff",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key={Math.random()}
          padding={"normal"}
          className={`${Styles.showOnMobile}`}
          align="right"
        >
          <span>Information</span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function ClientListLayout({
  clients,
  totalPage,
  ownClientAmount = 0,
  serviceClientAmount = 0,
  value,
  setValue,
  selectedPage,
  setSelectedPage,
  pageList,
  setPageList,
  pages,
  setPages,
  searchTerm,
  setSearchTerm,
  handleClickAdd,
  activeType,
  setActiveType,
  rbmView = false,
  syncData,
  isLoadingSync = false,
  isLoadingTable = false,
  ownerShipModal,
  setOwnerShip,
  trigger = "",
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const { width } = useWindowSize();
  const query = useQuery();

  const navigate = useNavigate();

  const [selectedClient, setSelectedClient] = useState(null);

  const ref = useRef(null);
  const [isActive, setIsActive] = useState(null);

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(null);
      }
    },
    [ref, isActive]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "900px";
    }
    return width;
  }, [windowSize]);

  const firstColMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "250px";
    }
    return width;
  }, [windowSize]);

  useEffect(() => {
    if (selectedClient !== null) {
      if (boxOutsideClick === false) {
        setSelectedClient(null);
      }
    }
  }, [boxOutsideClick, selectedClient]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClickRow = (e, type, each) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "open") {
      setSelectedClient(each);
    } else if (type === "navigate" && selectedClient === null) {
      if (rbmView) {
        navigate(
          `${pathname}/details?n=${each?.name}&s=&c=${
            each?.code
          }&tt=${query?.get("t")}&p=1&l=10&t=companyInfo&v=yes&u=${query.get(
            "u"
          )}`
        );
      } else {
        navigate(
          `${pathname}/details?n=${each?.name}&c=${each?.code}&tt=${query?.get(
            "t"
          )}&h=${String(Math.random()).slice(2)}`
        );
      }
      //
    }
  };

  const types = [
    {
      title: "My Client",
      value: "1",
      amount: ownClientAmount,
    },
    {
      title: "Service Client",
      value: "2",
      amount: serviceClientAmount,
    },
  ];

  useEffect(() => {
    const previousPage = selectedPage;
    if (activeType === "1") {
      if (ownClientAmount === 1 || ownClientAmount === 0) {
        setSelectedPage(1);
      } else {
        const validPage = Math.max(1, Math.min(previousPage, totalPage));
        setSelectedPage(validPage);
      }
    } else if (activeType === "2") {
      if (serviceClientAmount === 1 || serviceClientAmount === 0) {
        setSelectedPage(1);
      } else {
        const validPage = Math.max(1, Math.min(previousPage, totalPage));
        setSelectedPage(validPage);
      }
    }
  }, [
    activeType,
    selectedPage,
    ownClientAmount,
    serviceClientAmount,
    totalPage,
    setSelectedPage,
    // searchTerm,
  ]);

  return (
    <div className={Styles.outerWrapper}>
      {rbmView && (
        <DashboardSyncComponent
          syncData={syncData}
          isLoading={isLoadingSync}
          isDetailRbm={true}
        />
      )}
      <div
        //  style={{display: 'none'}}
        className={Styles.types}
      >
        {types?.map((t, tI) => (
          <div
            key={tI}
            onClick={() => {
              setActiveType(t?.value);
              setSelectedPage(1);
            }}
            className={activeType === t?.value ? Styles.active : ""}
          >
            <span>{t?.title}</span>
            <div>{t?.amount}</div>
          </div>
        ))}
      </div>
      <div className={Styles.innerWrapper}>
        <div className={Styles.header}>
          <div
            className={`${Styles.hTop} ${
              activeType === "2" ? Styles.centered : ""
            }`}
          >
            <h3 className={activeType === "2" ? Styles.centered : ""}>
              Daftar Klien
            </h3>
            {
              width > 768 && activeType === "1" && !rbmView && (
                <button onClick={() => navigate(pathname + "/add-new-client")}>
                  <span>Add Client</span>
                  <Icon icon={"add-circle"} size={20} />
                </button>
              )
              // </div>
            }
          </div>
          <div className={Styles.hBottom}>
            <div className={Styles.searchbarWrapper}>
              <SearchBar
                value={searchTerm}
                onChange={(e) => setSearchTerm(e?.target?.value)}
                placeholder="Search client name"
              />
            </div>
            <div className={Styles.indicators}>
              <div className={Styles.indicator}>
                <div className={Styles.hired} />
                <p>Jumlah Terpenuhi</p>
              </div>
              <div className={Styles.indicator}>
                <div className={Styles.vendor} />
                <p>
                  <span>Vendor</span> Lain
                </p>
              </div>
              <div className={Styles.indicator}>
                <div className={Styles.vacant} />
                <p>
                  <span>Vacant</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {isLoadingTable ||
        activeType !== query.get("t") ||
        searchTerm !== query.get("n") ||
        selectedPage != query.get("p") ||
        value != query.get("l") ||
        trigger != query.get("tr") ? (
          <div className={Styles.tableWrapper}>
            <LoadingSkeleton
              totalPage={totalPage}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              limit={value}
              setLimit={setValue}
            />
          </div>
        ) : (
          <div className={Styles.tableWrapper}>
            <TableContainer
              className={`${Styles.tableContainer} ${
                clients?.length === 0 && Styles.noMinHeight
              }`}
            >
              <Table className={Styles.table} sx={{ minWidth: tableMinWidth }}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  values={clients}
                  firstColMinWidth={firstColMinWidth}
                  rbmView={rbmView}
                />
                <TableBody>
                  {stableSort(clients, getComparator(order, orderBy))?.map(
                    (each, i) => {
                      return (
                        <TableRow
                          key={i}
                          className={`${
                            i % 2 === 1 ? Styles.evenRow : Styles.oddRow
                          } ${Styles.tableRow} ${!rbmView && Styles.cursor}`}
                          onClick={(e) => {
                            if (!rbmView) {
                              handleClickRow(e, "navigate", each);
                            }
                          }}
                        >
                          <TableCell
                            className={`${Styles.tableCell} ${Styles.firstCell}`}
                          >
                            <div className={Styles.link}>
                              <span>
                                <Icon
                                  icon="company"
                                  color="#1571DE"
                                  size={22}
                                />
                              </span>
                              <div>
                                <span className={Styles.linkText}>
                                  {each?.name}
                                </span>
                                <span>{each?.province}</span>
                              </div>
                            </div>
                          </TableCell>
                          {/* <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      {each?.province}
                    </TableCell> */}
                          <TableCell
                            className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                          >
                            <div className={Styles.contacts}>
                              <div>
                                <Icon
                                  icon={"email"}
                                  size={20}
                                  color={"#1571DE"}
                                />
                                <span>{each?.email || "no email"}</span>
                                <Icon
                                  className={Styles.duplicate}
                                  icon={"duplicate"}
                                  size={20}
                                  color={"#C0DDFF"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(each.email);
                                    // .then(() => setShowNotif(true))
                                  }}
                                />
                              </div>
                              <div>
                                <Icon
                                  icon={"phone"}
                                  size={20}
                                  color={"#1571DE"}
                                />
                                <span>{each?.phoneNumber || "-"}</span>
                                <Icon
                                  className={Styles.duplicate}
                                  icon={"duplicate"}
                                  size={20}
                                  color={"#C0DDFF"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(
                                      each.phoneNumber
                                    );
                                    // .then(() => setShowNotif(true))
                                  }}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                          >
                            {/* {each?.totalManPowers || 0} */}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Tooltip
                                content={
                                  <div className={Styles.tooltip}>
                                    <div className={Styles.quotaBox}>
                                      <span>Kuota</span>
                                      <span>{each?.quota || 0}</span>
                                    </div>

                                    <div className={Styles.content}>
                                      <div className={Styles.list}>
                                        <div>
                                          <div
                                            className={Styles.blueDark}
                                          ></div>
                                          <span>Terpenuhi</span>
                                        </div>

                                        <span>{each?.fulfillment || 0}</span>
                                      </div>

                                      <div className={Styles.list}>
                                        <div>
                                          <div className={Styles.blueSky}></div>
                                          <span>Vendor</span>
                                        </div>

                                        <span>{each?.vendor || 0}</span>
                                      </div>

                                      <div className={Styles.list}>
                                        <div>
                                          <div className={Styles.grey}></div>
                                          <span>Vacant</span>
                                        </div>

                                        <span>{each?.vacant || 0}</span>
                                      </div>
                                    </div>

                                    <div
                                      className={Styles.detail}
                                      onClick={() => {
                                        if (rbmView) {
                                          navigate(
                                            `fulfillment?c=${each?.code}&u=${
                                              query.get("u") || ""
                                            }&fr=`
                                          );
                                        }
                                      }}
                                    >
                                      <span>Lihat Detail</span>
                                    </div>
                                  </div>
                                }
                                direction={i === 0 ? "bottom" : "top"}
                                backgroundColor="#fff"
                                boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
                                tooltipMargin={i === 0 ? "" : "125px"}
                                isV2
                                padding="0px"
                                width="200px"
                                bottomMargin={i === 0 ? "-120px" : ""}
                              >
                                <StaticProgressBar
                                  fulfilledAmount={
                                    each?.fulfillment || each?.fullfillment || 0
                                  }
                                  middleFulfilledText={`${Math.floor(
                                    ((each?.fulfillment ||
                                      each?.fullfillment ||
                                      0) /
                                      (each?.quota || 0)) *
                                      100
                                  )}%`}
                                  // fulfilledText={`${each?.fulfillment || 0} (${Math.floor(+each?.fulfillment / +each?.quota * 100)}%)`}
                                  candidatesAmount={
                                    each?.fulfillment ||
                                    each?.fullfillment ||
                                    0 + each?.vendor ||
                                    0
                                  }
                                  candidatesText={each?.vendor || 0}
                                  requestAmount={each?.quota || 0}
                                  vacantAmount={each?.vacant || 0}
                                  isV3
                                  borderRadius="0px"
                                  height={width > 768 ? "32px" : "28px"}
                                />
                              </Tooltip>
                            </div>
                          </TableCell>

                          <TableCell
                            className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                            align="right"
                          >
                            <button
                              onClick={(e) => handleClickRow(e, "open", each)}
                            >
                              See More
                            </button>
                          </TableCell>
                          {rbmView ? (
                            <TableCell className={`${Styles.tableCell}`}>
                              <div className={Styles.actionWrapper}>
                                <div
                                  className={Styles.actionBox}
                                  onClick={() => {
                                    setIsActive(each);
                                  }}
                                >
                                  <Icon icon={"three-dots"} size={16} />
                                </div>

                                {isActive === each && (
                                  <div
                                    className={`${
                                      (clients?.length > 4 &&
                                        clients?.length - 1 === i) ||
                                      (clients?.length > 4 &&
                                        clients?.length - 2 === i)
                                        ? Styles.dropdownTop
                                        : Styles.dropDown
                                    }`}
                                    ref={ref}
                                  >
                                    <div
                                      onClick={(e) => {
                                        if (rbmView) {
                                          handleClickRow(e, "navigate", each);
                                        }
                                      }}
                                    >
                                      <span>Detail Perusahan</span>
                                      <Icon
                                        icon={"company-dash"}
                                        size={16}
                                        color={"#1571DE"}
                                      />
                                    </div>

                                    <div
                                      onClick={() => {
                                        navigate(
                                          `fulfillment?c=${each?.code}&u=${
                                            query.get("u") || ""
                                          }`
                                        );
                                      }}
                                    >
                                      <span>Detail Pemenuhan</span>
                                      <Icon
                                        icon={"chart-rbm"}
                                        size={16}
                                        color={"#1571DE"}
                                      />
                                    </div>

                                    {activeType === "2" ? null : (
                                      <div
                                        onClick={() => {
                                          setOwnerShip(each);
                                        }}
                                      >
                                        <span>Ganti Kepemilikan</span>
                                        <Icon
                                          icon={"refresh"}
                                          size={16}
                                          color={"#1571DE"}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!isLoadingTable && !clients?.length && (
              <div className={Styles.empty}>
                <img src={Images.TIME_MANAGEMENT} alt="" />
                <div>
                  <span>Belum Ada Data Klien</span>
                  <p>Belum ada klien didelegasikan kepada Anda</p>
                </div>
              </div>
            )}
            <div className={Styles.paginationContainer}>
              <Pagination
                total={clients?.length}
                totalPage={totalPage}
                value={value}
                setValue={setValue}
                pages={pages}
                setPages={setPages}
                pageList={pageList}
                setPageList={setPageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
            {activeType === "1" && !rbmView && (
              <div className={Styles.addButtonWrapper}>
                <button
                  variant="contained"
                  onClick={() => navigate(pathname + "/add-new-client")}
                >
                  <span>Add Client</span>
                  <Icon icon={"add-circle"} size={20} />
                </button>
              </div>
            )}

            <div
              className={`${Styles.modalWrapper} ${
                selectedClient ? Styles.showModal : Styles.hideModal
              }`}
            >
              <ViewDetailModal
                data={selectedClient}
                setData={setSelectedClient}
              />
            </div>
            <div
              ref={boxRef}
              className={`${Styles.overlay} ${
                selectedClient ? Styles.visible : ""
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
}
