import CheckboxJobPosting from '@Atom/CheckboxJobPosting'
import Icon from '@Atom/Icon'
import ModalWrapper from '@Atom/ModalWrapper'
import MultipleDropdownField from '@Atom/MultipleDropdownField'
import { makeRandomString } from '@Helpers/makeRandomString'
import AutocompleteField from '@Molecule/AutocompleteField'
import InputField from '@Molecule/InputField'
import InputNumberField from '@Molecule/InputNumberField'
import Images from '@Theme/Images'
import { useCallback, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import RequestSuccessModal from '../RequestSuccessModal'
import useWindowSize from '@Hooks/useWindowSize'
import { createManpowerRequest } from '@Services/client/dashboard'
import { useNavigate } from 'react-router-dom'
import FormErrorMessage from '@Atom/FormErrorMessage'

export default function AddPositionLocationModal({
  positionData = null,
  show = false,
  onClose = () => { },
  sync
}) {
  const navigate = useNavigate()
  const [step, setStep] = useState('details')
  const [qualificationsForAllCities, setQualificationsForAllCities] = useState(false)

  const [isSubmitting, setIsSubmiting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const [detailsForm, setDetailsForm] = useState({
    positionName: positionData?.positionName,
    cities: [
      {
        id: 1,
        cityName: '',
        cityCode: '',
        quota: '',
        vacant: ''
      }
    ]
  })

  const [qualificationsForm, setQualificationsForm] = useState([
    {
      id: 1,
      category: '',
      details: '',
      cities: []
    }
  ])

  const handleChangeDetails = (name, value) => {
    setDetailsForm(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleChangeDetailsCity = (id, name, value) => {
    setDetailsForm(prev => {
      return {
        ...prev,
        cities: prev?.cities?.map(c => {
          if (c?.id === id) {
            return {
              ...c,
              [name]: value
            }
          } return c
        })
      }
    })
  }
  const handleChangeQualifications = useCallback((id, name, value) => {
    setQualificationsForm(qualificationsForm?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      } return obj
    }))
  }, [qualificationsForm])

  const cities = useMemo(() => {
    return sync?.cities || []
  }, [sync?.cities])

  const filteredCities = useMemo(() => {
    return cities?.map(obj => obj?.name)
    ?.filter(obj => !positionData?.regions?.map(c => c?.cityName)?.includes(obj))
    ?.filter(obj => !detailsForm?.cities?.map(c => c?.cityName)?.includes(obj))
  }, [cities, detailsForm?.cities, positionData?.regions])

  const { width } = useWindowSize()


  const template = useMemo(() => {
    switch (step) {
      case 'details':
        return {
          title: `Tambah Lokasi Baru - ${positionData?.positionName}`,
          content: (
            <div className={Styles.detailsForm}>
              {
                detailsForm?.cities?.map((c, cI) => (
                  <div key={cI} className={Styles.dCity}>
                    <AutocompleteField
                      title={cI === 0 || width <= 768 ? 'Kota' : ''}
                      value={c?.cityName}
                      setValue={(newVal) => {
                        handleChangeDetailsCity(c?.id, 'cityName', newVal)
                        handleChangeDetailsCity(c?.id, 'cityCode', cities?.find(obj => obj?.name?.toUpperCase() === newVal?.toUpperCase())?.code)
                      }}
                      // searchOptions={[
                      //   // {
                      //   //   name: 'city 1'
                      //   // },
                      //   // {
                      //   //   name: 'city 2'
                      //   // },
                      //   'city 1',
                      //   'city 2'
                      // ]}
                      searchOptions={filteredCities}
                      placeholder={'Cari kota'}
                    />
                    <InputNumberField
                      title={cI === 0 || width <= 768 ? 'Jumlah Kuota' : ''}
                      value={c?.quota}
                      setValue={(newVal) => handleChangeDetailsCity(c?.id, 'quota', newVal)}
                      placeholder={'Contoh: 30'}
                    />
                    <InputNumberField
                      title={cI === 0 || width <= 768 ? 'Jumlah Vacant' : ''}
                      value={c?.vacant}
                      setValue={(newVal) => handleChangeDetailsCity(c?.id, 'vacant', newVal)}
                      placeholder={'Contoh: 30'}
                    />
                    <div>
                      <button onClick={() => {
                        handleChangeDetails('cities', detailsForm?.cities?.filter(obj => obj?.id !== c?.id))
                      }} disabled={detailsForm?.cities?.length === 1}>
                        <Icon icon={'trash'} size={width > 768 ? 24 : 20} />
                      </button>
                    </div>
                  </div>
                ))
              }
              <button onClick={() => {
                handleChangeDetails('cities', [...detailsForm?.cities, {
                  id: makeRandomString(5),
                  cityCode: '',
                  cityName: '',
                  quota: '',
                  vacant: ''
                }])
              }}>
                <span>Tambah Kota</span>
                <Icon icon={'page-plus'} size={20} />
              </button>
            </div>
          )
        }
      case 'qualifications':
        return {
          title: `Kualifikasi - ${positionData?.positionName}`,
          content:
            <div className={Styles.qualificationsForm}>
              <div className={Styles.allCitiesCheckbox}>
                <CheckboxJobPosting checked={qualificationsForAllCities} onChange={() => setQualificationsForAllCities(!qualificationsForAllCities)} />
                <span>Terapkan kualifikasi untuk seluruh kota</span>
              </div>
              <div className={Styles.fields}>
                {qualificationsForm?.map((q, qI) => (
                  <div key={qI} className={Styles.perQualification}>
                    <AutocompleteField
                      title={qI === 0 ? 'Kategori Kualifikasi' : ''}
                      value={q?.category}
                      setValue={(newVal) => handleChangeQualifications(q?.id, 'category', newVal)}
                      searchOptions={sync?.qualifications?.map(obj => obj?.value)?.filter(obj => !qualificationsForm?.some(q => q?.category === obj))}
                      placeholder={'Cari kualifikasi'}
                    />
                    <InputField
                      title={qI === 0 || width <= 768 ? 'Detail Kualifikasi' : ''}
                      value={q?.details}
                      onChange={(e) => handleChangeQualifications(q?.id, 'details', e?.target?.value)}
                      placeholder={'Detail Kualifikasi'}
                    />
                    <MultipleDropdownField
                      title={qI === 0 || width <= 768 ? 'Kota' : ''}
                      options={detailsForm?.cities?.map(obj => obj?.cityName)}
                      values={q?.cities}
                      setValues={(newValues) => handleChangeQualifications(q?.id, 'cities', newValues)}
                      placeholder='Belum dipilih'
                      disabled={qualificationsForAllCities}
                    />
                    <div>
                      <button onClick={() => {
                        setQualificationsForm(qualificationsForm?.filter(obj => obj?.id !== q?.id))
                      }}
                        disabled={qualificationsForm?.length === 1}>
                        <Icon icon={'trash'} size={width > 768 ? 24 : 20} />
                      </button>
                    </div>
                  </div>
                ))}
                <button onClick={() => {
                  setQualificationsForm([...qualificationsForm, {
                    id: makeRandomString(5),
                    category: '',
                    details: '',
                    cities: []
                  }])
                }}>
                  <span>Tambah Kualifikasi</span>
                  <Icon icon={'page-plus'} size={20} />
                </button>
              </div>
            </div>

        }
      default:
        return <></>
    }
  }, [cities, detailsForm?.cities, filteredCities, handleChangeQualifications, positionData?.positionName, qualificationsForAllCities, qualificationsForm, step, sync?.qualifications, width])

  const disableSubmit = useMemo(() => {
    if (step === 'details') {
      if (!detailsForm?.positionName
        ||
        detailsForm?.cities?.find(obj => !obj?.cityName || !obj?.cityCode || !obj?.vacant || !obj?.quota)
      ) {
        return true
      }
    } else {
      if (qualificationsForm?.find(obj => !obj?.category || !obj?.details)) {
        return true
      }
      if (!qualificationsForAllCities && qualificationsForm?.find(obj => !obj?.cities?.length)) {
        return true
      }
    }
    return isSubmitting
  }, [detailsForm?.cities, detailsForm?.positionName, isSubmitting, qualificationsForAllCities, qualificationsForm, step])

  const mappedQualifications = useMemo(() => {
    return detailsForm?.cities
      ?.map(c => qualificationsForAllCities
        ?
        qualificationsForm?.map(q => {
          return {
            ...q,
            cityCode: c.cityCode
          }
        })
        :
        qualificationsForm
          ?.filter(q => q?.cities?.find(obj => obj === c?.cityName))
          ?.map(q => {
            return {
              ...q,
              cityCode: c.cityCode
            }
          })
      )?.flat()
  }, [detailsForm?.cities, qualificationsForAllCities, qualificationsForm])

  const handleSubmit = async () => {
    try {
      setIsSubmiting(true)
      const form = new URLSearchParams()
      form?.append('type', 'Location')
      form?.append('position', detailsForm?.positionName)
      for (let i = 0; i < detailsForm?.cities?.length; i++) {
        form?.append(`manPowers[${i}][cityCode]`, detailsForm?.cities[i]?.cityCode)
        form?.append(`manPowers[${i}][quota]`, detailsForm?.cities[i]?.quota)
        form?.append(`manPowers[${i}][vacant]`, detailsForm?.cities[i]?.vacant)

      }

      for (let i = 0; i < mappedQualifications?.length; i++) {
        form?.append(`qualifications[${i}][cityCode]`, mappedQualifications[i]?.cityCode)
        form?.append(`qualifications[${i}][title]`, mappedQualifications[i]?.category)
        form?.append(`qualifications[${i}][value]`, mappedQualifications[i]?.details)

      }
      await createManpowerRequest(form)
      setSuccess(true)
    } catch (err) {
      const errorText = err?.response?.data?.error || 'Something went wrong'
      setError(errorText)

    }
  }


  if (success) {
    return (
      <RequestSuccessModal
        show={success}
        onClose={() => {
          navigate(`/dashboard?t=${makeRandomString(5)}`, { replace: 'true' })
          onClose()
        }}
        title='Request Berhasil Terkirim'
        description='Request tenaga kerja berhasil terkirim'
        closeButtonText='Tutup'
      />
    )
  }

  return (
    <ModalWrapper show={show} handleClose={isSubmitting ? () => { } : onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.hTop}>
            <h3>Tambah Lokasi</h3>
            <button onClick={onClose}>
              <Icon icon={'cross'} size={24} />
            </button>
          </div>
          <img src={Images.BUILDING} alt='' />
        </div>
        <div className={Styles.content}>
          <h4>{template?.title}</h4>
          {template?.content}
          <FormErrorMessage message={error} />
        </div>
        <div className={Styles.actions}>
          <button onClick={step === 'details' ? onClose : () => setStep('details')}>
            Kembali
          </button>
          <button disabled={disableSubmit} onClick={step === 'details' ? () => setStep('qualifications') : handleSubmit}>
            {step === 'details' ? 'Lanjut' : 'Simpan'}
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}