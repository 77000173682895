import { useRef, useState } from "react";
import Styles from "../../style.module.scss";
import { useDropzone } from "react-dropzone";
import Spinner from "@Atom/Spinner";
import Icon from "@Atom/Icon";
import { getAddress } from "@Hooks/getAddress";
import imageCompression from "browser-image-compression";

export default function ImagesCard({ state, setState, isKTP = false }) {
  let inputFileRef = useRef();
  const [errorFileReject, setErrorFileReject] = useState({});
  const [errorTypes, setErrorTypes] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const onDrop = async (acceptedFiles, fileRejections) => {
    const options = {
      maxSizeMB: 0.5, // Ukuran maksimal 0.5MB (500KB)
      maxWidthOrHeight: 1920, // Resolusi maksimal (sesuaikan jika perlu)
      useWebWorker: true, // Menggunakan web worker untuk performa
    };
    if (isKTP) {
      if (acceptedFiles?.length > 0) {
        try {
          setLoadingUpload(true);

          const compressedFile = await imageCompression(
            acceptedFiles[0],
            options
          );

          if (compressedFile) {
            setLoadingUpload(false);
            setState((prev) => ({ ...prev, imagesIdCard: compressedFile }));
            setErrorFileReject({});
            setErrorTypes("");
            navigator.geolocation.getCurrentPosition(function (position) {
              setState((prev) => ({
                ...prev,
                latitudeImageIdCard: position.coords.latitude,
                longitudeImageIdCard: position.coords.longitude,
              }));
              getAddress(
                position.coords.latitude,
                position.coords.longitude,
                setLoading
              )
                .then((result) => {
                  setState((prev) => ({
                    ...prev,
                    addressImageIdCard: result,
                  }));
                })
                .catch((errorMessage) => {
                  console.error(errorMessage);
                }); // Delay of 3 seconds
            });
          }
        } catch (error) {
          console.log(error);
          setErrorTypes(error);
        }
      } else {
        setErrorTypes("Format harus JPEG atau PNG");
        setErrorFileReject({});
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorTypes("");
              setErrorFileReject({
                message:
                  "File terlalu besar. Ukuran maksimal file adalah 5MB !",
              });
            }
          });
        });
      }
    } else {
      if (acceptedFiles?.length > 0) {
        try {
          setLoadingUpload(true);
          const compressedFile = await imageCompression(
            acceptedFiles[0],
            options
          );

          if (compressedFile) {
            setLoadingUpload(false);
            setState((prev) => ({ ...prev, image: compressedFile }));
            setErrorFileReject({});
            setErrorTypes("");
            navigator.geolocation.getCurrentPosition(function (position) {
              setState((prev) => ({
                ...prev,
                latitudeImage: position.coords.latitude,
                longitudeImage: position.coords.longitude,
              }));

              getAddress(
                position.coords.latitude,
                position.coords.longitude,
                setLoading
              )
                .then((result) => {
                  setState((prev) => ({
                    ...prev,
                    addressImage: result,
                  }));
                })
                .catch((errorMessage) => {
                  console.error(errorMessage);
                }); // Delay of 3 seconds
            });
          }
        } catch (error) {
          console.log(error);
          setErrorTypes(error);
        }
      } else {
        setErrorTypes("Format harus JPEG atau PNG");
        setErrorFileReject({});
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorTypes("");
              setErrorFileReject({
                message:
                  "File terlalu besar. Ukuran maksimal file adalah 5MB !",
              });
            }
          });
        });
      }
    }
  };

  const onChange = async (e) => {
    const options = {
      maxSizeMB: 0.5, // Ukuran maksimal 0.5MB (500KB)
      maxWidthOrHeight: 1920, // Resolusi maksimal (sesuaikan jika perlu)
      useWebWorker: true, // Menggunakan web worker untuk performa
    };
    if (isKTP) {
      if (e?.target?.files?.length > 0) {
        if (
          e?.target?.files[0]?.type === "image/jpeg" ||
          e?.target?.files[0]?.type === "image/png"
        ) {
          try {
            setLoadingUpload(true);
            const compressedFile = await imageCompression(
              e?.target?.files[0],
              options
            );

            if (compressedFile) {
              setLoadingUpload(false);
              setState((prev) => ({
                ...prev,
                imagesIdCard: compressedFile,
              }));

              setErrorTypes("");
              navigator.geolocation.getCurrentPosition(function (position) {
                setState((prev) => ({
                  ...prev,
                  latitudeImageIdCard: position.coords.latitude,
                  longitudeImageIdCard: position.coords.longitude,
                }));
                getAddress(
                  position.coords.latitude,
                  position.coords.longitude,
                  setLoading
                )
                  .then((result) => {
                    setState((prev) => ({
                      ...prev,
                      addressImageIdCard: result,
                    }));
                  })
                  .catch((errorMessage) => {
                    console.error(errorMessage);
                  });
              });
            }
          } catch (error) {
            console.log(error);
            setErrorTypes(error);
          }
        } else {
          setState((prev) => ({ ...prev, imagesIdCard: null }));
          setErrorTypes("Format harus JPEG atau PNG");
        }
      }
    } else {
      if (e?.target?.files?.length > 0) {
        // console.log(e?.target?.files, "test");
        if (
          e?.target?.files[0]?.type === "image/jpeg" ||
          e?.target?.files[0]?.type === "image/png"
        ) {
          try {
            setLoadingUpload(true);
            const compressedFile = await imageCompression(
              e?.target?.files[0],
              options
            );
            if (compressedFile) {
              setLoadingUpload(false);
              setState((prev) => ({ ...prev, image: compressedFile }));
              setErrorTypes("");
              navigator.geolocation.getCurrentPosition(function (position) {
                setState((prev) => ({
                  ...prev,
                  latitudeImage: position.coords.latitude,
                  longitudeImage: position.coords.longitude,
                }));

                getAddress(
                  position.coords.latitude,
                  position.coords.longitude,
                  setLoading
                )
                  .then((result) => {
                    setState((prev) => ({
                      ...prev,
                      addressImage: result,
                    }));
                  })
                  .catch((errorMessage) => {
                    console.error(errorMessage);
                  });
              });
            }
          } catch (error) {
            console.log(error);
            setErrorTypes(error);
          }
        } else {
          setState((prev) => ({ ...prev, image: null }));
          setErrorTypes("Format harus JPEG atau PNG");
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxSize: 1048576 * 5,
  });

  return isKTP ? (
    <div>
      {state.imagesIdCard &&
      state?.longitudeImageIdCard !== 0 &&
      state?.latitudeImageIdCard !== 0 &&
      !loading &&
      !loadingUpload ? (
        <div className={Styles.Section}>
          <div
            className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}
            {...getRootProps()}
          >
            <img
              src={URL.createObjectURL(state.imagesIdCard)}
              alt="imagesIdCard"
            />
            <div className={Styles.Footer}>
              <Icon icon={"camera"} size="24px" />
              <p>Foto Ulang</p>
              <input
                {...getInputProps()}
                type="file"
                ref={inputFileRef}
                name="file"
                onChange={onChange}
                hidden
              />
            </div>
          </div>
          <div className={`${Styles.ImageidContainer} ${Styles.showOnMobile}`}>
            <label
              htmlFor="cameraIdCard"
              className={`${Styles.ImageidContainer}`}
            >
              <img
                src={URL.createObjectURL(state.imagesIdCard)}
                alt="imagesIdCard"
              />
              <div className={Styles.Footer}>
                <Icon icon={"camera"} size="24px" />
                <p>Foto Ulang</p>
                <input
                  type="file"
                  // ref={inputFileRef}
                  capture="user"
                  name="file"
                  onChange={onChange}
                  hidden
                  multiple={false}
                  id="cameraIdCard"
                />
              </div>
            </label>
          </div>
        </div>
      ) : (state.imagesIdCard && state.longitudeImageIdCard === 0) ||
        (state.imagesIdCard && state?.latitudeImageIdCard === 0) ||
        (state.imagesIdCard && loading) ||
        loadingUpload ? (
        <div className={Styles.Section}>
          <div className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}>
            {loadingUpload ? null : (
              <img
                src={URL.createObjectURL(state.imagesIdCard)}
                alt="imagesIdCard"
              />
            )}

            <div className={Styles.loadingWrapper}>
              <div>
                <div className={Styles.spinner}>
                  <Spinner />
                </div>
                <span>Mengunggah foto...</span>
              </div>
            </div>
          </div>
          <div className={`${Styles.ImageidContainer} ${Styles.showOnMobile}`}>
            <label
              htmlFor="cameraIdCard"
              className={`${Styles.ImageidContainer}`}
            >
              {loadingUpload ? null : (
                <img
                  src={URL.createObjectURL(state.imagesIdCard)}
                  alt="imagesIdCard"
                />
              )}
              <div className={Styles.loadingWrapper}>
                <div>
                  <div className={Styles.spinner}>
                    <Spinner />
                  </div>
                  <span>Mengunggah foto...</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className={`${Styles.Section}`}>
          <div className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}>
            <div className={`${Styles.DropdownSection}`} {...getRootProps()}>
              <Icon icon="camera" size={28} color="#1571DE" />
              <p>Ambil Foto Disini</p>
              <input
                {...getInputProps()}
                type="file"
                // ref={inputFileRef}
                capture="user"
                name="file"
                onChange={onChange}
                hidden
              />
            </div>
          </div>
          <div className={`${Styles.DropdownSection} ${Styles.showOnMobile}`}>
            <label
              htmlFor="cameraIdCard"
              className={`${Styles.DropdownSection}`}
            >
              <Icon icon="camera" size={28} color="#1571DE" />
              <p>Ambil Foto Disini</p>
              <input
                type="file"
                // ref={inputFileRef}
                capture="user"
                name="file"
                onChange={onChange}
                hidden
                multiple={false}
                id="cameraIdCard"
              />
            </label>
          </div>
        </div>
      )}
      {errorFileReject?.message ? (
        <span className={Styles.errorText}>*{errorFileReject?.message}</span>
      ) : errorTypes ? (
        <span className={Styles.errorText}>*{errorTypes}</span>
      ) : null}

      <p className={Styles.Description}>
        Pastikan KTP terlihat dengan jelas pada foto
      </p>
    </div>
  ) : (
    <div>
      {state.image &&
      state?.longitudeImage !== 0 &&
      state?.latitudeImage !== 0 &&
      !loading &&
      !loadingUpload ? (
        <div className={Styles.Section}>
          <div
            className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}
            {...getRootProps()}
          >
            <img src={URL.createObjectURL(state.image)} alt="imageid" />
            <div className={Styles.Footer}>
              <Icon icon={"camera"} size="24px" />
              <p>Foto Ulang</p>
              <input
                {...getInputProps()}
                type="file"
                ref={inputFileRef}
                name="file"
                onChange={onChange}
                hidden
              />
            </div>
          </div>
          <div className={`${Styles.ImageidContainer} ${Styles.showOnMobile}`}>
            <label htmlFor="camera" className={`${Styles.ImageidContainer}`}>
              <img src={URL.createObjectURL(state.image)} alt="imageid" />
              <div className={Styles.Footer}>
                <Icon icon={"camera"} size="24px" />
                <p>Foto Ulang</p>
                <input
                  type="file"
                  // ref={inputFileRef}
                  capture="user"
                  name="file"
                  onChange={onChange}
                  hidden
                  multiple={false}
                  id="camera"
                />
              </div>
            </label>
          </div>
        </div>
      ) : (state.image && state.longitudeImage === 0) ||
        (state.image && state?.latitudeImage === 0) ||
        (state.image && loading) ||
        loadingUpload ? (
        <div className={Styles.Section}>
          <div className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}>
            {loadingUpload ? null : (
              <img src={URL.createObjectURL(state.image)} alt="imageid" />
            )}
            <div className={Styles.loadingWrapper}>
              <div>
                <div className={Styles.spinner}>
                  <Spinner />
                </div>
                <span>Mengunggah foto...</span>
              </div>
            </div>
          </div>
          <div className={`${Styles.ImageidContainer} ${Styles.showOnMobile}`}>
            <label htmlFor="camera" className={`${Styles.ImageidContainer}`}>
              {loadingUpload ? null : (
                <img src={URL.createObjectURL(state.image)} alt="imageid" />
              )}

              <div className={Styles.loadingWrapper}>
                <div>
                  <div className={Styles.spinner}>
                    <Spinner />
                  </div>
                  <span>Mengunggah foto...</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className={`${Styles.Section}`}>
          <div className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}>
            <div className={`${Styles.DropdownSection}`} {...getRootProps()}>
              <Icon icon="camera" size={28} color="#1571DE" />
              <p>Ambil Foto Disini</p>
              <input
                {...getInputProps()}
                type="file"
                // ref={inputFileRef}
                capture="user"
                name="file"
                onChange={onChange}
                hidden
              />
            </div>
          </div>
          <div className={`${Styles.DropdownSection} ${Styles.showOnMobile}`}>
            <label htmlFor="camera" className={`${Styles.DropdownSection}`}>
              <Icon icon="camera" size={28} color="#1571DE" />
              <p>Ambil Foto Disini</p>
              <input
                type="file"
                // ref={inputFileRef}
                capture="user"
                name="file"
                onChange={onChange}
                hidden
                multiple={false}
                id="camera"
              />
            </label>
          </div>
          {/* <div className={Styles.DropdownSection} {...getRootProps()}>
        <Icon icon="camera" size={28} color="#1571DE" />
        <p>Ambil Foto Disini</p>
        <input
          {...getInputProps()}
          type="file"
          ref={inputFileRef}
          name="file"
          onChange={onChange}
          hidden
        />
      </div> */}
        </div>
      )}
      {errorFileReject?.message ? (
        <span className={Styles.errorText}>*{errorFileReject?.message}</span>
      ) : errorTypes ? (
        <span className={Styles.errorText}>*{errorTypes}</span>
      ) : null}

      <p className={Styles.Description}>
        Pastikan foto Anda terlihat dengan jelas, fotolah pada tempat dengan
        pencahayaan yang cukup.
      </p>
    </div>
  );
}
