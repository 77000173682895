import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import Rotation from "@Atom/Rotation";
import UploadBox from "@Atom/UploadBox";
import { formatBytes } from "@Helpers/formatBytes";
import { InputTitle } from "@Molecule/InputField";
import TextareaField from "@Molecule/TextAreaField";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./styles.module.scss";
import {
  candidateApproval,
  getApprovalDetails,
} from "@Services/manager/approval";
import { makeRandomString } from "@Helpers/makeRandomString";
import { fileBaseUrl } from "@Config/api";
import { getUrlFileSize } from "@Helpers/getUrlFileSize";

export default function ApproveCandidateModal({
  defaultData,
  show,
  onClose = () => {},
  setTrigger = () => {},
  setSelectedMode,
  setIsDone,
}) {
  const [data, setData] = useState(null);
  const [size, setSize] = useState(0);
  const [viewImage, setViewImage] = useState(null);

  useEffect(() => {
    if (data?.url) {
      getUrlFileSize(fileBaseUrl + data?.url, setSize);
    }
  }, [data?.url]);

  const fetchData = useCallback(async () => {
    try {
      const { response } = await getApprovalDetails(defaultData?.id);
      setData(response);
    } catch (err) {
      console.log(err, "error on fetching candidate details");
    }
  }, [defaultData?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [form, setForm] = useState({
    reason: "",
    attachment: "",
  });

  const handleChangeForm = (name, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [isApproving, setIsApproving] = useState(false);
  const [errorFileReject, setErrorFileReject] = useState({});
  const [errorFile, setErrorFile] = useState("");

  // const inputFileRef = useRef();

  // const onChangeResponsive = (e) => {
  //   const file = e.target.files[0];
  //   handleChangeForm("attachment", file);
  // };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];
        if (
          file?.type === "image/jpeg" ||
          file.name.endsWith(".jpeg") ||
          file.name.endsWith(".png")
        ) {
          handleChangeForm("attachment", acceptedFiles[0]);
          setErrorFileReject({});
          setErrorFile("");
        } else {
          setErrorFile(
            "Tipe file tidak sesuai. Pastikan file memiliki format JPEG, JPG atau PNG"
          );
        }
      } else {
        setErrorFile(
          "Tipe file tidak sesuai. Pastikan file memiliki format JPEG, JPG atau PNG"
        );
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message:
                  "File terlalu besar. Ukuran maksimal file adalah 5MB !",
              });
            }
          });
        });
      }
    },
    accept: {
      // "application/pdf": [".pdf"],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    multiple: false,
    // noClick: true,
    // noDrag: form?.attachment,
    maxSize: 1048576 * 5,
  });

  const handleApprove = async () => {
    try {
      setIsApproving(true);
      await candidateApproval(defaultData?.id, form?.attachment, form?.reason);
      setIsDone({
        name: defaultData?.name,
        mode: "approve",
      });
      setTrigger(makeRandomString(5));
      onClose();
      setIsApproving(false);
    } catch (err) {
      setIsApproving(false);
      console.log(err);
    }
  };

  const handleCancel = () => {
    setSelectedMode("Reject");
  };

  return (
    <ModalWrapper
      show={show}
      handleClose={
        defaultData?.isApproved
          ? !viewImage
            ? onClose
            : () => setViewImage(null)
          : () => {}
      }
    >
      {!viewImage ? (
        <div className={Styles.container}>
          <div className={Styles.header}>
            <h3>
              {defaultData?.isApproved ? "Detail Approval" : "Approve Kandidat"}
            </h3>
            {defaultData?.isApproved &&
            defaultData?.status !== "CLIENT-DISQUALIFIED" ? (
              <button className={Styles.cancelButton} onClick={handleCancel}>
                <span>Cancel Approval</span>
              </button>
            ) : (
              <></>
            )}
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            {defaultData?.isApproved ? (
              <div className={Styles.reason}>
                <InputTitle title={"Alasan Approve"} />
                <p>{data?.remark || "-"}</p>
              </div>
            ) : (
              <TextareaField
                title={"Alasan Approve"}
                value={form?.reason}
                onChange={(e) => handleChangeForm("reason", e?.target?.value)}
                placeholder="Alasan approve"
                disabled={isApproving}
              />
            )}
            <div className={Styles.attachment}>
              {form?.attachment || defaultData?.isApproved ? (
                <div className={Styles.preview}>
                  <InputTitle title={"Attachment"} />
                  <div className={Styles.previewCard}>
                    <img
                      src={
                        form?.attachment
                          ? URL.createObjectURL(form?.attachment)
                          : data?.url
                          ? fileBaseUrl + data?.url
                          : ""
                      }
                      alt=""
                    />
                    <div className={Styles.desc}>
                      <span>{form?.attachment?.name || data?.url}</span>
                      <span>
                        {form?.attachment
                          ? formatBytes(form?.attachment?.size)
                          : data?.url
                          ? formatBytes(size)
                          : ""}
                      </span>
                    </div>
                    {defaultData?.isApproved ? (
                      <button
                        disabled={!data?.url}
                        onClick={() => setViewImage(fileBaseUrl + data?.url)}
                        className={Styles.viewDetail}
                      >
                        View Detail
                      </button>
                    ) : (
                      <div className={Styles.fileActions}>
                        <button
                          disabled={isApproving}
                          // onClick={open}
                          {...getRootProps()}
                        >
                          <input
                            {...getInputProps()}
                            disabled={false}
                            type="file"
                            name="file"
                            hidden
                            accept="image/*"
                          />
                          <Icon icon={"edit"} size={22} />
                        </button>
                        <button
                          disabled={isApproving}
                          onClick={() => handleChangeForm("attachment", null)}
                        >
                          <Icon icon={"trash"} size={22} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={Styles.box}>
                  <InputTitle
                    title={"Taruh file di kotak bawah ini"}
                    color="#757575"
                  />

                  <div {...getRootProps()}>
                    <UploadBox isPdf={false} />
                    <input
                      {...getInputProps()}
                      disabled={false}
                      type="file"
                      name="file"
                      hidden
                      accept="image/*"
                    />
                  </div>

                  {errorFile ? (
                    <span className={Styles.errorFile}>*{errorFile}</span>
                  ) : null}
                </div>
              )}
            </div>

            {errorFileReject?.message ? (
              <span className={Styles.errorText}>
                *{errorFileReject?.message}
              </span>
            ) : null}

            <div className={Styles.actions}>
              <button
                className={Styles.cancel}
                disabled={isApproving}
                onClick={onClose}
              >
                {defaultData?.isApproved ? "Close" : "Cancel"}
              </button>
              {!defaultData?.isApproved && (
                <button
                  className={Styles.submit}
                  disabled={isApproving || !form?.attachment || !form?.reason}
                  onClick={handleApprove}
                >
                  {isApproving ? (
                    <Rotation width={"20px"} type="white" />
                  ) : (
                    <span>Approve</span>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className={Styles.imgContainer}>
          <button
            className={Styles.closeImageButton}
            onClick={() => setViewImage(null)}
          >
            <Icon icon={"cross"} size={22} />
          </button>
          <img src={viewImage} alt="" />
        </div>
      )}
    </ModalWrapper>
  );
}
