import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import Images from '@Theme/Images'

export default function DownloadErrorModal({
  error,
  onClose = () => { },
  onRetry = () => { }
}) {

  return (
    <ModalWrapper show={error} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <img alt='' src={Images.FOLDER_WARNING} />
          <h3>Oopss...</h3>
        </div>
        <div className={Styles.content}>
          <span>Terjadi kesalahan saat download report, silahkan coba kembali</span>
          <div className={Styles.actions}>
            <button onClick={onClose}>Tutup</button>
            <button onClick={onRetry}>Download Ulang</button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}