import { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import CheckboxJobPosting from '@Atom/CheckboxJobPosting'
import { InputTitle } from '@Molecule/InputField'
import UseOutsideClick from '@Hooks/useOutsideClick'

export default function MultipleDropdownField({
  title = '',
  required = false,
  options,
  values = [],
  setValues = () => { },
  placeholder = '',
  disabled =false
}) {
  const [expand, setExpand] = useState(false)

  const handleCheck = (o) => {
    if (values?.find(obj => obj === o)) {
      setValues(values?.filter(obj => obj !== o))
    } else {
      setValues([...values, o])
    }
  }

  const ref = useRef()
  const outsideClick = UseOutsideClick(ref)

  useEffect(() => {
    if (outsideClick && expand) {
      setExpand(false)
    }
  }, [expand, outsideClick])


  return (
    <div className={Styles.container}>
      {!!title &&
        <InputTitle
          title={title}
          required={required}
        />
      }
      <div ref={ref} className={`${Styles.field} ${disabled ? Styles.disabled : ''}`} onClick={() => !disabled ? setExpand(true) : ()=> {}}>
        <span className={`${!values || !values?.length ? Styles.placeholder : ''}`}>{values?.length ? values?.join(', ') : placeholder}</span>
        {
          expand
          &&
          <div className={Styles.options}>
            {options?.map((o, oI) => (
              <div key={oI} className={Styles.option}>
                <CheckboxJobPosting
                  checked={values?.find(obj => obj === o)}
                  onChange={() => handleCheck(o)}
                />
                <span onClick={() => handleCheck(o)}>{o}</span>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}