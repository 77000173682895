import { useMemo } from "react";
import Styles from "./style.module.scss";

export default function SecondSection({
  employmentType,
  workSchemeForm,
  remark,
  selectedButtonBPJS,
  selectedValueBPU,
}) {
  const template = useMemo(() => {
    return [
      {
        title: "TKO Contract",
        component: (
          <InfoCard
            title=""
            description={
              workSchemeForm?.tkoContract === "PKWT"
                ? "PKWT (Perjanjian Kerja Waktu Tertentu)"
                : "PKM (Perjanjian Kemitraan)"
            }
            backgroundColor="#F5F5F5"
          />
        ),
      },
      {
        title: "Tax",
        component: (
          <div className={`${Styles.tax}`}>
            <Grid>
              <InfoCard
                title="PPH21"
                description={workSchemeForm?.taxs?.pph21}
                backgroundColor="#F5F5F5"
              />
              <InfoCard
                title="PPH23"
                description={workSchemeForm?.taxs?.pph23}
                backgroundColor="#F5F5F5"
              />
              <InfoCard
                title="PPN"
                description={workSchemeForm?.taxs?.ppn}
                backgroundColor="#F5F5F5"
              />
            </Grid>
          </div>
        ),
      },
      {
        title:
          selectedButtonBPJS === "BPU"
            ? "BPJS • Bukan Penerima Upah"
            : "BPJS • Penerima Upah",
        component:
          selectedButtonBPJS === "BPU" ? (
            <div className={Styles.bpjs}>
              <Grid>
                <InfoCard
                  title="Tagih"
                  description={selectedValueBPU}
                  backgroundColor="#F5F5F5"
                />
                <InfoCard
                  title="Pendfataran BPJS"
                  description={workSchemeForm?.bpjs?.registration}
                  backgroundColor="#F5F5F5"
                />
                <InfoCard
                  title="Bukti Pembayaran BPJS"
                  description={workSchemeForm?.bpjs?.paymentProof}
                  backgroundColor="#F5F5F5"
                />
                <InfoCard
                  title="Potongan BPJS TKO"
                  description={workSchemeForm?.bpjs?.cutOffBPJSTKO}
                  backgroundColor="#F5F5F5"
                />
              </Grid>

              <Grid>
                <Grid
                  title="Kesehatan"
                  gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
                  backgroundColor="#F5F5F5"
                  padding="16px"
                >
                  <InfoCard
                    title="KS (Kesehatan)"
                    description={workSchemeForm?.bpjs?.health}
                    backgroundColor="#fff"
                  />
                </Grid>
                <Grid
                  title="AKDHK"
                  gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
                  backgroundColor="#F5F5F5"
                  padding="16px"
                >
                  <InfoCard
                    title="AKDHK (Asuransi Kecelakaan Diluar Hari Kerja)"
                    description={workSchemeForm?.bpjs?.akhdk}
                    backgroundColor="#fff"
                  />
                </Grid>
              </Grid>

              <Grid title="Remark" gridTemplateColumns="1fr">
                <InfoCard
                  title="Deskripsi"
                  description={remark}
                  backgroundColor="#F5F5F5"
                />
              </Grid>
            </div>
          ) : (
            <div className={Styles.bpjs}>
              <Grid>
                <InfoCard
                  title="Pendfataran BPJS"
                  description={workSchemeForm?.bpjs?.registration}
                  backgroundColor="#F5F5F5"
                />
                <InfoCard
                  title="Bukti Pembayaran BPJS"
                  description={workSchemeForm?.bpjs?.paymentProof}
                  backgroundColor="#F5F5F5"
                />
                <InfoCard
                  title="Potongan BPJS TKO"
                  description={workSchemeForm?.bpjs?.cutOffBPJSTKO}
                  backgroundColor="#F5F5F5"
                />
              </Grid>

              <Grid
                title="Ketenagakerjaan"
                gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
                backgroundColor="#F5F5F5"
                padding="16px"
              >
                <InfoCard
                  title="JKK (Jaminan Kelengkapan Kerja)"
                  description={workSchemeForm?.bpjs?.jkk}
                  backgroundColor="#fff"
                />
                <InfoCard
                  title="JKM (Jaminan Kematian)"
                  description={workSchemeForm?.bpjs?.jkm}
                  backgroundColor="#fff"
                />
                <InfoCard
                  title="JHT (Jaminan Hari Tua)"
                  description={workSchemeForm?.bpjs?.jht}
                  backgroundColor="#fff"
                />
                <InfoCard
                  title="JP (Jaminan Pansiun)"
                  description={workSchemeForm?.bpjs?.jp}
                  backgroundColor="#fff"
                />
              </Grid>

              <Grid>
                <Grid
                  title="Kesehatan"
                  gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
                  backgroundColor="#F5F5F5"
                  padding="16px"
                >
                  <InfoCard
                    title="KS (Kesehatan)"
                    description={workSchemeForm?.bpjs?.health}
                    backgroundColor="#fff"
                  />
                </Grid>
                <Grid
                  title="AKDHK"
                  gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
                  backgroundColor="#F5F5F5"
                  padding="16px"
                >
                  <InfoCard
                    title="AKDHK (Asuransi Kecelakaan Diluar Hari Kerja)"
                    description={workSchemeForm?.bpjs?.akhdk}
                    backgroundColor="#fff"
                  />
                </Grid>
              </Grid>
              <Grid title="Remark" gridTemplateColumns="1fr">
                <InfoCard
                  title="Deskripsi"
                  description={remark}
                  backgroundColor="#F5F5F5"
                />
              </Grid>
            </div>
          ),
      },
      {
        title: "Others",
        component: (
          <Grid>
            <InfoCard
              title="Pengenaan manfee"
              description={workSchemeForm?.others?.manfee
                ?.map((obj) => obj?.name)
                ?.join(", ")}
              backgroundColor="#F5F5F5"
            />
            <InfoCard
              title="Penagihan THR"
              description={workSchemeForm?.others?.thr}
              backgroundColor="#F5F5F5"
            />
            <InfoCard
              title="Penagihan UAK"
              description={workSchemeForm?.others?.uak}
              backgroundColor="#F5F5F5"
            />
            <InfoCard
              title="Rekening Wajib TKO"
              description={workSchemeForm?.bpjs?.tkoBank?.join(", ")}
              backgroundColor="#F5F5F5"
            />
          </Grid>
        ),
      },
    ];
  }, [
    remark,
    workSchemeForm?.bpjs?.akhdk,
    workSchemeForm?.bpjs?.cutOffBPJSTKO,
    workSchemeForm?.bpjs?.health,
    workSchemeForm?.bpjs?.jht,
    workSchemeForm?.bpjs?.jkk,
    workSchemeForm?.bpjs?.jkm,
    workSchemeForm?.bpjs?.jp,
    workSchemeForm?.bpjs?.paymentProof,
    workSchemeForm?.bpjs?.registration,
    workSchemeForm?.bpjs?.tkoBank,
    workSchemeForm?.others?.manfee,
    workSchemeForm?.others?.thr,
    workSchemeForm?.others?.uak,
    workSchemeForm?.taxs?.pph21,
    workSchemeForm?.taxs?.pph23,
    workSchemeForm?.taxs?.ppn,
    workSchemeForm?.tkoContract,
    selectedButtonBPJS,
    selectedValueBPU,
  ]);

  return (
    <div className={Styles.container}>
      {template?.map((t, tI) => (
        <Section key={tI} title={t?.title} component={t?.component} />
      ))}
    </div>
  );
}

export const Section = ({ title, component }) => {
  return (
    <div className={Styles.each}>
      <h3>{title}</h3>
      {component}
    </div>
  );
};

export const InfoCard = ({
  title = "",
  description = "",
  backgroundColor = "transparent",
}) => {
  return (
    <div className={Styles.infoCard} style={{ backgroundColor }}>
      {title && <span>{title}</span>}
      <p>{description || "-"}</p>
    </div>
  );
};

export const Grid = ({
  title = "",
  backgroundColor = "",
  children,
  gridTemplateColumns = "repeat(auto-fill, minmax(500px, 1fr))",
  padding = "",
  border = "none",
  isMobileHalf = false,
}) => {
  return (
    <div
      className={`${Styles.gridWrapper}`}
      style={{ backgroundColor, padding, border }}
    >
      {title && <div className={Styles.title}>{title}</div>}
      <div
        className={`${Styles.grid} ${Styles[isMobileHalf ? "half" : ""]}`}
        style={{ gridTemplateColumns }}
      >
        {children}
      </div>
    </div>
  );
};
