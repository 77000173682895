import Styles from "./styles.module.scss";

export default function Scrollbar({ children, isFulfillment }) {
  return (
    <div
      className={`${Styles.customScrollbar} ${
        !isFulfillment && Styles.noScroll
      }`}
    >
      {children}
    </div>
  );
}
