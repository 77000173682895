import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import Rotation from "@Atom/Rotation";
import { verifyFLB } from "@Services/hc/flbVerification";
import { useState } from "react";
import Styles from "./style.module.scss";

export default function FLBVerificationConfirmationModal({
  show = false,
  onClose = () => {},
  id = "",
  flbNumber = "",
  fetchData = () => {},
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  // useEffect(() => {
  //   if (error) {
  //     setTimeout(() => {
  //       setError("");
  //     }, 5000);
  //   }
  // }, [error, setError]);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await verifyFLB(id);
      fetchData();
      setIsSuccess(true);
      setIsSubmitting(false);
      setError("");
    } catch (err) {
      setIsSubmitting(false);
      const errorText = err?.response?.data?.error || "Something went wrong";
      setError(errorText);
    }
  };

  return (
    <ModalWrapper show={show} handleClose={!isSubmitting ? onClose : () => {}}>
      <div className={Styles.container}>
        <Icon
          color={isSuccess ? "#1571DE" : "#E9B62F"}
          icon={isSuccess ? "check-circle-2" : "question-mark"}
          size={134}
        />
        <div className={Styles.desc}>
          {isSuccess ? (
            <p>
              {`FLb ${flbNumber} berhasil di`} <span>approve</span>
            </p>
          ) : (
            <p>
              Apakah anda yakin ingin melakukan <span>approve</span> pada FLB
              ini?
            </p>
          )}
        </div>
        <div className={Styles.actionsBox}>
          {error && <span>{error}</span>}
          <div className={Styles.actions}>
            <button disabled={isSubmitting} onClick={onClose}>
              {isSuccess ? "Tutup" : "Cancel"}
            </button>
            {!isSuccess && (
              <button disabled={isSubmitting} onClick={onSubmit}>
                {isSubmitting ? <Rotation type="white" /> : "Approve"}
              </button>
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
