import { useEffect, useRef } from "react";
import BPJSSection from "./BPJSSection";
import OthersSection from "./OthersSection";
import TaxesSection from "./TaxesSection";
import Styles from "./style.module.scss";
import TKOContractSection from "./TKOContractSection";

export default function SecondStep({
  defaultForm,
  form,
  setForm,
  options,
  isDone,
  onNext,
  onPrevious,
  setTrigger,
  setActiveButtonBPJS,
  selectedButtonBPJS,
  setSelectedButtonBPJS,
  selectedValueBPU,
  setSelectedValueBPU,
  alertMessage = "",
  contractForm,
  withBPJS = false,
  setWithBPJS = () => { },
  optionalBPJS = false,
}) {
  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <TKOContractSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
        defaultForm={defaultForm}
      />
      <TaxesSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options.taxs}
        alertMessage={alertMessage}
        contractForm={contractForm}
      />
      <BPJSSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options?.bpjs}
        setActiveButtonBPJS={setActiveButtonBPJS}
        selectedButtonBPJS={selectedButtonBPJS}
        setSelectedButtonBPJS={setSelectedButtonBPJS}
        selectedValueBPU={selectedValueBPU}
        setSelectedValueBPU={setSelectedValueBPU}
        alertMessage={alertMessage}
        withBPJS={withBPJS}
        setWithBPJS={setWithBPJS}
        optionalBPJS={optionalBPJS}

      />
      <OthersSection
        bankOptions={options?.others?.tkoBank}
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options?.others}
        alertMessage={alertMessage}
      />
      {/* <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button onClick={onNext}>
          Next Step
        </button>
      </div> */}
    </div>
  );
}
