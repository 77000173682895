import JobPostingCard from "@Molecule/JobPostingCard";
import Styles from "./style.module.scss";
// import { getJobPostingList } from "@Services/officer/jobPosting";

export default function JobPostingListContent({
  data,
  setTrigger,
  setShowAction,
  showAction,
}) {

  return (
    <>
      {data?.data?.length === 0 ? (
        <div className={Styles.emptyData}>
          <div className={Styles.boxText}>
            <span className={Styles.warn}>
              Belum ada Data Job Posting yang Ditambahkan
            </span>
            <span className={Styles.click}>
              Tambahkan data Job Posting anda, klik pada tombol tambah job
              posting
            </span>
          </div>
        </div>
      ) : (
        <div className={Styles.container}>
          <div className={Styles.wrapper}>
            {data?.data?.map((each, i) => (
              <JobPostingCard
                key={i}
                data={each}
                showAction={showAction}
                setShowAction={setShowAction}
                setTrigger={setTrigger}
                // refetchData={refetchData}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
