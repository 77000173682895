/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import StaticProgressBar from "@Atom/StaticProgressBar";
import Tooltip from "@Atom/Tooltip";
import { fileBaseUrl } from "@Config/api";
import useQuery from "@Hooks/useQuery";
import useWindowSize from "@Hooks/useWindowSize";
import CustomTable from "@Molecule/CustomTable";
import DashboardSyncComponent from "@Molecule/DashboardSyncComponent";
import { getBMDashboardLog } from "@Services/client/dashboard";
import { downloadBM, downloadClients } from "@Services/manager/bm";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { Skeleton } from "@mui/material";

export default function RBMDashboardLayout({
  syncData,
  isLoadingSync = false,
  isLoadingTable = false,
  isLoadingTable2 = false,
  totalPage = 0,
  value = 10,
  setValue,
  selectedPage,
  setSelectedPage,
  // pageList,
  // setPageList,
  // pages,
  // setPages,
  searchTerm,
  setSearchTerm,
  data = [],
  totalPage2 = 0,
  value2 = 10,
  setValue2,
  selectedPage2,
  setSelectedPage2,
  // pageList2,
  // setPageList2,
  // pages2,
  // setPages2,
  searchTerm2,
  setSearchTerm2,
  data2,
  rbmView = false,
}) {
  const query = useQuery();
  const maxLength = 10;
  const maxLengthRole = 15;

  // /daftar-bm/201160650?u=FITRIA

  const { width } = useWindowSize();
  const navigate = useNavigate();

  const tableLoading = useMemo(() => {
    return (
      isLoadingTable ||
      query?.get("n") !== searchTerm ||
      selectedPage != query?.get("p") ||
      value != query?.get("l")
    );
  }, [isLoadingTable, query, searchTerm, selectedPage, value]);
  const table2Loading = useMemo(() => {
    return (
      isLoadingTable2 ||
      query?.get("n2") !== searchTerm2 ||
      selectedPage2 != query?.get("p2") ||
      value2 != query?.get("l2")
    );
  }, [isLoadingTable2, query, searchTerm2, selectedPage2, value2]);

  const columns = [
    {
      name: "businessManager",
      label: "Business Manager",
      renderData: (row) => (
        <div className={Styles.ppWrapper}>
          <div className={Styles.pp}>
            <img
              src={
                row?.User?.profilePicture
                  ? row?.User?.profilePicture?.includes("http")
                    ? row?.User?.profilePicture?.replace(
                        "https://myworkspace",
                        "https://api1.myworkspace"
                      )
                    : fileBaseUrl + row?.User?.profilePicture
                  : Images.AVA_DEFAULT
              }
              alt=""
            />
            <div>
              <span>
                {row?.User?.name
                  ? row?.User?.name?.length > maxLength
                    ? row?.User?.name?.substring(0, maxLength) + "..."
                    : row?.User?.name
                  : "-"}
              </span>
              <span>
                {" "}
                {row?.User?.jobLevel
                  ? row?.User?.jobLevel?.length > maxLengthRole
                    ? row?.User?.jobLevel?.substring(0, maxLengthRole) + "..."
                    : row?.User?.jobLevel
                  : "-"}
              </span>
              {/* <span>{row?.User?.jobLevel}</span> */}
            </div>
          </div>
          {/* <span>{row?.totalClient}</span> */}
        </div>
      ),
      minWidth: "180px",
      maxWidth: "180px",
      tdClassName: Styles.sticky,
      thClassName: Styles.sticky,
    },
    {
      name: "total",
      label: "Klien",
      // align: "center",
      border: true,
      // renderData: (row) => (
      //   <div className={Styles.totalClient}>{row?.totalClient?.all}</div>
      // ),
    },
    {
      name: "",
      hideOnMobile: true,
      label: "Jumlah Permintaan & Terpenuhi",
      renderData: (row) => (
        <div className={Styles.tooltipWrapper}>
          <Tooltip
            padding="0"
            content={
              <div className={Styles.tooltip}>
                <div className={Styles.qIndicator}>
                  <span>Kuota</span>
                  <span>{row?.quota || 0}</span>
                </div>
                <div className={Styles.tLegends}>
                  <p>
                    <span className={`${Styles.bullet} ${Styles.fulfilled}`} />
                    <span>Terpenuhi</span>
                    <span>:</span>
                    <span>{`${row?.fullfillment || 0}`}</span>
                  </p>
                  <p>
                    <span className={`${Styles.bullet} ${Styles.vendor}`} />
                    <span>Vendor</span>
                    <span>:</span>
                    <span>{row?.vendor || 0}</span>
                  </p>
                  <p>
                    <span className={`${Styles.bullet} ${Styles.vacant}`} />
                    <span>Vacant</span>
                    <span>:</span>
                    <span>{row?.vacant || 0}</span>
                  </p>
                </div>
              </div>
            }
            direction="top"
            backgroundColor="#fff"
            boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
            tooltipMargin="80px"
            isV2
          >
            <StaticProgressBar
              fulfilledAmount={row?.fullfillment || 0}
              middleFulfilledText={`${row?.percentage}`}
              // fulfilledText={`${row?.fulfillment || 0} (${Math.floor(+row?.fulfillment / +row?.quota * 100)}%)`}
              candidatesAmount={row?.fulfillment || 0 + row?.vendor || 0}
              candidatesText={row?.vendor || 0}
              requestAmount={row?.quota || 0}
              vacantAmount={row?.vacant || 0}
              isV3
              borderRadius="0px"
              height={width > 768 ? "32px" : "28px"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "businessManager",
      label: "Clients",
      border: true,
      renderData: (row) => (
        <div className={Styles.clientWrapper}>
          <div className={Styles.logo}>
            <Icon icon="company" size={24} />
          </div>
          <div className={Styles.desc}>
            <span>{row?.name}</span>
            <div className={Styles.clientName}>
              <img
                alt=""
                src={
                  row?.Region?.User?.profilePicture
                    ? row?.Region?.User?.profilePicture
                        ?.toLowerCase()
                        ?.includes("http")
                      ? row?.Region?.User.profilePicture?.replace(
                          "https://myworkspace",
                          "https://api1.myworkspace"
                        )
                      : fileBaseUrl + row?.Region?.User?.profilePicture
                    : Images?.AVA_DEFAULT
                }
              />
              <span>{row?.Region?.User?.name}</span>
            </div>
          </div>
        </div>
      ),
      minWidth: "300px",
      maxWidth: "300px",
      tdClassName: Styles.sticky,
      thClassName: Styles.sticky,
    },
    // {
    //   name: 'totalClient',
    //   label: 'Jumlah Klien',
    //   align: 'right'
    // },
    {
      name: "",
      hideOnMobile: true,
      label: "Jumlah Permintaan & Terpenuhi",
      renderData: (row) => (
        <div className={Styles.tooltipWrapper}>
          <Tooltip
            padding="0"
            content={
              <div className={Styles.tooltip}>
                <div className={Styles.qIndicator}>
                  <span>Kuota</span>
                  <span>{row?.quota || 0}</span>
                </div>
                <div className={Styles.tLegends}>
                  <p>
                    <span className={`${Styles.bullet} ${Styles.fulfilled}`} />
                    <span>Terpenuhi</span>
                    <span>:</span>
                    <span>{`${row?.fullfillment || 0}`}</span>
                    {/* <span>{`${row?.fullfillment || 0} (${Math.floor(row?.fullfillment / row?.quota * 100)}%)`}</span> */}
                  </p>
                  <p>
                    <span className={`${Styles.bullet} ${Styles.vendor}`} />
                    <span>Vendor</span>
                    <span>:</span>
                    <span>{row?.vendor || 0}</span>
                  </p>
                  <p>
                    <span className={`${Styles.bullet} ${Styles.vacant}`} />
                    <span>Vacant</span>
                    <span>:</span>
                    <span>{row?.vacant || 0}</span>
                  </p>

                  <button
                    onClick={() => {
                      if (rbmView) {
                        navigate(
                          `/daftar-bm/${
                            row?.Region?.User?.employeeId
                          }/fulfillment?c=${row?.code}&u=${
                            row?.Region?.User?.name || ""
                          }&fr=dashboard`
                        );
                      } else {
                        navigate(
                          `/clients/${
                            row?.Region?.User?.employeeId
                          }/fulfillment?c=${row?.code}&u=${
                            row?.Region?.User?.name || ""
                          }&fr=dashboard`
                        );
                      }
                    }}
                  >
                    Lihat Detail
                  </button>
                </div>
              </div>
            }
            direction="top"
            backgroundColor="#fff"
            boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
            tooltipMargin="115px"
            isV2
          >
            <StaticProgressBar
              fulfilledAmount={row?.fullfillment || 0}
              middleFulfilledText={`${row?.percentage}`}
              // fulfilledText={`${row?.fulfillment || 0} (${Math.floor(+row?.fulfillment / +row?.quota * 100)}%)`}
              candidatesAmount={row?.fulfillment || 0 + row?.vendor || 0}
              candidatesText={row?.vendor || 0}
              requestAmount={row?.quota || 0}
              vacantAmount={row?.vacant || 0}
              isV3
              borderRadius="0px"
              height={width > 768 ? "32px" : "28px"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const [isDownloadingBM, setIsDownloadingBM] = useState(false);
  const [isDownloadingClients, setIsDownloadingClients] = useState(false);

  const handleDownload = useCallback(async (type) => {
    try {
      type === "bm" ? setIsDownloadingBM(true) : setIsDownloadingClients(true);
      const res =
        type === "bm"
          ? await downloadBM()
          : await downloadClients(
              localStorage.getItem("role")?.includes("REGION")
            );
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}-report.xlsx`);
      document.body.appendChild(link);
      link.click();
      setIsDownloadingBM(false);
      setIsDownloadingClients(false);
    } catch (err) {
      setIsDownloadingBM(false);
      setIsDownloadingClients(false);
      const errMsg = err?.response?.data?.error || err?.message;
      console.log(errMsg);
    }
  }, []);

  const [dataLog, setDataLog] = useState([]);

  const logs = useMemo(() => {
    return dataLog?.map((obj) => {
      return {
        title: obj?.remark,
        clientName: obj?.FulfillmentSummary?.Client?.name,
        value: (
          <p>
            {/* Kota <span>Bandung</span> ditambahkan */}
            {`${obj?.field}: ${obj?.oldValue} → ${obj?.newValue}`}
          </p>
        ),
        updatedAt: new Date(obj?.updatedAt),
      };
    });
  }, [dataLog]);

  const [pageLog, setPageLog] = useState(1);
  const [totalPageLog, setTotalPageLog] = useState(0);

  const [onFirstLoad, setOnFirstLoad] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchLogs = useCallback(
    async (isMore = false) => {
      try {
        if (isMore) {
          setIsLoadingMore(true);
          // setPage(+page + 1)
        }
        const { response } = await getBMDashboardLog(
          isMore ? (+pageLog || 1) + 1 : pageLog,
          10
        );
        if (isMore) {
          setPageLog(pageLog + 1);
        }
        setTotalPageLog(response?.totalPage);
        setDataLog(isMore ? [...logs, ...response?.data] : response?.data);
        setIsLoadingMore(false);
        setOnFirstLoad(false);
      } catch (err) {
        setIsLoadingMore(false);
        setOnFirstLoad(false);
        console.log(err, "err on fetching");
      }
    },
    [logs, pageLog]
  );

  useEffect(() => {
    if (onFirstLoad) fetchLogs();
  }, [fetchLogs, onFirstLoad]);

  return (
    <div className={Styles.container}>
      <DashboardSyncComponent
        syncData={syncData}
        isLoading={isLoadingSync}
        withBMAmount
      />
      <div
        className={`${Styles.tablesWrapper} ${Styles[rbmView ? "rbm" : "bm"]}`}
      >
        {[
          {
            title: "Daftar BM",
            search: searchTerm,
            setSearch: setSearchTerm,
            placeholder: "Search BM name",
            onDownload: () => handleDownload("bm"),
            isDownloading: isDownloadingBM,
            component: (
              <CustomTable
                data={data}
                columns={columns}
                isLoading={tableLoading}
                isLoadingSkeleton={tableLoading}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                value={value}
                setValue={setValue}
                totalPage={totalPage}
                stickyTop
                height="690px"
                isRbm
                isFulfillment
                onClickRow={(row) => {
                  navigate(
                    `/daftar-bm/${row?.User?.employeeId}?u=${
                      row?.User?.name || ""
                    }`
                  );
                }}
              />
            ),
          },
          {
            title: "Daftar Klien",
            search: searchTerm2,
            setSearch: setSearchTerm2,
            placeholder: "Search client name",
            onDownload: () => handleDownload("clients"),
            isDownloading: isDownloadingClients,
            component: (
              <CustomTable
                data={data2}
                columns={columns2}
                isLoadingSkeleton={table2Loading}
                selectedPage={selectedPage2}
                setSelectedPage={setSelectedPage2}
                value={value2}
                setValue={setValue2}
                totalPage={totalPage2}
                isFulfillment
                stickyTop
                height="690px"
              />
            ),
          },
        ]
          ?.filter((obj) => !obj?.title?.includes(!rbmView ? "BM" : "KKK"))
          ?.map((t, tI) => (
            <div key={tI} className={Styles.section}>
              <div className={Styles.sHeader}>
                <div className={Styles.shTop}>
                  <h3>{t?.title}</h3>
                  <div className={Styles.legends}>
                    {[
                      {
                        label: <p>Jumlah Terpenuhi</p>,
                        bgColor: "#1571DE",
                      },
                      {
                        label: (
                          <p>
                            <span>Vendor</span> Lain
                          </p>
                        ),
                        bgColor: "#91BDF1",
                      },
                      {
                        label: (
                          <p>
                            <span>Vacant</span>
                          </p>
                        ),
                        bgColor: "#E6E3E3",
                      },
                    ]?.map((i, iI) => (
                      <div key={iI} className={Styles.legend}>
                        <div style={{ backgroundColor: i?.bgColor }} />
                        {i?.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={Styles.shBottom}>
                  <div>
                    <SearchBar
                      value={t?.search}
                      onChange={(e) => t?.setSearch(e?.target?.value)}
                      placeholder={t?.placeholder}
                    />
                  </div>
                  <button onClick={t?.onDownload} disabled={t?.isDownloading}>
                    <span>Download Report</span>
                    <Icon icon={"download"} size={20} />
                  </button>
                </div>
              </div>
              {t?.component}
            </div>
          ))}

        {!rbmView && (
          <div className={Styles.logCard}>
            <h3>Informasi</h3>

            <div
              className={`${Styles.logs} ${
                !onFirstLoad && !logs?.length ? Styles.centered : ""
              }`}
            >
              {onFirstLoad &&
                [...Array(5)]?.map((row, i) => {
                  return (
                    <div key={i} className={Styles.logLoading}>
                      <div className={Styles.top}>
                        <div>
                          <Skeleton
                            duration={2}
                            className={Styles.defaultSkeleton}
                            height={20}
                            width={200}
                            sx={{ borderRadius: "8px" }}
                          />
                        </div>

                        <Skeleton
                          duration={2}
                          className={Styles.defaultSkeleton}
                          height={20}
                          width={80}
                          sx={{ borderRadius: "8px" }}
                        />
                      </div>

                      <Skeleton
                        duration={2}
                        className={Styles.defaultSkeleton}
                        height={20}
                        width={"50%"}
                        sx={{ borderRadius: "8px" }}
                      />

                      <Skeleton
                        duration={2}
                        className={Styles.defaultSkeleton}
                        height={20}
                        width={"100%"}
                        sx={{ borderRadius: "8px" }}
                      />
                    </div>
                  );
                })}
              {!onFirstLoad && !logs?.length && (
                <div className={Styles.empty}>
                  <img src={Images.LOG_ICON} alt="" />
                  <span>Informasi Kosong</span>
                  <span>Belum ada informasi yang tercatat</span>
                </div>
              )}

              {logs?.map((l, lI) => (
                <div key={lI} className={Styles.log}>
                  <div className={Styles.bullet} />
                  <div className={Styles.desc}>
                    <div className={Styles.dTop}>
                      <h4>{l?.title}</h4>
                      <span>{moment(l?.updatedAt)?.format("lll")}</span>
                    </div>
                    <div className={Styles.dBottom}>
                      <div className={Styles.dClientName}>
                        <Icon
                          icon={"company-dash"}
                          size={12}
                          color={"#9E9E9E"}
                        />
                        <span>{l?.clientName}</span>
                      </div>
                      <div className={Styles.dLabel}>{l?.value}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {totalPageLog > pageLog && !isLoadingMore && (
              <div className={Styles.seeMoreWrapper}>
                <button onClick={() => fetchLogs(true)}>
                  <span>Click to See more</span>
                  <Icon icon={"arrow-down"} size={20} />
                </button>
              </div>
            )}

            {isLoadingMore && (
              <div className={Styles.isLoadingMore}>
                <span>Loading...</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
