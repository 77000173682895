/* eslint-disable no-unused-vars */
import Styles from "./styles.module.scss";

import { Doughnut } from "react-chartjs-2";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Box, Skeleton, Typography } from "@mui/material";
import SkeletonChart from "@Atom/SkeletonChart";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ManPowerChart({
  defaultData = [
    // {
    //   label: 'Vacant',
    //   bgColor: 'red',
    //   amount: 100
    // },
  ],
  middleText = "0%",
  isLoading,
}) {
  // const dataTemp = [150, 100]; // Switch the order to make "Penuhan" first
  const dataTemp = defaultData?.map((obj) => obj?.amount);

  const data = {
    // labels: ["Vacant", "Penuhan"],
    labels: defaultData?.map((obj) => obj?.label),
    datasets: [
      {
        data: dataTemp, // 50% vacant, 50% penuhan
        // backgroundColor: [
        //   "rgba(245, 245, 245, 1)", // Abu-abu
        //   "rgba(66, 133, 244, 1)", // Biru
        // ],
        backgroundColor: defaultData?.map((obj) => obj?.bgColor),
        hoverBackgroundColor: defaultData?.map(
          (obj) => obj?.hoverBgColor || obj?.bgColor
        ),
        borderWidth: 0,
        // borderRadius: 10,
      },
    ],
  };

  const options = {
    cutout: "80%", // Make donut thicker
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
    },
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltip
      },
      legend: {
        display: false, // Hide legend
      },
      datalabels: {
        display: false,
      },

      // Add text in the center
    },
  };

  function formatNumber(num) {
    if (num >= 1e12) {
      return Math.floor(num / 1e12) + "T";
    } else if (num >= 1e9) {
      return Math.floor(num / 1e9) + "M";
    } else if (num >= 1e6) {
      return Math.floor(num / 1e6) + "jt";
    } else if (num >= 1e3) {
      return Math.floor(num / 1e3) + "rb";
    } else {
      return num.toString();
    }
  }

  const totalValue = formatNumber(
    dataTemp.reduce((acc, value) => acc + value, 0)
  );

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = "bolder 35px sans-serif";
      ctx.fillStyle = "#000";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillText(
        isLoading ? "" : middleText,
        // totalValue,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );

      const offset = 35;
      ctx.font = "12px sans-serif"; // Ubah font jika diperlukan
      ctx.fillStyle = "#C2C2C2";
      ctx.fillText(
        isLoading ? "Loading" : "Terpenuhi",
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + (isLoading ? 0 : offset)
      );
      ctx.restore();
    },
  };

  return (
    <div className={Styles.chartDoughnut}>
      {isLoading ? (
        <SkeletonChart />
      ) : (
        <Doughnut data={data} options={options} plugins={[textCenter]} />
      )}
    </div>
  );
}
