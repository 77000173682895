import LoadingAnimation from "@Atom/LoadingAnimation";
import JobPostingListContent from "@Organism/Recruiter/JobPosting/JobPostingList/Content";
import JobPostingListHeader from "@Organism/Recruiter/JobPosting/JobPostingList/Header";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";
import {
  getJobPostingList,
  getJobPostingListSync,
} from "@Services/officer/jobPosting";
import moment from "moment";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import {
  Await,
  defer,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Styles from "./style.module.scss";
import { Helmet } from "react-helmet-async";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import FilterJobPosting from "@Molecule/_modal/FilterJobPosting";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const query = url.searchParams;
  // const limit = query.get("l") || 15;
  const page = query.get("p") || 1;
  const search = query.get("s") || "";
  const status = query.get("st") || "";
  const dateFrom = query.get("df")
    ? moment(new Date(query.get("df")))?.format("YYYY-MM-DD")
    : "";
  const dateTo = query.get("dt")
    ? moment(new Date(query.get("dt")))?.format("YYYY-MM-DD")
    : "";

  const flbNumber = query.get("flb") || "";
  const clientName = query.get("cl") || "";
  const locationName = query.get("lc") || "";

  const packageJobPostingListPromise = getJobPostingList(
    16,
    page,
    dateFrom,
    dateTo,
    status,
    encodeURIComponent(search),
    encodeURIComponent(flbNumber),
    encodeURIComponent(clientName),
    encodeURIComponent(locationName)
  );

  return defer({
    packageJobPostingList: packageJobPostingListPromise,
  });
}

export default function JobPostingListPage() {
  const { packageJobPostingList } = useLoaderData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const underMaintenance = false;
  const [showFilter, setShowFilter] = useState(false);
  const [trigger, setTrigger] = useState("");
  const [showAction, setShowAction] = useState("");
  const [dataSync, setDataSync] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const query = useQuery();
  const searchTermQuery = query.get("s") || "";
  const statusQuery = query.get("st") || "";
  const dateFromQuery = query.get("df")
    ? moment(new Date(query?.get("df")))?.format("YYYY-MM-DD")
    : "";
  const dateToQuery = query.get("dt")
    ? moment(new Date(query?.get("dt")))?.format("YYYY-MM-DD")
    : "";
  const pageQuery = query.get("p") || "1";
  const flbQuery = query.get("flb") || "";
  const clientQuery = query.get("cl") || "";
  const locationQuery = query.get("lc") || "";

  const [page, setPage] = useState(pageQuery);

  const [status, setStatus] = useState(statusQuery);
  const [dateFrom, setDateFrom] = useState(dateFromQuery);
  const [dateTo, setDateTo] = useState(dateToQuery);
  const [searchTerm, setSearchTerm] = useState(searchTermQuery);
  const [client, setClient] = useState(clientQuery || "");
  const [flb, setFlb] = useState(flbQuery || "");
  const [location, setLocation] = useState(locationQuery || "");

  const [flbNumber, setFlbNumber] = useState("");
  const [titleName, setTitleName] = useState("");
  const [positionName, setPositionName] = useState("");
  const [clientName, setClientName] = useState("");
  const [placement, setPlacement] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusName, setStatusName] = useState("");

  const debouncedSearchTitle = useDebounce(titleName || positionName, 500);
  const debouncedSearchFlb = useDebounce(flbNumber, 500);
  const debouncedSearchClient = useDebounce(clientName, 500);
  const debouncedSearchLocation = useDebounce(placement, 500);

  const refetchDataSync = useCallback(async () => {
    try {
      setIsLoading(true);
      const { response } = await getJobPostingListSync(
        encodeURIComponent(debouncedSearchFlb) || "",
        encodeURIComponent(debouncedSearchTitle) || "",
        encodeURIComponent(debouncedSearchClient) || "",
        encodeURIComponent(debouncedSearchLocation) || ""
      );
      setDataSync(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, [
    debouncedSearchFlb,
    debouncedSearchTitle,
    debouncedSearchClient,
    debouncedSearchLocation,
  ]);

  useEffect(() => {
    refetchDataSync();
  }, [refetchDataSync]);

  useEffect(() => {
    navigate(
      `${pathname}?p=${page}&s=${searchTerm}&st=${status}&tr=${trigger}&df=${
        dateFrom ? moment(new Date(dateFrom))?.format("YYYY-MM-DD") : ""
      }&dt=${dateTo ? moment(new Date(dateTo))?.format("YYYY-MM-DD") : ""}&cl=${
        client || ""
      }&flb=${flb || ""}&lc=${location || ""}`,
      { replace: true }
    );
  }, [
    dateFrom,
    dateTo,
    searchTerm,
    navigate,
    page,
    pathname,
    status,
    trigger,
    client,
    flb,
    location,
  ]);

  useEffect(() => {
    setFlbNumber(flb || "");
    setTitleName(searchTerm || "");
    setPositionName(searchTerm || "");
    setClientName(client || "");
    setPlacement(location || "");
    setStartDate(dateFrom || "");
    setEndDate(dateTo || "");
    setStatusName(status || "");
  }, [flb, searchTerm, client, location, dateFrom, dateTo, status]);

  const onClickOption = (obj) => {
    setSearchTerm(obj?.name || "");
    setFlb(obj?.flb || "");
    setClient(obj?.clientName || "");
    setLocation(obj?.city || "");
    setDateFrom(
      obj?.startDate
        ? moment(new Date(obj?.startDate))?.format("YYYY-MM-DD")
        : ""
    );
    setDateTo(
      obj?.endDate ? moment(new Date(obj?.endDate))?.format("YYYY-MM-DD") : ""
    );
    setStatus(obj?.statusName || "");
  };

  const disabledValue = useMemo(() => {
    let valid = false;
    if (
      flb ||
      searchTerm ||
      client ||
      location ||
      dateFrom ||
      dateTo ||
      status
    ) {
      valid = true;
    }
    return valid;
  }, [flb, searchTerm, client, location, dateFrom, dateTo, status]);

  if (underMaintenance) {
    return <UnderMaintenacePage />;
  }

  return (
    <Suspense
      fallback={
        <div className={Styles.container}>
          <Helmet>
            <title>Kerja 365 | Job Posting List</title>
          </Helmet>

          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        </div>
      }
    >
      <Await
        resolve={packageJobPostingList}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageJobPostingList) => (
          <div className={Styles.container}>
            <Helmet>
              <title>Kerja 365 | Job Posting List</title>
            </Helmet>
            <div className={Styles.headerWrapper}>
              <JobPostingListHeader
                data={packageJobPostingList?.response}
                trigger={trigger}
                setShowAction={setShowAction}
                showAction={showAction}
                isLoading={isLoading}
                dataSync={dataSync}
                pageQuery={pageQuery}
                searchTerm={titleName}
                setSearchTerm={setTitleName}
                setPage={setPage}
                onClickOption={onClickOption}
                setShowFilter={setShowFilter}
                flb={flb}
                client={client}
                location={location}
                dateFrom={dateFrom}
                dateTo={dateTo}
                status={status}
                page={page}
              />
              {/* <SearchContent data={packageJobPostingList?.response} /> */}
            </div>

            {searchTermQuery !== searchTerm ||
            statusQuery !== status ||
            dateFromQuery !== dateFrom ||
            dateToQuery !== dateTo ||
            flbQuery !== flb ||
            clientQuery !== client ||
            locationQuery !== location ||
            +page !== +pageQuery ? (
              <div className={Styles.loadingWrapper}>
                <LoadingAnimation />
              </div>
            ) : (
              <div className={Styles.content}>
                <JobPostingListContent
                  data={packageJobPostingList?.response}
                  setTrigger={setTrigger}
                  setShowAction={setShowAction}
                  showAction={showAction}
                />
              </div>
            )}

            {showFilter && (
              <FilterJobPosting
                handleClose={() => setShowFilter(false)}
                isLoading={isLoading}
                dataSync={dataSync}
                flbNumber={flbNumber}
                setFlbNumber={setFlbNumber}
                onClickOption={onClickOption}
                searchTerm={positionName}
                setSearchTerm={setPositionName}
                clientName={clientName}
                setClientName={setClientName}
                placement={placement}
                setPlacement={setPlacement}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStatusName={setStatusName}
                statusName={statusName}
                disabledValue={disabledValue}
              />
            )}
          </div>
        )}
      </Await>
    </Suspense>
  );
}
