import LoadingAnimation from '@Atom/LoadingAnimation';
import ExtendContractLayout from '@Organism/BusinessSupport/Contracts/ExtendContract';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';
import { fetchBSDelegateClient } from '@Services/client/order';
import {
    getContractListSync,
    getExtendCandidateDetail
} from '@Services/support/candidate';
import { Suspense, useState } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';
import Styles from './styles.module.scss';

export async function loader({ params }) {
    const { employeeId } = params;

    // const contractDetailsPromise = getCandidateDetails(employeeId);
    const contractDetailsPromise = getExtendCandidateDetail(employeeId);
    const contractSyncPromise = getContractListSync();
    const clientAllPromise = fetchBSDelegateClient();
    return defer({
        packageContractDetails: contractDetailsPromise,
        packageContractSync: contractSyncPromise,
        packageClientAll: clientAllPromise,
    });
}

export default function ExtendContract() {
    const { packageContractDetails, packageContractSync, packageClientAll } = useLoaderData();
    const [trigger, setTrigger] = useState('');

    return (
        <div className={Styles.container}>
            <Suspense
                fallback={
                    <div className={Styles.loadingWrapper}>
                        <LoadingAnimation />
                    </div>
                }>
                <Await
                    resolve={packageContractDetails}
                    errorElement={
                        <div style={{ height: 'calc(100vh - 220px)' }}>
                            <AsyncErrorBoundary />
                        </div>
                    }>
                    {(packageContractDetails) => (
                        <Suspense
                            fallback={
                                <div className={Styles.loadingWrapper}>
                                    <LoadingAnimation />
                                </div>
                            }>
                            <Await
                                resolve={packageContractSync}
                                errorElement={
                                    <div style={{ height: 'calc(100vh - 220px)' }}>
                                        <AsyncErrorBoundary />
                                    </div>
                                }>
                                {(packageContractSync) => (
                                    <Suspense
                                        fallback={
                                            <div className={Styles.loadingWrapper}>
                                                <LoadingAnimation />
                                            </div>
                                        }>
                                        <Await
                                            resolve={packageClientAll}
                                            errorElement={
                                                <div style={{ height: 'calc(100vh - 220px)' }}>
                                                    <AsyncErrorBoundary />
                                                </div>
                                            }>
                                            {(packageClientAll) => (
                                                <ExtendContractLayout
                                                trigger={trigger}
                                                setTrigger={setTrigger}
                                                defaultData={packageContractDetails?.response}
                                                contractList={packageContractSync}
                                                clientList={packageClientAll}
                                            />
                                            )}
                                        </Await>
                                    </Suspense>
                                )}
                            </Await>
                        </Suspense>
                    )}
                </Await>
            </Suspense>
        </div>
    );
}
