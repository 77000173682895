import Hamburger from "@Atom/Hamburger";
import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BMNavbar from "./ManagersNavbar";
import RONavbar from "./RecruitersNavbar";
import Styles from "./navbar.module.scss";
import ClientNavbar from "./ClientsNavbar";
import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import ClientProfileModal from "@Molecule/_modal/ClientProfileModal";
import { fileBaseUrl } from "@Config/api";
import ModalWrapper from "@Atom/ModalWrapper";
import useWindowSize from "@Hooks/useWindowSize";
import ICON_365 from "@Assets/Images/icon365-light.png";

const MuiPopover = styled(Popover)({
  "& 	.MuiPopover-root": {},
  "& .MuiPopover-paper": {
    overflow: "hidden",
    height: "96px",
    borderRadius: 8,
    width: "197px",
    padding: "0px 8px",
    paddingBottom: "16px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
  },
});

export default function Navbar({ children, ...props }) {
  const location = useLocation();
  const pathname = location.pathname;
  const role = localStorage.getItem("role");
  const name = localStorage.getItem("username");
  const profilePicture = localStorage.getItem("profilePicture");
  const [onHover, setOnHover] = useState(false);
  // const role = sessionStorage.getItem("role");
  // const name = sessionStorage.getItem("username");
  // const profilePicture = sessionStorage.getItem("profilePicture");
  const [profileModalActive, setProfileModalActive] = useState(false);

  const isClient = localStorage.getItem("isClient");

  const boxRef = useRef();
  const burgerRef = useRef();
  const profileModalRef = useRef();
  const inactiveRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  const burgerOutsideClick = UseOutsideClick(burgerRef);
  const modalOutsideClick = UseOutsideClick(profileModalRef);

  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(false), [pathname]);

  const { width } = useWindowSize();

  useEffect(() => {
    if (open && boxOutsideClick && burgerOutsideClick) {
      setOpen(!boxOutsideClick);
    }
  }, [boxOutsideClick, burgerOutsideClick, open]);

  useEffect(() => {
    if (width <= 768 && profileModalActive && modalOutsideClick) {
      setProfileModalActive(false);
    }
  }, [modalOutsideClick, profileModalActive, width]);

  const navigate = useNavigate();

  const active = useMemo(() => {
    return pathname;
  }, [pathname]);

  const activeInfo = useMemo(() => {
    return pathname.split("/");
  }, [pathname]);

  useEffect(() => {
    // console.log(profileModalActive, "PMA");
  }, [profileModalActive]);

  useEffect(() => {
    if (open) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  const [anchorEl, setAnchorEl] = useState(null);

  const navigateToProfile = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileHandler = () => {
    setProfileModalActive(true);
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleLogout = (isClient) => {
    sessionStorage.clear();
    localStorage.clear();
    if (
      role === "BUSINESS MANAGER" ||
      role === "BUSINESS DIRECTOR" ||
      role === "RECRUITMENT OFFICER"
    ) {
      if (isClient) {
        navigate("/login");
      } else {
        navigate("/sign-in");
      }
    } else {
      navigate("/");
    }
  };

  return (
    <div className={Styles.container}>
      <ModalWrapper show={profileModalActive}>
        <div ref={profileModalActive ? profileModalRef : inactiveRef}>
          <ClientProfileModal handleDone={() => setProfileModalActive(false)} />
        </div>
      </ModalWrapper>

      <div
        style={{ display: isClient === "true" ? "none" : "flex" }}
        ref={boxRef}
        className={`${width <= 768
          ? Styles.sideBar
          : isClient === "true"
            ? Styles.sideBar
            : Styles.sideBarDefault
          } ${open ? Styles.opened : Styles.closed}`}
        onMouseEnter={() => {
          setOnHover(true);
        }}
        onMouseLeave={() => {
          setOnHover(false);
        }}
      >
        <div className={`${Styles.logoWrapper} ${Styles.controlWeb}`}>
          {onHover ? (
            <div className={Styles.iconWrapper}>
              <img
                src={Images.KERJA365_LIGHT}
                alt="logo-365"
                onClick={() => navigate("/")}
                height={"34px"}
              />
            </div>
          ) : (
            <div className={Styles.iconWrapper}>
              <img src={ICON_365} alt="" height={"34px"} />
            </div>
          )}
          {/* <div className={Styles.iconWrapper}>
            <img
              src={Images.KERJA365_LIGHT}
              alt="logo-365"
              onClick={() => navigate("/")}
              height={"34px"}
            />
          </div> */}
          <img
            src={Images.ICON365_LIGHT}
            alt="icon-365"
            className={Styles.iconOnly}
          />
          <div className={Styles.burgerWrapper}>
            <Hamburger showClose={true} open={open} setOpen={setOpen} />
          </div>
        </div>
        <div className={`${Styles.logoWrapper} ${Styles.controlMobile}`}>
          <div className={`${Styles.topControlMobile}`}>
            <Icon icon={"bell"} size={22} />
            <div className={Styles.profile}>
              <img
                src={
                  profilePicture && profilePicture !== "null"
                    ? profilePicture?.includes("http")
                      ? profilePicture?.replace(
                        "https://myworkspace",
                        "https://api1.myworkspace"
                      )
                      : fileBaseUrl + profilePicture
                    : Images.AVA_DEFAULT
                }
                alt="ava-default"
                onClick={handleClick}
              />
              <MuiPopover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className={Styles.Popover}
              >
                <label>Action</label>
                <div className={Styles.Stacked} onClick={navigateToProfile}>
                  <Icon icon="user" size={17} />
                  <span>Profile</span>
                </div>
                <div
                  className={Styles.Stacked}
                  onClick={() =>
                    handleLogout(
                      localStorage?.getItem("isClient") === "true" ||
                        localStorage?.getItem("isClient") === true
                        ? true
                        : false
                    )
                  }
                >
                  <Icon icon="log-out" size={17} color="#FF3E13" />
                  <span>Log Out</span>
                </div>
              </MuiPopover>
            </div>
          </div>
          <div className={Styles.burgerWrapper}>
            <Hamburger showClose={true} open={open} setOpen={setOpen} />
          </div>
        </div>
        {/* {(role === "BUSINESS MANAGER" || role === "BUSINESS DIRECTOR")
        ? (
          <BMNavbar active={active} activeInfo={activeInfo} setOpen={setOpen} />
        ) : (
          <RONavbar active={active} activeInfo={activeInfo} setOpen={setOpen} />
        )} */}
        {(role === "BUSINESS MANAGER" ||
          role === "BUSINESS DIRECTOR" ||
          role === "REGION BUSINESS MANAGER") &&
          isClient === "false" && (
            <BMNavbar
              active={active}
              activeInfo={activeInfo}
              setOpen={setOpen}
              onHover={onHover}
              width={width}
            />
          )}
        {role === "RECRUITMENT OFFICER" && isClient === "false" && (
          <RONavbar
            active={active}
            activeInfo={activeInfo}
            setOpen={setOpen}
            onHover={onHover}
            width={width}
          />
        )}
        {isClient === "true" && (
          <ClientNavbar
            active={active}
            activeInfo={activeInfo}
            setOpen={setOpen}
            onHover={onHover}
            width={width}
          />
        )}

        <div className={Styles.settingWrapper}>
          <NavLink
            style={{ display: "none" }}
            to={`/settings`}
            className={({ isActive, isPending }) =>
              isActive && active === `/settings`
                ? Styles.navActive
                : isPending
                  ? "pending"
                  : ""
            }
          >
            <button className={Styles.navButton} onClick={() => setOpen(false)}>
              {(role === "BUSINESS MANAGER" || role === "BUSINESS DIRECTOR") &&
                isClient === "false" ? (
                <Icon
                  icon="setting-filled"
                  color={active === "/settings" ? "#1571DE" : "#757575"}
                  size={20}
                />
              ) : active === "/settings" ? (
                <Icon icon="setting-outline" color="#1571DE" size={20} />
              ) : (
                <Icon icon="setting-outline" color="#757575" size={20} />
              )}
              <span>Settings</span>
            </button>
          </NavLink>
          {/* {isClient === 'false'&& (
            <button className={Styles.navButton} onClick={handleLogout}>
              {role === "BUSINESS MANAGER" || role === "BUSINESS DIRECTOR" ? (
                <Icon icon="log-out" color="#757575" size={20} />
              ) : (
                <Icon icon="logout-outline" color="#757575" size={20} />
              )}
              <span>Log out</span>
            </button>
          )} */}
          {isClient === "true" && (
            <>
              <div className={Styles.chatBox}>
                <div className={Styles.logo}>
                  <img src={Images?.WHATSAPP_LOGO} alt="wa" />
                </div>
                <h4>Help Desk</h4>
                <p>Butuh bantuan? kami siap membantu anda</p>
                <button
                  onClick={() =>
                    window.open(
                      "https://wa.me/6287840101425",
                      "_blank",
                      "rel=noopener noreferrer"
                    )
                  }
                >
                  <span>Mulai Chat</span>
                  <Icon icon="arrow-right-stick" size={20} />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={`${Styles.content} ${isClient === "true" && Styles.contentClient
          }`}
      >
        <div className={Styles.topBarWrapper}>
          <div
            className={`${Styles.topBar} ${open ? Styles.hide : ""} ${isClient === "true" ? Styles.limited : ""
              }`}
            style={{
              maxWidth:
                sessionStorage?.getItem("currentMode") === "homepage"
                  ? "1440px"
                  : "100%",
            }}
          >
            {
              isClient === "true"
              &&
              <img className={Styles.smallLogo} alt="" src={Images.KERJA365_LIGHT} />
            }
            <div className={Styles.topControl}>
              <div ref={burgerRef} className={Styles.burgerWrapper}>
                {isClient !== "true" && (
                  <Hamburger open={open} showClose={true} setOpen={setOpen} />
                )}
              </div>
              {
                isClient !== "true"
                &&
                <h2>
                  {activeInfo[1] === "" || activeInfo[1] === "dashboard"
                    ? role !== "RECRUITMENT OFFICER"
                      ? "Dashboard"
                      : "Halaman Utama"
                    : activeInfo[1] === "pipeline-list"
                      ? "Pipeline"
                      : activeInfo[1]?.split("-").join(" ")}
                </h2>
              }

            </div>
            <div className={`${Styles.topControl} ${Styles.controlWeb}`}>
              <Icon icon={"bell"} size={22} />
              <div className={Styles.verLine} />
              <div className={Styles.profile} onClick={handleClick}>
                <img
                  src={
                    profilePicture && profilePicture !== "null"
                      ? profilePicture?.includes("http")
                        ? profilePicture?.replace(
                          "https://myworkspace",
                          "https://api1.myworkspace"
                        )
                        : fileBaseUrl + profilePicture
                      : Images.AVA_DEFAULT
                  }
                  alt="ava-default"
                  style={{ objectFit: "cover" }}
                />
                <div className={Styles.userTag}>
                  <span>{name?.toLowerCase()}</span>
                  <span>
                    {role === "BUSINESS MANAGER" &&
                      isClient === "false" &&
                      "Business Manager"}
                    {role === "RECRUITMENT OFFICER" &&
                      isClient === "false" &&
                      "Recruitment Officer"}
                    {role === "BUSINESS DIRECTOR" &&
                      isClient === "false" &&
                      "Business Director"}
                    {role !== "BUSINESS DIRECTOR" &&
                      role !== "RECRUITMENT OFFICER" &&
                      role !== "BUSINESS MANAGER" &&
                      isClient === "false" &&
                      role}
                    {isClient === "true" && role}
                  </span>
                </div>
              </div>
              <MuiPopover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className={Styles.Popover}
              >
                <label>Action</label>
                <div className={Styles.Stacked} onClick={navigateToProfile}>
                  <Icon icon="user" size={17} />
                  <span>Profile</span>
                </div>
                <div
                  className={Styles.Stacked}
                  onClick={() =>
                    handleLogout(
                      localStorage?.getItem("isClient") === "true" ||
                        localStorage?.getItem("isClient") === true
                        ? true
                        : false
                    )
                  }
                >
                  <Icon icon="log-out" size={17} color="#FF3E13" />
                  <span>Log Out</span>
                </div>
              </MuiPopover>
            </div>
            <div className={`${Styles.topControl} ${Styles.controlMobile}`}>
              <Icon icon={"bell"} size={22} />
              <div className={Styles.profile} onClick={handleClick}>
                <img
                  src={
                    profilePicture && profilePicture !== "null"
                      ? profilePicture?.includes("http")
                        ? profilePicture?.replace(
                          "https://myworkspace",
                          "https://api1.myworkspace"
                        )
                        : fileBaseUrl + profilePicture
                      : Images.AVA_DEFAULT
                  }
                  alt="ava-default"
                />
              </div>
              <MuiPopover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className={Styles.Popover}
              >
                <label>Action</label>
                <div
                  className={Styles.Stacked}
                  onClick={
                    isClient === "true" ? profileHandler : navigateToProfile
                  }
                >
                  <Icon icon="user" size={17} />
                  <span>Profile</span>
                </div>
                <div
                  className={Styles.Stacked}
                  onClick={() =>
                    handleLogout(
                      localStorage?.getItem("isClient") === "true" ||
                        localStorage?.getItem("isClient") === true
                        ? true
                        : false
                    )
                  }
                >
                  <Icon icon="log-out" size={17} color="#FF3E13" />
                  <span>Log Out</span>
                </div>
              </MuiPopover>
            </div>
          </div>
        </div>

        {children}
      </div>
      <div className={`${Styles.Overlay} ${open ? Styles.Visible : ""}`} />
    </div>
  );
}
