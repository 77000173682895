import RBMDashboardLayout from "./RBMDashboard";

export default function BMDashboardLayout({
  rbmView = false,
  syncData,
  isLoadingSync = false,
  isLoadingTable = false,
  isLoadingTable2 = false,
  totalPage = 0,
  value = 10,
  setValue,
  selectedPage,
  setSelectedPage,
  pageList,
  setPageList,
  pages,
  setPages,
  searchTerm,
  setSearchTerm,
  data = [],
  totalPage2 = 0,
  value2 = 10,
  setValue2,
  selectedPage2,
  setSelectedPage2,
  pageList2,
  setPageList2,
  pages2,
  setPages2,
  searchTerm2,
  setSearchTerm2,
  data2 = [],
}) {
  return (
    <RBMDashboardLayout
      rbmView={rbmView}
      syncData={syncData}
      isLoadingSync={isLoadingSync}
      isLoadingTable={isLoadingTable}
      isLoadingTable2={isLoadingTable2}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      data={data}
      totalPage={totalPage}
      value={value}
      setValue={setValue}
      pages={pages}
      setPages={setPages}
      pageList={pageList}
      setPageList={setPageList}
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
      data2={data2}
      totalPage2={totalPage2}
      value2={value2}
      setValue2={setValue2}
      selectedPage2={selectedPage2}
      setSelectedPage2={setSelectedPage2}
      pageList2={pageList2}
      setPageList2={setPageList2}
      pages2={pages2}
      setPages2={setPages2}
      searchTerm2={searchTerm2}
      setSearchTerm2={setSearchTerm2}
    />
  );
}
