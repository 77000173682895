import { useEffect, useRef } from "react";
import Styles from "./styles.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";
import SearchBar from "@Atom/SearchBar";
import InputDate from "@Atom/InputDate";
import AutocompleteV2 from "@Atom/AutocompleteV2";

export default function FilterJobPosting({
  handleClose,
  isLoading,
  dataSync,
  flbNumber,
  setFlbNumber,
  onClickOption,
  searchTerm,
  setSearchTerm,
  clientName,
  setClientName,
  placement,
  setPlacement,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setStatusName,
  statusName,
  disabledValue,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
  }, [boxOutsideClick, handleClose]);

  return (
    <div className={Styles.bodyFilter}>
      <div ref={boxRef}>
        <div className={Styles.header}>
          <span>Filter</span>
          {disabledValue ? (
            <span
              onClick={() => {
                onClickOption(null);
                handleClose();
              }}
            >
              Reset
            </span>
          ) : (
            <span></span>
          )}
        </div>

        <div className={Styles.filterContent}>
          <div>
            <span>Nomor Dokumen</span>
            <div>
              <SearchBar
                isJobPosting={true}
                value={flbNumber}
                placeholder={"Cari Nomor Dokumen"}
                onChange={setFlbNumber}
                isLoadingFilter={isLoading}
                onClickOption={() => {}}
                options={
                  dataSync?.contracts?.map((el, idx) => {
                    return {
                      id: idx,
                      name: el,
                    };
                  }) || []
                }
              />
            </div>
          </div>

          <div>
            <span>Nama Posisi</span>
            <div>
              <SearchBar
                isJobPosting={true}
                value={searchTerm}
                placeholder={"Cari Posisi"}
                onChange={setSearchTerm}
                isLoadingFilter={isLoading}
                options={
                  dataSync?.positions?.map((el, idx) => {
                    return {
                      id: idx,
                      name: el,
                    };
                  }) || []
                }
              />
            </div>
          </div>

          <div>
            <span>Nama Perusahaan</span>
            <div>
              <SearchBar
                isJobPosting={true}
                value={clientName}
                placeholder={"Cari Nama Perusahaan"}
                onChange={setClientName}
                isLoadingFilter={isLoading}
                options={
                  dataSync?.clients?.map((el, idx) => {
                    return {
                      id: idx,
                      name: el,
                    };
                  }) || []
                }
              />
            </div>
          </div>

          <div>
            <span>Lokasi Kerja</span>
            <div>
              <SearchBar
                isJobPosting={true}
                value={placement}
                placeholder={"Cari Lokasi"}
                onChange={setPlacement}
                isLoadingFilter={isLoading}
                options={
                  dataSync?.cities?.map((el, idx) => {
                    return {
                      id: idx,
                      name: el,
                    };
                  }) || []
                }
              />
            </div>
          </div>

          <div>
            <span>Status</span>
            <div>
              <AutocompleteV2
                value={statusName}
                setValue={setStatusName}
                placeholder={"Status"}
                options={[
                  {
                    name: "Active",
                  },
                  {
                    name: "Inactive",
                  },
                  {
                    name: "Draft",
                  },
                  {
                    name: "Publish",
                  },
                  {
                    name: "Unpublish",
                  },
                  {
                    name: "Expired",
                  },
                ]}
              />
            </div>
          </div>

          <div>
            <span>Tanggal Pembuatan Job Posting</span>
            <div className={`${Styles.noHeight}`}>
              <div className={Styles.dateBox}>
                <div className={Styles.date}>
                  <InputDate
                    value={startDate}
                    setValue={setStartDate}
                    placeholder={"Dari"}
                  />
                </div>
                <div className={Styles.date}>
                  <InputDate
                    value={endDate}
                    setValue={setEndDate}
                    placeholder={"Ke"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.buttonSection}>
          <div
            className={`${
              !flbNumber &&
              !searchTerm &&
              !clientName &&
              !placement &&
              !startDate &&
              !endDate &&
              !statusName &&
              Styles.disabled
            }`}
            onClick={() => {
              if (
                !flbNumber &&
                !searchTerm &&
                !clientName &&
                !placement &&
                !startDate &&
                !endDate &&
                !statusName
              ) {
                return;
              } else {
                onClickOption({
                  flb: flbNumber || "",
                  name: searchTerm || "",
                  clientName: clientName || "",
                  city: placement || "",
                  startDate: startDate || "",
                  endDate: endDate || "",
                  statusName: statusName || "",
                });
                handleClose();
              }
            }}
          >
            Terapkan
          </div>
        </div>
      </div>
    </div>
  );
}
