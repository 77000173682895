import Icon from "@Atom/Icon";
import MenuListPopup from "@Molecule/MenuListPopup";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function VerificationButtons({
  verificationStatus = [true, false],
  onVerifyActions = [() => {}, () => {}],
  verificationDates = [new Date(), new Date()],
  isAcceptedStatus = [true, true],
  setShowAccept,
  setShowReject,
  tag,
  secondVerifyAvailable,
  stage,
  type,
}) {
  const [expandPopup, setExpandPopup] = useState(false);
  const [expandPopupOne, setExpandPopupOne] = useState("");

  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      setExpandPopupOne("");
    }
  }, [boxOutsideClick]);

  const confirmationOptions = [
    {
      title: "OK",
      icon: "shield-check",
      iconColor: "#22970F",
      textColor: "#22970F",
      action: () => {
        setShowAccept(1);
      },
    },
    {
      title: "Reject",
      icon: "shield-exclamation",
      iconColor: "#D42701",
      textColor: "#D42701",
      action: () => {
        setShowReject(1);
      },
    },
  ];

  const template = useMemo(() => {
    if (type === "extend") {
      return [
        {
          title: "Verifikasi Perpanjangan Kontrak",
          isVerified: verificationStatus[1],
          onVerify: onVerifyActions[1],
          verificationDate: verificationDates[1]
            ? moment(verificationDates[1]).format("LL")
            : "-",
          isAccepted: isAcceptedStatus[1],
        },
      ];
    } else {
      return [
        {
          title: "Verifikasi Pertama",
          isVerified: verificationStatus[0],
          onVerify: onVerifyActions[0],
          verificationDate: verificationDates[0]
            ? moment(verificationDates[0]).format("LL")
            : "-",
          isAccepted: isAcceptedStatus[0],
        },
        {
          title: "Verifikasi Kedua",
          isVerified: verificationStatus[1],
          onVerify: onVerifyActions[1],
          verificationDate: verificationDates[1]
            ? moment(verificationDates[1]).format("LL")
            : "-",
          isAccepted: isAcceptedStatus[1],
        },
      ];
    }
  }, [
    verificationStatus,
    onVerifyActions,
    verificationDates,
    isAcceptedStatus,
    type,
  ]);

  const isVerifyAvailable = useCallback(
    (i) => {
      if (i === 0 && type !== "extend") {
        if (!verificationStatus[0]) {
          return true;
        }
      } else if (verificationStatus[0] && tag !== "Declined") {
        if (secondVerifyAvailable) {
          return true;
        }
        return false;
      }
      return false;
    },
    [verificationStatus, tag, secondVerifyAvailable, type]
  );

  const isDeclined = useCallback(
    (i) => {
      if (i === 0 && type !== "extend") {
        if (tag === "Declined") {
          return true;
        }
      } else if (verificationStatus[0] && tag === "Declined") {
        if (verificationStatus[1]) {
          return true;
        }
      }
      return false;
    },
    [verificationStatus, tag, type]
  );

  const isReVerifyAvailable = useCallback(
    (i) => {
      if (i === 0 && type !== "extend") {
        return isDeclined(i);
      } else {
        return isDeclined(i);
      }
    },
    [isDeclined, type]
  );

  const handleVerify = () => {
    setExpandPopup(!expandPopup);
  };

  if (type === "extend") {
    return (
      <div className={Styles.container}>
        <div className={Styles.blueBg} />
        <div className={Styles.wrapper}>
          {template?.map((each, i) => {
            const verifyAvailabiity = isVerifyAvailable(i);
            const reverifyAvailability = isReVerifyAvailable(i);
            return (
              <div key={i} className={`${Styles.eachExtend}`}>
                <div
                  className={`${Styles.header} ${
                    each?.isVerified ? Styles.verified : ""
                  }`}
                >
                  <Icon icon="check-circle-outline" size={24} />
                  <div className={Styles.title}>
                    <span>{each?.title}</span>
                    <span>
                      {each?.isVerified ? each?.verificationDate : "-"}
                    </span>
                  </div>
                </div>
                {verifyAvailabiity && (
                  <div className={Styles.verificationButtonWrapper}>
                    <VerificationButton
                      onClick={handleVerify}
                      setShowAccept={setShowAccept}
                      stage={stage}
                    />
                    {expandPopup && (
                      <div className={Styles.popupWrapper}>
                        <MenuListPopup
                          title="Verifikasi"
                          options={confirmationOptions}
                          withIcon
                          onClose={() => setExpandPopup(false)}
                        />
                      </div>
                    )}
                  </div>
                )}

                {each?.isVerified && (
                  <VerifiedIndicator
                    tag={isDeclined(i) && tag ? "Declined" : ""}
                  />
                )}
                {reverifyAvailability && tag === "Declined" && (
                  <div className={Styles.verificationButtonWrapper}>
                    <Icon
                      onClick={handleVerify}
                      icon={"arrow-rounded"}
                      size={24}
                      className={Styles.IconButton}
                    />
                    {expandPopup && (
                      <div className={Styles.popupWrapper}>
                        <MenuListPopup
                          title="Verifikasi"
                          options={confirmationOptions}
                          withIcon
                          onClose={() => setExpandPopup(false)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className={Styles.container}>
        <div className={Styles.blueBg} />
        <div className={Styles.wrapper}>
          {template?.map((each, i) => {
            const verifyAvailabiity = isVerifyAvailable(i);
            const reverifyAvailability = isReVerifyAvailable(i);
            return (
              <div
                key={i}
                className={`${Styles.each} ${
                  each?.isVerified ? Styles.verified : ""
                }`}
              >
                <Icon icon="check-circle-outline" size={24} />
                <div className={Styles.title}>
                  <span>{each?.title}</span>
                  <span>{each?.isVerified ? each?.verificationDate : "-"}</span>
                </div>
                {verifyAvailabiity && (
                  <div className={Styles.verificationButtonWrapper}>
                    <VerificationButton
                      onClick={handleVerify}
                      setShowAccept={setShowAccept}
                      stage={stage}
                    />
                    {expandPopup && (
                      <div className={Styles.popupWrapper}>
                        <MenuListPopup
                          title="Verifikasi"
                          options={confirmationOptions}
                          withIcon
                          onClose={() => setExpandPopup(false)}
                        />
                      </div>
                    )}
                  </div>
                )}

                {each?.isVerified && (
                  <VerifiedIndicator
                    tag={isDeclined(i) && tag ? "Declined" : ""}
                  />
                )}
                {reverifyAvailability && tag === "Declined" && (
                  <div className={Styles.verificationButtonWrapper}>
                    <Icon
                      onClick={() => {
                        if (expandPopupOne) {
                          setExpandPopupOne("");
                        } else {
                          setExpandPopupOne(each?.title);
                        }
                      }}
                      icon={"arrow-rounded"}
                      size={24}
                      className={Styles.IconButton}
                    />
                    {expandPopupOne === each?.title && (
                      <div className={Styles.popupWrapper} ref={boxRef}>
                        <MenuListPopup
                          title="Verifikasi"
                          options={confirmationOptions}
                          withIcon
                          onClose={() => setExpandPopup(false)}
                        />
                      </div>
                    )}
                  </div>
                )}

                {i === 0 && <div className={Styles.divider} />}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const VerificationButton = ({ onClick, stage, setShowAccept }) => {
  if (stage === "DONE") {
    return (
      <div className={Styles.reInjectWrapper}>
        <div className={`${Styles.preVerifiedIndicator}`}>
          <span>{"Terverifikasi"}</span>
          <Icon icon={"information-solid"} size={20} />
        </div>
        <button onClick={setShowAccept} className={Styles.reInjectButton}>
          <Icon icon={"file-inject-outline"} size={20} />
        </button>
      </div>
    );
  }
  return (
    <button onClick={onClick} className={Styles.verificationButton}>
      <span>Verifikasi</span>
      <Icon icon={"arrow-right"} size={20} />
    </button>
  );
};

const VerifiedIndicator = ({ tag }) => {
  return (
    <div
      className={`${Styles.verifiedIndicator} ${
        tag === "Declined" ? Styles.declined : ""
      }`}
    >
      <span>{tag !== "Declined" ? "Terverifikasi" : "Not Verified"}</span>
      <Icon
        icon={tag !== "Declined" ? "shield-check" : "shield-exclamation"}
        size={20}
      />
    </div>
  );
};
