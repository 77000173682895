/* eslint-disable no-unused-vars */
import { CostEstimation } from '@Helpers/costEstimation';
import { useMemo, useState } from 'react';
import { DataTable, SummaryTable } from '../../Fifth';
import Styles from './style.module.scss';


export const IDR = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  minimumFractionDigits: 0,
});

const getSalaryInMonth = (periodStr, baseSalary) => {
  switch (periodStr) {
    case "Harian":
      return +baseSalary * 20;
    case "Mingguan":
      return +baseSalary * 4;
    default:
      return +baseSalary;
  }
};

const getPeriodText = (periodStr) => {
  switch (periodStr) {
    case "Harian":
      return "Harian x 20";
    case "Mingguan":
      return "Mingguan x 4";
    default:
      return "Bulanan";
  }
};


export default function FifthSection({
  delegationsForm,
  workSchemeForm,
  cities,
  manfee,
  TOP = "",
  fixedManfee,
  fixedBpjsCut,
  selectedValueBPU,
  noTaxes = false,
  pph
}) {

  const manfeeMode = useMemo(() => {
    return manfee?.includes('%') ? '%' : 'Rp'
  }, [manfee])

  const getLocationName = (locationCode) => {
    return cities?.find((c) => c.code === locationCode)?.name;
  };

  const [umkArr, setUmkArr] = useState(Array(delegationsForm?.length).fill(""));
  const [costList, setCostList] = useState(Array(delegationsForm?.length).fill(""));

  const [subTotals, setSubTotals] = useState([...Array(delegationsForm?.length)])


  const [getSubTotalPerArea, setSubTotal] = useState(0);

  const [manfeePercentage, setManfeePercentage] = useState(
    Number(manfee.split("%")[0]) || 0
  );

  const [manfeeRupiah, setManfeeRupiah] = useState(
    !manfee.includes("%") ? Number(manfee) : 0
  );

  const showThr = useMemo(() => {
    if (workSchemeForm?.others?.thr?.toLowerCase()?.includes("bulanan")) {
      return true;
    }
    return false;
  }, [workSchemeForm]);

  const showUak = useMemo(() => {
    if (workSchemeForm?.others?.uak?.toLowerCase()?.includes("bulanan")) {
      return true;
    }
    return false;
  }, [workSchemeForm]);

  const {
    // getTotalSalaryPerArea,
    // getTotalUmkPerArea,
    getBpjsNominal,
    getJPNominal,
    getKSNominal,
    getPersonalEquipmentsNominal,
    // getPersonalEquipmentsNominalPerArea,
    // AllowanecesWithoutUAKandTHR,
    getUAK,
    getTHR,
    getAllowancesNominal,
    getAllowancesBPUNominal,
    getAllowancesNominalWithBPU,
    getAllowancesNominalWithoutTHR,
    // getAllowancesNominalPerAreaWithoutTHRWithoutBPU,
    // getAllowancesNominalPerAreaBPUOnly,
    // getTotalBpjsNominalPerArea,
    getEachManfeeGetter,
    // getAllowancesNominalPerAreaTHRWithoutBPU,
    // getAllowancesNominalPerAreaUAKWithoutBPU,
    getManfeeGetter,
    getAreaWorkEquipmentsNominal,
    getTotalCostPerArea,
  } = CostEstimation();


  const bpjsPercentage = useMemo(() => {
    let percentage = 0;

    if (
      workSchemeForm?.bpjs?.jkk &&
      workSchemeForm?.bpjs?.jkk?.includes("perusahaan")
    ) {
      const jkkPercentage = workSchemeForm?.bpjs?.jkk
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJkkPercentage = jkkPercentage.replace(",", ".");
      percentage += Number(fixedJkkPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jkm &&
      workSchemeForm?.bpjs?.jkm?.includes("perusahaan")
    ) {
      const jkmPercentage = workSchemeForm?.bpjs?.jkm
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJkmPercentage = jkmPercentage.replace(",", ".");
      percentage += Number(fixedJkmPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jht &&
      workSchemeForm?.bpjs?.jht?.includes("perusahaan")
    ) {
      const jhtPercentage = workSchemeForm?.bpjs?.jht
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJhtPercentage = jhtPercentage.replace(",", ".");
      percentage += Number(fixedJhtPercentage);
    }

    return percentage;
  }, [workSchemeForm]);

  const jpPercentage = useMemo(() => {
    let percentage = 0;
    if (
      workSchemeForm?.bpjs?.jp &&
      workSchemeForm?.bpjs?.jp?.includes("perusahaan")
    ) {
      const jPercentage = workSchemeForm?.bpjs?.jp
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJpPercentage = jPercentage.replace(",", ".");
      percentage += Number(fixedJpPercentage);
    }
    return percentage;
  }, [workSchemeForm?.bpjs?.jp]);

  const ksPercentage = useMemo(() => {
    let percentage = 0;
    if (
      workSchemeForm?.bpjs?.health &&
      workSchemeForm?.bpjs?.health?.includes("perusahaan")
    ) {
      const healthPercentage = workSchemeForm?.bpjs?.health
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedHealthPercentage = healthPercentage.replace(",", ".");
      percentage += Number(fixedHealthPercentage);
    }
    return percentage;
  }, [workSchemeForm?.bpjs?.health]);

  const tempManfeeNominal = useMemo(() => {
    return manfeeMode === "Rp"
      ? manfeeRupiah
      : delegationsForm
        ?.map((f) =>
          Math.ceil(
            (manfeePercentage *
              getManfeeGetter(
                f,
                f?.umk,
                fixedManfee,
                selectedValueBPU,
                showUak,
                showThr,
                bpjsPercentage,
                jpPercentage,
                ksPercentage,
                fixedBpjsCut
              )) /
            100
          )
        )
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)
  }, [bpjsPercentage, delegationsForm, fixedBpjsCut, fixedManfee, getManfeeGetter, jpPercentage, ksPercentage, manfeeMode, manfeePercentage, manfeeRupiah, selectedValueBPU, showThr, showUak])

  const tempSubTotal = useMemo(() => {
    return subTotals?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0)
  }, [subTotals])

  const ppnAmount = useMemo(() => {
    return Math.ceil(11 * (+tempSubTotal + +tempManfeeNominal) / 100)
  }, [tempManfeeNominal, tempSubTotal])
  const pphAmount = useMemo(() => {
    return Math.ceil(2 * ((pph?.includes('total') ? +tempSubTotal : 0) + +tempManfeeNominal) / 100)
  }, [pph, tempManfeeNominal, tempSubTotal])

  const generalCost = useMemo(() => {
    let cost = 0;
    for (let i = 0; i < delegationsForm.length; i++) {
      const perArea = getTotalCostPerArea(
        delegationsForm[i],
        delegationsForm[i]?.umk,
        false,
        subTotals[i],
        manfee,
        manfeeMode,
        manfeePercentage,
        fixedManfee,
        selectedValueBPU,
        showUak,
        showThr,
        bpjsPercentage,
        jpPercentage,
        ksPercentage,
        fixedBpjsCut
      );
      cost += Math.ceil(Number(perArea));
    }

    return +cost
  }, [delegationsForm, getTotalCostPerArea, subTotals, manfee, manfeeMode, manfeePercentage, fixedManfee, selectedValueBPU, showUak, showThr, bpjsPercentage, jpPercentage, ksPercentage, fixedBpjsCut]);


  return (
    <div className={Styles.container}>
      {delegationsForm?.map((each, i) => {
        const locationName = getLocationName(each?.location);
        const areaWorkEquipmentsNominal = getAreaWorkEquipmentsNominal(each);
        const manfeePerArea =
          manfeeMode === "Rp"
            ? manfee
            : Math.ceil(
              (manfeePercentage *
                getManfeeGetter(
                  each,
                  each?.umk,
                  fixedManfee,
                  selectedValueBPU,
                  showUak,
                  showThr,
                  bpjsPercentage,
                  jpPercentage,
                  ksPercentage,
                  fixedBpjsCut
                )) /
              100
            );

        const endTotalPerArea = getTotalCostPerArea(
          each,
          umkArr[i],
          false,
          getSubTotalPerArea,
          manfee,
          manfeeMode,
          manfeePercentage,
          fixedManfee,
          selectedValueBPU,
          showUak,
          showThr,
          bpjsPercentage,
          jpPercentage,
          ksPercentage,
          fixedBpjsCut
        );
        return (
          <DataTable
            key={i}
            index={i}
            locationName={locationName}
            each={each}
            form={delegationsForm}
            getPersonalEquipmentsNominal={getPersonalEquipmentsNominal}
            getAllowancesNominal={getAllowancesNominal}
            getCleanAllowance={getAllowancesNominalWithoutTHR}
            getBpjsNominal={getBpjsNominal}
            getJPNominal={getJPNominal}
            getKSNominal={getKSNominal}
            IDR={IDR}
            areaWorkEquipmentsNominal={areaWorkEquipmentsNominal}
            manfee={manfee}
            // handleClickSee={handleClickSee}
            // manfeeMode={manfeeMode}
            manfeePerArea={manfeePerArea}
            // manfeePercentage={manfeePercentage}
            endTotalPerArea={endTotalPerArea}
            getSubTotalPerArea={getSubTotalPerArea}
            umkArr={umkArr}
            setUmkArr={setUmkArr}
            getTotalCostPerArea={getTotalCostPerArea}
            costList={costList}
            setCostList={setCostList}
            generalCost={generalCost}
            getEachManfeeGetter={getEachManfeeGetter}
            selectedValueBPU={selectedValueBPU}
            getAllowancesBPUNominal={getAllowancesBPUNominal}
            getAllowancesNominalWithBPU={getAllowancesNominalWithBPU}
            showThr={showThr}
            showUak={showUak}
            getUAK={getUAK}
            getTHR={getTHR}
            setSubTotal={setSubTotal}
            fixedBpjsCut={fixedBpjsCut}
            bpjsPercentage={bpjsPercentage}
            jpPercentage={jpPercentage}
            ksPercentage={ksPercentage}
            manfeeMode={manfeeMode}
            manfeePercentage={manfeePercentage}
            fixedManfee={fixedManfee}
            setCurrentSubTotal={(sub) => {
              // const newSubs = [...subTotals]
              subTotals[i] = sub
              // setSubTotals(newSubs)
            }}
          />
        );
      })}

      <SummaryTable
        noTaxes={noTaxes}
        pph={pph}
        data={delegationsForm?.map((f, fI) => {
          return {
            cityName: getLocationName(f?.location),
            // subTotal: IDR.format(getSubTotalPerArea),
            subTotal: IDR.format(subTotals[fI] || 0),
            areaWorkEquipments: IDR.format(getAreaWorkEquipmentsNominal(f)),
            total: IDR.format(
              getTotalCostPerArea(
                f,
                f?.umk,
                true,
                subTotals[fI] || 0,
                manfee,
                manfeeMode,
                manfeePercentage,
                fixedManfee,
                selectedValueBPU,
                showUak,
                showThr,
                bpjsPercentage,
                jpPercentage,
                ksPercentage,
                fixedBpjsCut
              )
            ),
          };
        })}
        // subTotal={getSubTotalPerArea}
        subTotal={subTotals
          ?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0)
        }
        manfeeMode={manfeeMode}
        manfeeNominal={
          manfeeMode === "Rp"
            ? manfeeRupiah
            : delegationsForm
              ?.map((f) =>
                Math.ceil(
                  (manfeePercentage * getManfeeGetter(f,
                    f?.umk,
                    fixedManfee,
                    selectedValueBPU,
                    showUak,
                    showThr,
                    bpjsPercentage,
                    jpPercentage,
                    ksPercentage,
                    fixedBpjsCut
                  )) / 100
                )
              )
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
              }, 0)
        }
      />
    </div>
  )
}