/* eslint-disable no-unused-vars */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Stepper from "@Molecule/Stepper";
import Images from "@Theme/Images";
import FirstStep from "./First";
import SecondStep from "./Second";
import ThirdStep from "./Third";
import FourthStep from "./Fourth";
import FifthStep from "./Fifth";
import SixthStep from "./Sixth";
import SendingModal from "@Molecule/_modal/SendingModal";
import moment from "moment";
import {
  getCandidateOfferingData,
  sendCandidateForm,
  sendCandidateUpdateForm,
  sendOTP,
} from "@Services/officer/recruitment";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import { fileBaseUrl } from "@Config/api";
import ReactSignatureCanvas from "react-signature-canvas";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { imageToPdf } from "@Helpers/convertImageToPdf";
import ModalWrapper from "@Atom/ModalWrapper";
import OTPConfirmationModal from "@Molecule/_modal/OTPConfirmationModal";
import useQuery from "@Hooks/useQuery";
import { makeRandomString } from "@Helpers/makeRandomString";
import loading from "@Helpers/debounceLoading";
import LoadingModal from "@Molecule/_modal/LoadingModal";
import { validateEmail } from "@Helpers/validateEmail";
const PDFJS = require("pdfjs-dist/webpack");

export default function OnProgress({
  currentStep,
  setCurrentStep,
  syncData,
  offeringData,
  setTriggerDefault,
  type,
  isPhoneVerified,
  setIsPhoneVerified,
}) {
  const { hash } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [trigger, setTrigger] = useState(null);

  const [isValidated, setIsValidated] = useState(false);
  const [loadingPersonId, setLoadingPersonId] = useState(false);
  const [loadingPhoneVerify, setLoadingPhoneVerify] = useState(false);
  const [triggerRenew, setTriggerRenew] = useState("");
  const [errorField, setErrorField] = useState(false);
  const [errorShow, setErrorShow] = useState(false);

  const handleErrorShow = () => {
    setErrorShow(true);
    setTimeout(() => {
      setErrorShow(false);
    }, 5000);
  };

  const [lifetimeIdCard, setLifetimeIdCard] = useState(
    localStorage.getItem(`lifetimeIdCard_${hash}`) === "true" ? true : false
  );

  const [noTaxNumber, setNoTaxNumber] = useState(
    localStorage.getItem(`noTaxNumber_${hash}`) === "true" ? true : false
  );

  const [isDomicileTheSame, setIsDomicileTheSame] = useState(
    localStorage.getItem(`isDomicileTheSame_${hash}`) === "true" ? true : false
  );

  const defaultPersonalData = JSON.parse(
    localStorage.getItem(`c_personalData_${hash}`)
  );

  const PersonIDPeriod = useMemo(() => {
    if (
      offeringData?.CandidateComprehensive?.personIdPeriod === "SEUMUR HIDUP"
    ) {
      return setLifetimeIdCard(true);
    } else {
      return offeringData?.CandidateComprehensive?.personIdPeriod;
    }
  }, [offeringData?.CandidateComprehensive?.personIdPeriod]);

  const TaxNumber = useMemo(() => {
    if (offeringData?.CandidateComprehensive?.taxId === "TIDAK MEMILIKI NPWP") {
      return setNoTaxNumber(true);
    } else {
      return offeringData?.CandidateComprehensive?.taxId;
    }
  }, [offeringData?.CandidateComprehensive?.taxId]);

  const RT = useMemo(() => {
    if (offeringData?.CandidateComprehensive?.neighborhood) {
      return offeringData?.CandidateComprehensive?.neighborhood.split("/")[0];
    }
  }, [offeringData?.CandidateComprehensive?.neighborhood]);

  const RW = useMemo(() => {
    if (offeringData?.CandidateComprehensive?.neighborhood) {
      return offeringData?.CandidateComprehensive?.neighborhood.split("/")[1];
    }
  }, [offeringData?.CandidateComprehensive?.neighborhood]);

  const RTDomicilie = useMemo(() => {
    if (offeringData?.CandidateComprehensive?.domicileNeighbourhood) {
      return offeringData?.CandidateComprehensive?.domicileNeighbourhood.split(
        "/"
      )[0];
    }
  }, [offeringData?.CandidateComprehensive?.domicileNeighbourhood]);

  const RWDomicilie = useMemo(() => {
    if (offeringData?.CandidateComprehensive?.domicileNeighbourhood) {
      return offeringData?.CandidateComprehensive?.domicileNeighbourhood.split(
        "/"
      )[1];
    }
  }, [offeringData?.CandidateComprehensive?.domicileNeighbourhood]);

  const [personalData, setPersonalData] = useState({
    name: defaultPersonalData?.name || offeringData?.name?.toUpperCase(),
    birthPlace:
      defaultPersonalData?.birthPlace ||
      offeringData?.CandidateComprehensive?.placeOfBirth,
    birthDate:
      defaultPersonalData?.birthDate ||
      offeringData?.dateOfBirth ||
      offeringData?.CandidateComprehensive?.dateOfBirth,
    gender:
      defaultPersonalData?.gender ||
      offeringData?.gender ||
      offeringData?.CandidateComprehensive?.gender,
    religion:
      defaultPersonalData?.religion ||
      offeringData?.CandidateComprehensive?.religion,
    bloodType:
      defaultPersonalData?.bloodType ||
      offeringData?.CandidateComprehensive?.bloodType,
    height:
      defaultPersonalData?.height ||
      offeringData?.CandidateComprehensive?.bodyHeight,
    weight:
      defaultPersonalData?.weight ||
      offeringData?.CandidateComprehensive?.bodyWeight,
    maritalStatus:
      defaultPersonalData?.maritalStatus ||
      offeringData?.CandidateComprehensive?.maritalStatus,
    kk:
      defaultPersonalData?.kk || offeringData?.CandidateComprehensive?.familyId,
    nik:
      defaultPersonalData?.nik ||
      offeringData?.personId ||
      offeringData?.CandidateComprehensive?.personId,
    idCardPeriod: defaultPersonalData?.idCardPeriod || PersonIDPeriod,
    taxNumber: defaultPersonalData?.taxNumber || TaxNumber,
    email:
      defaultPersonalData?.email || offeringData?.email?.toLowerCase()?.trim(),
    phoneNumber:
      defaultPersonalData?.phoneNumber || offeringData?.phoneNumber?.slice(2),
    biologicalMotherName:
      defaultPersonalData?.biologicalMotherName ||
      offeringData?.CandidateComprehensive?.motherName,
  });

  // console.log(personalData.idCardPeriod, "test");

  const defaultAddress = JSON.parse(localStorage.getItem(`c_address_${hash}`));

  const [address, setAddress] = useState({
    idCard: {
      address:
        defaultAddress?.idCard?.address || offeringData?.address?.toUpperCase(),
      rt: defaultAddress?.idCard?.rt || RT,
      rw: defaultAddress?.idCard?.rw || RW,
      ward:
        defaultAddress?.idCard?.ward ||
        offeringData?.CandidateComprehensive?.subDistrict,
      subdistrict:
        defaultAddress?.idCard?.subdistrict ||
        offeringData?.CandidateComprehensive?.district,
      regency:
        defaultAddress?.idCard?.regency ||
        offeringData?.CandidateComprehensive?.city,
      province:
        defaultAddress?.idCard?.province ||
        offeringData?.CandidateComprehensive?.province,
      postal:
        defaultAddress?.idCard?.postal ||
        offeringData?.CandidateComprehensive?.postal,
    },
    domicile: {
      address:
        defaultAddress?.domicile?.address ||
        offeringData?.CandidateComprehensive?.domicileAddress,
      rt: defaultAddress?.domicile?.rt || RTDomicilie,
      rw: defaultAddress?.domicile?.rw || RWDomicilie,
      ward:
        defaultAddress?.domicile?.ward ||
        offeringData?.CandidateComprehensive?.domicileSubDistrict,
      subdistrict:
        defaultAddress?.domicile?.subdistrict ||
        offeringData?.CandidateComprehensive?.domicileDistrict,
      regency:
        defaultAddress?.domicile?.regency ||
        offeringData?.CandidateComprehensive?.domicileCity,
      province:
        defaultAddress?.domicile?.province ||
        offeringData?.CandidateComprehensive?.domicileProvince,
      postal:
        defaultAddress?.domicile?.postal ||
        offeringData?.CandidateComprehensive?.domicilePostal,
    },
  });

  const defaultLastEducation = JSON.parse(
    localStorage.getItem(`c_lastEducation_${hash}`)
  );

  const [lastEducation, setLastEducation] = useState({
    education:
      defaultLastEducation?.education ||
      offeringData?.lastEducation ||
      offeringData?.CandidateComprehensive?.lastEducationType,
    institution:
      defaultLastEducation?.institution ||
      offeringData?.CandidateComprehensive?.lastEducationName,
    faculty:
      defaultLastEducation?.faculty ||
      offeringData?.CandidateComprehensive?.lastEducationFaculty,
    gpa:
      defaultLastEducation?.gpa ||
      offeringData?.CandidateComprehensive?.lastEducationGrade,
  });

  const defaultContactInfo = JSON.parse(
    localStorage.getItem(`c_contactInfo_${hash}`)
  );

  const [contactInfo, setContactInfo] = useState({
    emergencyContact: {
      name:
        defaultContactInfo?.emergencyContact?.name ||
        offeringData?.CandidateFamilyMembers[0]?.name,
      relationship:
        defaultContactInfo?.emergencyContact?.relationship ||
        offeringData?.CandidateFamilyMembers[0]?.relation,
      phoneNumber:
        defaultContactInfo?.emergencyContact?.phoneNumber ||
        offeringData?.CandidateFamilyMembers[0]?.phoneNumber.slice(2),
      address:
        defaultContactInfo?.emergencyContact?.address ||
        offeringData?.CandidateFamilyMembers[0]?.address,
    },
    sameHouseFamilyContact: {
      name:
        defaultContactInfo?.sameHouseFamilyContact?.name ||
        offeringData?.CandidateFamilyMembers[1]?.name,
      relationship:
        defaultContactInfo?.sameHouseFamilyContact?.relationship ||
        offeringData?.CandidateFamilyMembers[1]?.relation,
      phoneNumber:
        defaultContactInfo?.sameHouseFamilyContact?.phoneNumber ||
        offeringData?.CandidateFamilyMembers[1]?.phoneNumber.slice(2),
      address:
        defaultContactInfo?.sameHouseFamilyContact?.address ||
        offeringData?.CandidateFamilyMembers[1]?.address,
    },
    differentHouseFamilyContact: {
      name:
        defaultContactInfo?.differentHouseFamilyContact?.name ||
        offeringData?.CandidateFamilyMembers[2]?.name,
      relationship:
        defaultContactInfo?.differentHouseFamilyContact?.relationship ||
        offeringData?.CandidateFamilyMembers[2]?.relation,
      phoneNumber:
        defaultContactInfo?.differentHouseFamilyContact?.phoneNumber ||
        offeringData?.CandidateFamilyMembers[2]?.phoneNumber.slice(2),
      address:
        defaultContactInfo?.differentHouseFamilyContact?.address ||
        offeringData?.CandidateFamilyMembers[2]?.address,
    },
  });

  const defaultOtherData = JSON.parse(
    localStorage.getItem(`c_otherData_${hash}`)
  );

  const [otherData, setOtherData] = useState({
    bankName:
      defaultOtherData?.bankName ||
      offeringData?.CandidateComprehensive?.BankName,
    bankAccountNumber:
      defaultOtherData?.bankAccountNumber ||
      offeringData?.CandidateComprehensive?.BankNumber,
    roCode: offeringData?.recruiterID,
    nearestHealthFacility:
      defaultOtherData?.nearestHealthFacility ||
      offeringData?.CandidateComprehensive?.nearestClinic,
    fbUsername:
      defaultOtherData?.fbUsername ||
      offeringData?.CandidateComprehensive?.facebook,
    igUsername:
      defaultOtherData?.igUsername ||
      offeringData?.CandidateComprehensive?.instagram,
    twitterUsername:
      defaultOtherData?.twitterUsername ||
      offeringData?.CandidateComprehensive?.twitter,
    linkedinUsername:
      defaultOtherData?.linkedinUsername ||
      offeringData?.CandidateComprehensive?.linkedin,
  });

  const defaultFamilyMembers = JSON.parse(
    localStorage.getItem(`c_familyMembers_${hash}`)
  );
  const [familyMembers, setFamilyMembers] = useState(
    defaultFamilyMembers || []
  );

  useEffect(() => {
    switch (personalData?.maritalStatus?.toLowerCase()) {
      case "tidak kawin":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
        ]);
        break;
      case "kawin (0 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[4]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[4]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[4]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[4]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[4]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[4]?.gender,
          },
        ]);
        break;
      case "kawin (1 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[4]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[4]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[4]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[4]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[4]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[4]?.gender,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[5]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[5]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[5]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[5]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[5]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[5]?.gender,
          },
        ]);
        break;
      case "kawin (2 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[4]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[4]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[4]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[4]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[4]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[4]?.gender,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[5]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[5]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[5]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[5]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[5]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[5]?.gender,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[6]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[6]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[6]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[6]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[6]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[6]?.gender,
          },
        ]);
        break;
      case "kawin (>= 3 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[4]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[4]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[4]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[4]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[4]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[4]?.gender,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[5]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[5]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[5]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[5]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[5]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[5]?.gender,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[6]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[6]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[6]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[6]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[6]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[6]?.gender,
          },
          {
            id: Math.random(),
            relationship:
              null || offeringData?.CandidateFamilyMembers[7]?.relation,
            name: null || offeringData?.CandidateFamilyMembers[7]?.name,
            birthPlace:
              null || offeringData?.CandidateFamilyMembers[7]?.placeOfBirth,
            birthDate:
              null || offeringData?.CandidateFamilyMembers[7]?.dateOfBirth,
            nik: null || offeringData?.CandidateFamilyMembers[7]?.nik,
            gender: null || offeringData?.CandidateFamilyMembers[7]?.gender,
          },
        ]);
        break;
      default:
        return;
    }
  }, [
    offeringData?.CandidateFamilyMembers,
    personalData?.birthDate,
    personalData?.birthPlace,
    personalData?.gender,
    personalData?.maritalStatus,
    personalData?.name,
    personalData?.nik,
  ]);

  const defaultEducationHistory = JSON.parse(
    localStorage.getItem(`c_educationHistory_${hash}`)
  );
  const [educationHistory, setEducationHistory] = useState(
    defaultEducationHistory || offeringData?.CandidateEducations || []
  );

  const defaultJobHistory = JSON.parse(
    localStorage.getItem(`c_jobHistory_${hash}`)
  );
  const [jobHistory, setJobHistory] = useState(
    defaultJobHistory || offeringData?.CandidateWorkExperiences || []
  );

  // console.log(jobHistory);

  const defaultPerformanceHistory = JSON.parse(
    localStorage.getItem(`c_performanceHistory_${hash}`)
  );
  const [performanceHistory, setPerformanceHistory] = useState(
    defaultPerformanceHistory || offeringData?.CandidateAchievements || []
  );

  const renewDefault = useCallback(() => {
    sessionStorage.setItem("candidateStep", currentStep);
    localStorage.setItem(
      `c_personalData_${hash}`,
      JSON.stringify(personalData)
    );
    localStorage.setItem(`c_address_${hash}`, JSON.stringify(address));
    localStorage.setItem(
      `c_lastEducation_${hash}`,
      JSON.stringify(lastEducation)
    );
    localStorage.setItem(`c_contactInfo_${hash}`, JSON.stringify(contactInfo));
    localStorage.setItem(`c_otherData_${hash}`, JSON.stringify(otherData));
    localStorage.setItem(
      `c_familyMembers_${hash}`,
      JSON.stringify(familyMembers)
    );
    localStorage.setItem(
      `c_educationHistory_${hash}`,
      JSON.stringify(educationHistory)
    );
    localStorage.setItem(`c_jobHistory_${hash}`, JSON.stringify(jobHistory));
    localStorage.setItem(
      `c_performanceHistory_${hash}`,
      JSON.stringify(performanceHistory)
    );
    localStorage.setItem(`isDomicileTheSame_${hash}`, isDomicileTheSame);
    localStorage.setItem(`lifetimeIdCard_${hash}`, lifetimeIdCard);
    localStorage.setItem(`noTaxNumber_${hash}`, noTaxNumber);
  }, [
    currentStep,
    hash,
    personalData,
    address,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    isDomicileTheSame,
    lifetimeIdCard,
    noTaxNumber,
  ]);

  useEffect(() => {
    renewDefault();
  }, [renewDefault, triggerRenew]);

  const docJamsostek = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "jamsostek"
  );

  const docURLJamsostek = docJamsostek
    ? fileBaseUrl + docJamsostek?.url
    : undefined;

  const docReferensi = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat referensi kerja"
  );

  const docURLReferensi = docReferensi
    ? fileBaseUrl + docReferensi?.url
    : undefined;

  const docNikah = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat nikah"
  );

  const docURLNikah = docNikah ? fileBaseUrl + docNikah?.url : undefined;

  const docAkta = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "akta lahir"
  );

  const docURLAkta = docAkta ? fileBaseUrl + docAkta?.url : undefined;

  const docRestuFamily = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat persetujuan keluarga"
  );

  const docURLRestuFamily = docRestuFamily
    ? fileBaseUrl + docRestuFamily?.url
    : undefined;

  const docSKCK = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "skck"
  );

  const docURLSKCK = docSKCK ? fileBaseUrl + docSKCK?.url : undefined;

  const docSuratSehat = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat keterangan berbadan sehat"
  );

  const docURLSuratSehat = docSuratSehat
    ? fileBaseUrl + docSuratSehat?.url
    : undefined;

  const [documents, setDocuments] = useState({
    mandatory: [],
    nonMandatory: [],
    additional: [],
  });
  const [images, setImages] = useState();
  const doc = offeringData?.CandidateDocuments?.find((d) => d?.type === "CV");
  const docURL = doc ? fileBaseUrl + doc?.url : undefined;
  useEffect(() => {
    if (docURL) {
      const loadDefaultImages = async () => {
        const imagesArr = [];
        if (docURL === undefined) {
          imagesArr.push(null);
        } else {
          const uri = docURL;
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            imagesArr.push(canvas.toDataURL());
          }
          canvas.remove();
        }

        setImages(imagesArr[0]);
      };
      loadDefaultImages();
    } else {
      setImages(null);
    }
  }, [docURL]);

  const docSelfPhoto = offeringData?.CandidateDocuments?.find(
    (d) => d?.type === "SELF PICTURE" || d?.type === "FOTO SELFIE"
  );

  const docURLSelfPhoto = docSelfPhoto
    ? fileBaseUrl + docSelfPhoto?.url
    : undefined;

  // console.log(docURLSelfPhoto);

  const docPersonID = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "ktp"
  );

  const docURLPersonID = docPersonID
    ? fileBaseUrl + docPersonID?.url
    : undefined;

  const docLamaran = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat lamaran"
  );

  const docURLLamaran = docLamaran ? fileBaseUrl + docLamaran?.url : undefined;

  const docCerificate = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "ijazah"
  );

  const docURLCerificate = docCerificate
    ? fileBaseUrl + docCerificate?.url
    : undefined;

  const docTranskipNilai = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "transkrip nilai"
  );

  const docURLTranskipNilai = docTranskipNilai
    ? fileBaseUrl + docTranskipNilai?.url
    : undefined;

  const docKK = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "kartu keluarga"
  );

  const docURLKK = docKK ? fileBaseUrl + docKK?.url : undefined;

  const docRekening = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "rekening"
  );

  const docURLRekening = docRekening
    ? fileBaseUrl + docRekening?.url
    : undefined;

  const docNPWP = offeringData?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "npwp"
  );

  const docURLNPWP = docNPWP ? fileBaseUrl + docNPWP?.url : undefined;

  const refetchDocuments = useCallback(() => {
    if (type?.contractType?.toLowerCase() === "pkwt") {
      if (noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? images : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "Surat Lamaran",
                fileType: "jpg",
                file: null,
                preview: docURLLamaran ? docURLLamaran : null,
                name: docLamaran ? docLamaran?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Ijazah",
                fileType: "jpg",
                file: null,
                preview: docURLCerificate ? docURLCerificate : null,
                name: docCerificate ? docCerificate?.url : null,
              },
              {
                title: "Transkrip Nilai",
                fileType: "jpg",
                file: null,
                preview: docURLTranskipNilai ? docURLTranskipNilai : null,
                name: docTranskipNilai ? docTranskipNilai?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
            ],
          };
        });
      } else if (!noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? images : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "Surat Lamaran",
                fileType: "jpg",
                file: null,
                preview: docURLLamaran ? docURLLamaran : null,
                name: docLamaran ? docLamaran?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Ijazah",
                fileType: "jpg",
                file: null,
                preview: docURLCerificate ? docURLCerificate : null,
                name: docCerificate ? docCerificate?.url : null,
              },
              {
                title: "Transkrip Nilai",
                fileType: "jpg",
                file: null,
                preview: docURLTranskipNilai ? docURLTranskipNilai : null,
                name: docTranskipNilai ? docTranskipNilai?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
              {
                title: "NPWP",
                fileType: "jpg",
                file: null,
                preview: docURLNPWP ? docURLNPWP : null,
                name: docNPWP ? docNPWP?.url : null,
              },
            ],
          };
        });
      }
    } else if (type?.contractType?.toLowerCase() === "pkm") {
      if (noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? images : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
            ],
          };
        });
      } else if (!noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? images : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
              {
                title: "NPWP",
                fileType: "jpg",
                file: null,
                preview: docURLNPWP ? docURLNPWP : null,
                name: docNPWP ? docNPWP?.url : null,
              },
            ],
          };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    noTaxNumber,
    doc,
    images,
    // docURLJamsostek,
    // docJamsostek,
    // docURL,
    // docURLReferensi,
    // docURLNikah,
    // docURLAkta,
    // docURLRestuFamily,
    // docURLSKCK,
    // docURLSuratSehat,
    // docSelfPhoto,
    // docURLLamaran,
    // docURLPersonID,
    // docURLCerificate,
    // docURLTranskipNilai,
    // docURLKK,
    // docURLRekening,
    // docURLNPWP,
  ]);

  useEffect(() => {
    refetchDocuments();
  }, [refetchDocuments]);

  const [isDoneCompleting, setIsDoneCompleting] = useState(true);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const [error, setError] = useState(null);
  // console.log(familyMembers);
  const isDoneChecker = useMemo(() => {
    return {
      first: () => {
        const newPersonalData = { ...personalData };
        const newOtherData = { ...otherData };

        delete newPersonalData.idCardPeriod;
        delete newPersonalData.taxNumber;

        delete newOtherData.fbUsername;
        delete newOtherData.igUsername;
        delete newOtherData.twitterUsername;
        delete newOtherData.linkedinUsername;

        if (
          !allFilledObjChecker(newPersonalData) ||
          !(personalData?.idCardPeriod || lifetimeIdCard) ||
          !(personalData?.taxNumber || noTaxNumber) ||
          !allFilledObjChecker(address?.idCard) ||
          !(isDomicileTheSame || allFilledObjChecker(address?.domicile)) ||
          !allFilledObjChecker(lastEducation) ||
          !allFilledObjChecker(contactInfo?.emergencyContact) ||
          !allFilledObjChecker(contactInfo?.sameHouseFamilyContact) ||
          !allFilledObjChecker(contactInfo?.differentHouseFamilyContact) ||
          !allFilledObjChecker(newOtherData) ||
          personalData?.nik?.length < 16 ||
          personalData?.kk?.length < 16 ||
          !isPhoneVerified ||
          !isValidated ||
          !validateEmail(personalData?.email)
        ) {
          return false;
        }
        return true;
      },
      second: () => {
        if (familyMembers?.length) {
          if (familyMembers?.length > 1) {
            for (let i = 0; i < familyMembers.length; i++) {
              if (!allFilledObjChecker(familyMembers[i])) {
                return false;
              }
            }
          } else {
            return true;
          }

          return true;
        } else {
          return false;
        }
      },
      third: () => {
        if (educationHistory?.length) {
          for (let i = 0; i < educationHistory.length; i++) {
            if (!allFilledObjChecker(educationHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      },
      fourth: () => {
        if (jobHistory?.length) {
          for (let i = 0; i < jobHistory.length; i++) {
            if (!jobHistory[i].description) {
              console.log("jejak1", jobHistory[i]);
              delete jobHistory[i].description;
            }
            if (!jobHistory[i].startMonth) {
              console.log("jejak2", jobHistory[i]);
              delete jobHistory[i].startMonth;
            }
            if (!jobHistory[i].endMonth) {
              console.log("jejak3", jobHistory[i]);
              delete jobHistory[i].endMonth;
            }
            if (!allFilledObjChecker(jobHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          if (currentStep >= 4) {
            return true;
          } else {
            return false;
          }
        }
      },
      fifth: () => {
        if (performanceHistory?.length) {
          for (let i = 0; i < performanceHistory.length; i++) {
            if (!allFilledObjChecker(performanceHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          if (currentStep >= 5) {
            return true;
          } else {
            return false;
          }
        }
      },
      sixth: () => {
        const filtered = documents?.mandatory?.filter(
          (obj) => obj?.title !== "CV"
        );
        for (let i = 0; i < filtered?.length; i++) {
          const doc = filtered[i];
          if (!doc?.preview) {
            return false;
          }
        }
        const filteredCV = documents?.mandatory?.filter(
          (obj) => obj?.title === "CV"
        );

        for (let i = 0; i < filteredCV?.length; i++) {
          const doc = filteredCV[i];
          if (!doc?.name) {
            return false;
          }
        }
        return true;
      },
    };
  }, [
    personalData,
    lifetimeIdCard,
    noTaxNumber,
    address,
    isDomicileTheSame,
    contactInfo,
    lastEducation,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    isPhoneVerified,
    isValidated,
    currentStep,
  ]);

  // console.log(performanceHistory);

  useEffect(() => {
    if (isDoneChecker?.sixth() === false) {
      setIsDoneCompleting(false);
    }
  }, [isDoneChecker, isDoneChecker.sixth]);

  const defaultSteps = JSON.parse(sessionStorage.getItem("candidateFormSteps"));

  // eslint-disable-next-line
  const stepsOptions = useMemo(() => {
    return [
      {
        id: 1,
        label: "Personal Data",
        isDone: isDoneChecker?.first(),
      },
      {
        id: 2,
        label: "Data Keluarga",
        isDone: isDoneChecker?.second(),
      },
      {
        id: 3,
        label: "Data Pendidikan",
        isDone: isDoneChecker?.third(),
      },
      {
        id: 4,
        label: "Data Pekerjaan",
        isDone: isDoneChecker?.fourth(),
      },
      {
        id: 5,
        label: "Data Prestasi",
        isDone: isDoneChecker?.fifth(),
      },
      {
        id: 6,
        label: "Unggah Dokumen",
        isDone: isDoneChecker?.sixth(),
      },
    ];
  }, [isDoneChecker]);

  const handleClickStep = (step) => {
    if (+step === 1 || +currentStep > +step) {
      setCurrentStep(step);
    } else if (+currentStep < +step) {
      if (stepsOptions[+currentStep - 1]?.isDone) {
        setCurrentStep(step);
      }
    }
  };

  const onPrevious = () => {
    setErrorField(false);
    currentStep > 1 && setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    let checkField = disableNext;
    if (checkField) {
      setErrorField(checkField);
      handleErrorShow();
    } else {
      if (currentStep < 6) {
        setErrorField(false);
        setCurrentStep(currentStep + 1);
      } else {
        setErrorField(false);
        setIsDoneCompleting(true);
      }
    }
  };

  useEffect(() => {
    if (currentStep !== 6) {
      setIsDoneCompleting(false);
    }
  }, [currentStep]);

  const clearStorage = () => {
    sessionStorage.removeItem("candidateStep");
    localStorage.removeItem(`c_personalData_${hash}`);
    localStorage.removeItem(`c_address_${hash}`);
    localStorage.removeItem(`c_lastEducation_${hash}`);
    localStorage.removeItem(`c_contactInfo_${hash}`);
    localStorage.removeItem(`c_otherData_${hash}`);
    localStorage.removeItem(`c_familyMembers_${hash}`);
    localStorage.removeItem(`c_educationHistory_${hash}`);
    localStorage.removeItem(`c_jobHistory_${hash}`);
    localStorage.removeItem(`c_performanceHistory_${hash}`);
    localStorage.removeItem(`isDomicileTheSame_${hash}`);
    localStorage.removeItem(`lifetimeIdCard_${hash}`);
    localStorage.removeItem(`noTaxNumber_${hash}`);
  };

  // console.log(offeringData, "kenapa ga mau");
  const handleSend = useCallback(async () => {
    try {
      setIsProcessing(true);
      const formData = new FormData();
      formData?.append("personalData[candidateID]", offeringData?.id);
      formData?.append("personalData[placeOfBirth]", personalData?.birthPlace);
      formData?.append(
        "personalData[dateOfBirth]",
        personalData?.birthDate
          ? moment(new Date(personalData?.birthDate))?.format("YYYY-MM-DD")
          : ""
      );
      formData?.append("personalData[candidateName]", personalData?.name);
      formData?.append("personalData[gender]", personalData?.gender);
      formData?.append("personalData[religion]", personalData?.religion);
      formData?.append("personalData[bloodType]", personalData?.bloodType);
      formData?.append("personalData[bodyHeight]", personalData?.height);
      formData?.append("personalData[bodyWeight]", personalData?.weight);
      formData?.append(
        "personalData[maritalStatus]",
        personalData?.maritalStatus
      );
      formData?.append("personalData[familyId]", personalData?.kk);
      formData?.append("personalData[personId]", personalData?.nik);
      formData?.append(
        "personalData[personIdPeriod]",
        !lifetimeIdCard
          ? personalData?.idCardPeriod
            ? moment(new Date(personalData?.idCardPeriod)).format("YYYY-MM-DD")
            : null
          : "SEUMUR HIDUP"
      );
      formData?.append(
        "personalData[taxId]",
        !noTaxNumber ? personalData?.taxNumber : "TIDAK MEMILIKI NPWP"
      );
      formData?.append("personalData[email]", personalData?.email);
      formData?.append(
        "personalData[phoneNumber]",
        "62" + personalData?.phoneNumber
      );
      formData?.append(
        "personalData[motherName]",
        personalData?.biologicalMotherName
      );
      formData?.append(
        "personalData[address][ktp][address]",
        address?.idCard?.address
      );
      formData?.append(
        "personalData[address][ktp][neighborhood]",
        `${address?.idCard?.rt}/${address?.idCard?.rw}`
      );
      formData?.append(
        "personalData[address][ktp][subDistrict]",
        address?.idCard?.ward
      );
      formData?.append(
        "personalData[address][ktp][district]",
        address?.idCard?.subdistrict
      );
      formData?.append(
        "personalData[address][ktp][city]",
        address?.idCard?.regency
      );
      formData?.append(
        "personalData[address][ktp][province]",
        address?.idCard?.province
      );
      formData?.append(
        "personalData[address][ktp][postal]",
        address?.idCard?.postal
      );

      if (isDomicileTheSame) {
        formData?.append(
          "personalData[address][domicile][domicileAddress]",
          address?.idCard?.address
        );
        formData?.append(
          "personalData[address][domicile][domicileNeighbourhood]",
          `${address?.idCard?.rt}/${address?.idCard?.rw}`
        );
        formData?.append(
          "personalData[address][domicile][domicileSubDistrict]",
          address?.idCard?.ward
        );
        formData?.append(
          "personalData[address][domicile][domicileDistrict]",
          address?.idCard?.subdistrict
        );
        formData?.append(
          "personalData[address][domicile][domicileCity]",
          address?.idCard?.regency
        );
        formData?.append(
          "personalData[address][domicile][domicileProvince]",
          address?.idCard?.province
        );
        formData?.append(
          "personalData[address][domicile][domicilePostal]",
          address?.idCard?.postal
        );
      } else {
        formData?.append(
          "personalData[address][domicile][domicileAddress]",
          address?.domicile?.address
        );
        formData?.append(
          "personalData[address][domicile][domicileNeighbourhood]",
          `${address?.domicile?.rt}/${address?.domicile?.rw}`
        );
        formData?.append(
          "personalData[address][domicile][domicileSubDistrict]",
          address?.domicile?.ward
        );
        formData?.append(
          "personalData[address][domicile][domicileDistrict]",
          address?.domicile?.subdistrict
        );
        formData?.append(
          "personalData[address][domicile][domicileCity]",
          address?.domicile?.regency
        );
        formData?.append(
          "personalData[address][domicile][domicileProvince]",
          address?.domicile?.province
        );
        formData?.append(
          "personalData[address][domicile][domicilePostal]",
          address?.domicile?.postal
        );
      }

      formData?.append(
        "personalData[lastEducation][type]",
        lastEducation?.education
      );
      formData?.append(
        "personalData[lastEducation][name]",
        lastEducation?.institution
      );
      formData?.append(
        "personalData[lastEducation][faculty]",
        lastEducation?.faculty
      );
      formData?.append(
        "personalData[lastEducation][grade]",
        lastEducation?.gpa
      );

      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactName]",
        contactInfo?.emergencyContact?.name
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactRelation]",
        contactInfo?.emergencyContact?.relationship
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactNumber]",
        "62" + contactInfo?.emergencyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactAddress]",
        contactInfo?.emergencyContact?.address
      );

      formData?.append(
        "personalData[contact][family1][family1Name]",
        contactInfo?.sameHouseFamilyContact?.name
      );
      formData?.append(
        "personalData[contact][family1][family1Relation]",
        contactInfo?.sameHouseFamilyContact?.relationship
      );
      formData?.append(
        "personalData[contact][family1][family1PhoneNumber]",
        "62" + contactInfo?.sameHouseFamilyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][family1][family1Address]",
        contactInfo?.sameHouseFamilyContact?.address
      );

      formData?.append(
        "personalData[contact][family2][family2Name]",
        contactInfo?.differentHouseFamilyContact?.name
      );
      formData?.append(
        "personalData[contact][family2][family2Relation]",
        contactInfo?.differentHouseFamilyContact?.relationship
      );
      formData?.append(
        "personalData[contact][family2][family2PhoneNumber]",
        "62" + contactInfo?.differentHouseFamilyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][family2][family2Address]",
        contactInfo?.differentHouseFamilyContact?.address
      );

      formData?.append(
        "personalData[others][bank][BankName]",
        otherData?.bankName
      );
      formData?.append(
        "personalData[others][bank][BankNumber]",
        otherData?.bankAccountNumber
      );
      formData?.append(
        "personalData[others][nearestClinic]",
        otherData?.nearestHealthFacility
      );
      if (otherData?.fbUsername) {
        formData?.append(
          "personalData[others][socialMedia][facebook]",
          otherData?.fbUsername
        );
      }
      if (otherData?.igUsername) {
        formData?.append(
          "personalData[others][socialMedia][instagram]",
          otherData?.igUsername
        );
      }
      if (otherData?.twitterUsername) {
        formData?.append(
          "personalData[others][socialMedia][twitter]",
          otherData?.twitterUsername
        );
      }
      if (otherData?.linkedinUsername) {
        formData?.append(
          "personalData[others][socialMedia][linkedin]",
          otherData?.linkedinUsername
        );
      }
      if (
        !otherData?.fbUsername &&
        !otherData?.igUsername &&
        !otherData?.twitterUsername &&
        !otherData?.linkedinUsername
      ) {
        formData?.append("personalData[others][socialMedia]", {});
      }

      for (let i = 0; i < familyMembers?.length; i++) {
        const member = familyMembers[i];
        formData?.append(`familyData[${i}][name]`, member?.name);
        formData?.append(
          `familyData[${i}][relation]`,
          member?.relationship || "-"
        );
        formData?.append(`familyData[${i}][placeOfBirth]`, member?.birthPlace);
        formData?.append(
          `familyData[${i}][dateOfBirth]`,
          member?.birthDate
            ? moment(new Date(member?.birthDate)).format("YYYY-MM-DD")
            : ""
        );
        formData?.append(`familyData[${i}][nik]`, member?.nik);
        formData?.append(`familyData[${i}][gender]`, member?.gender);
      }

      for (let i = 0; i < educationHistory?.length; i++) {
        const education = educationHistory[i];
        formData?.append(
          `educationData[${i}][startYear]`,
          education?.startYear
        );
        formData?.append(`educationData[${i}][endYear]`, education?.endYear);
        formData?.append(`educationData[${i}][type]`, education?.type);
        formData?.append(`educationData[${i}][name]`, education?.name);
        formData?.append(`educationData[${i}][mastery]`, education?.mastery);
        formData?.append(
          `educationData[${i}][sertificateNumber]`,
          education?.sertificateNumber
        );
        formData?.append(`educationData[${i}][city]`, education?.city);
      }

      for (let i = 0; i < jobHistory?.length; i++) {
        const job = jobHistory[i];
        formData?.append(`workExperienceData[${i}][startYear]`, job?.startYear);
        formData?.append(`workExperienceData[${i}][endYear]`, job?.endYear);
        formData?.append(
          `workExperienceData[${i}][companyName]`,
          job?.companyName
        );
        formData?.append(`workExperienceData[${i}][position]`, job?.position);
        formData?.append(`workExperienceData[${i}][remark]`, job?.remark);
      }

      if (!jobHistory?.length) {
        formData?.append(`workExperienceData`, []);
      }

      for (let i = 0; i < performanceHistory?.length; i++) {
        const performance = performanceHistory[i];
        formData?.append(`achievementData[${i}][year]`, performance?.year);
        formData?.append(
          `achievementData[${i}][achievement]`,
          performance?.achievement
        );
        formData?.append(`achievementData[${i}][remark]`, performance?.remark);
      }

      if (!performanceHistory?.length) {
        formData?.append(`achievementData`, []);
      }

      const files = documents?.mandatory?.filter((doc) => doc?.file);
      const files2 = documents?.nonMandatory?.filter((doc) => doc?.file);
      const files3 = documents?.additional?.filter((doc) => doc?.file);

      for (let i = 0; i < files?.length; i++) {
        const file = files[i];

        if (file?.title?.toLowerCase() !== "cv") {
          formData?.append("files", file?.file);
          formData?.append("types", file?.title?.toUpperCase());
        } else {
          const fileExtension = file?.file?.type
            ?.split("/")
            .pop()
            .toLowerCase();

          let validPdf = file?.file;
          if (
            fileExtension === "jpeg" ||
            fileExtension === "jpg" ||
            fileExtension === "png"
          ) {
            validPdf = await imageToPdf(file?.file);
          }
          formData?.append("files", validPdf);
          formData?.append("types", file?.title?.toUpperCase());
        }
      }
      for (let i = 0; i < files2?.length; i++) {
        const file = files2[i];
        formData?.append("files", file?.file);
        formData?.append("types", file?.title?.toUpperCase());
      }
      for (let i = 0; i < files3?.length; i++) {
        const file = files3[i];
        formData?.append("files", file?.file);
        formData?.append("types", file?.title?.toUpperCase());
      }

      if (offeringData?.status === "OFFER-INVITE") {
        await sendCandidateForm(formData);
      } else {
        await sendCandidateUpdateForm(formData);
      }

      setIsSent(true);
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
    }
  }, [
    offeringData,
    personalData,
    address,
    isDomicileTheSame,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    lifetimeIdCard,
    noTaxNumber,
  ]);

  const handleDone = () => {
    setIsProcessing(false);
    setCurrentStep(0);
    clearStorage();
    setTriggerDefault(Math.random());
  };

  console.log(documents);

  const options = useMemo(() => {
    return {
      banks: syncData?.banks,
      cities: syncData?.cities,
      provinces: syncData?.provinces,
      genders: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Gender")
        ?.map((el) => el?.value),
      religions: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Religion")
        ?.map((el) => el?.value),
      bloodTypes: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Blood Type")
        ?.map((el) => el?.value),
      maritalStatus: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Marital Status")
        ?.map((el) => el?.value),
      educations: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Education")
        ?.map((el) => el?.value),
      relationships: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Family Relationship")
        ?.map((el) => el?.value),
    };
  }, [syncData]);

  const disableInput = useMemo(() => {
    return personalData?.nik?.length !== 16 || !isValidated || !isPhoneVerified;
  }, [isValidated, personalData?.nik?.length, isPhoneVerified]);

  const disablePhoneVerification = useMemo(() => {
    return personalData.nik?.length !== 16 || !isValidated;
  }, [isValidated, personalData.nik?.length]);

  useEffect(() => {
    if (!isValidated && personalData.nik?.length === 16) {
      setIsValidated(true);
    }
    if (isValidated && personalData.nik.length < 16) {
      setIsValidated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalData.nik]);

  useEffect(() => {
    setTriggerRenew(makeRandomString(5));
  }, [personalData, familyMembers, noTaxNumber]);

  const onClickPhoneVerification = useCallback(async () => {
    try {
      setLoadingPhoneVerify(true);
      const { response } = await sendOTP("62" + personalData?.phoneNumber);
      navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });
      setLoadingPhoneVerify(false);
    } catch (error) {
      console.log("in error", error);
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      setError(errorMessage);
      setLoadingPhoneVerify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, personalData?.phoneNumber]);

  const handlePhoneVerification = () => {
    setLoadingPhoneVerify(false);
    setIsPhoneVerified(true);
    navigate(`${pathname}?h=${query.get("h")}&v=false`, {
      replace: true,
    });
  };

  const content = useMemo(() => {
    switch (+currentStep) {
      case 1:
        return (
          <FirstStep
            personalData={personalData}
            setPersonalData={setPersonalData}
            address={address}
            setAddress={setAddress}
            lastEducation={lastEducation}
            setLastEducation={setLastEducation}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            otherData={otherData}
            setOtherData={setOtherData}
            trigger={trigger}
            setTrigger={setTrigger}
            lifetimeIdCard={lifetimeIdCard}
            setLifetimeIdCard={setLifetimeIdCard}
            noTaxNumber={noTaxNumber}
            setNoTaxNumber={setNoTaxNumber}
            isDomicileTheSame={isDomicileTheSame}
            setIsDomicileTheSame={setIsDomicileTheSame}
            options={options}
            disableInput={disableInput}
            disablePhoneVerification={disablePhoneVerification}
            loadingPersonId={loadingPersonId}
            isPhoneVerified={isPhoneVerified}
            setIsPhoneVerified={setIsPhoneVerified}
            loadingPhoneVerify={loadingPhoneVerify}
            onClickPhoneVerification={onClickPhoneVerification}
            errorField={errorField}
          />
        );
      case 2:
        return (
          <SecondStep
            form={familyMembers}
            setForm={setFamilyMembers}
            options={options}
            errorField={errorField}
          />
        );
      case 3:
        return (
          <ThirdStep
            form={educationHistory}
            setForm={setEducationHistory}
            options={options}
            errorField={errorField}
          />
        );
      case 4:
        return (
          <FourthStep
            form={jobHistory}
            setForm={setJobHistory}
            errorField={errorField}
          />
        );
      case 5:
        return (
          <FifthStep
            form={performanceHistory}
            setForm={setPerformanceHistory}
            errorField={errorField}
          />
        );
      case 6:
        return (
          <SixthStep
            form={documents}
            setForm={setDocuments}
            isDoneCompleting={isDoneCompleting}
            setIsDoneCompleting={setIsDoneCompleting}
            handleSend={handleSend}
            errorField={errorField}
          />
        );
      default:
        return (
          <FirstStep
            personalData={personalData}
            setPersonalData={setPersonalData}
            address={address}
            setAddress={setAddress}
            lastEducation={lastEducation}
            setLastEducation={setLastEducation}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            otherData={otherData}
            setOtherData={setOtherData}
            trigger={trigger}
            setTrigger={setTrigger}
            lifetimeIdCard={lifetimeIdCard}
            setLifetimeIdCard={setLifetimeIdCard}
            noTaxNumber={noTaxNumber}
            setNoTaxNumber={setNoTaxNumber}
            isDomicileTheSame={isDomicileTheSame}
            setIsDomicileTheSame={setIsDomicileTheSame}
            options={options}
            disableInput={disableInput}
            disablePhoneVerification={disablePhoneVerification}
            loadingPersonId={loadingPersonId}
            isPhoneVerified={isPhoneVerified}
            setIsPhoneVerified={setIsPhoneVerified}
            loadingPhoneVerify={loadingPhoneVerify}
            onClickPhoneVerification={onClickPhoneVerification}
            errorField={errorField}
          />
        );
    }
  }, [
    currentStep,
    personalData,
    trigger,
    lifetimeIdCard,
    noTaxNumber,
    address,
    isDomicileTheSame,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    isDoneCompleting,
    options,

    handleSend,
    disableInput,
    disablePhoneVerification,
    isPhoneVerified,
    setIsPhoneVerified,
    loadingPersonId,
    loadingPhoneVerify,
    onClickPhoneVerification,
    errorField,
  ]);

  const isNextButtonAvailable = useMemo(() => {
    if (currentStep === 6 && isDoneCompleting) {
      return false;
    } else if (currentStep === 2) {
      if (familyMembers?.length) {
        return true;
      } else {
        return false;
      }
    } else if (currentStep === 3) {
      if (educationHistory?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [currentStep, familyMembers, educationHistory, isDoneCompleting]);

  const [stepperHeight, setStepperHeight] = useState();
  const stepperWrapperRef = useRef();

  const getHeight = () => {
    const newHeight = stepperWrapperRef?.current?.clientHeight;
    setStepperHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const disableNext = useMemo(() => {
    switch (currentStep) {
      case 1:
        return !isDoneChecker?.first();
      case 2:
        return !isDoneChecker?.second();
      case 3:
        return !isDoneChecker?.third();
      case 4:
        return !isDoneChecker?.fourth();
      case 5:
        return !isDoneChecker?.fifth();
      case 6:
        return !isDoneChecker?.sixth();
      default:
        return !isDoneChecker?.first();
    }
  }, [currentStep, isDoneChecker]);

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper} ref={stepperWrapperRef}>
        <div className={Styles.logoWrapper}>
          <div>
            <img src={Images.INITIAL_LOGO} alt="logo" />
          </div>
          <span>PT Permata Indo Sejahtera</span>
        </div>

        <div className={Styles.stepControllerWrapper}>
          <button onClick={onPrevious}>
            <Icon icon="arrow-left" size={20} />
          </button>
          <div>
            <Stepper
              type="filled"
              onClick={handleClickStep}
              options={stepsOptions}
              currentStep={currentStep}
            />
          </div>

          <button onClick={onNext}>
            <Icon icon="arrow-right" size={20} />
          </button>
        </div>
      </div>
      <div
        className={Styles.mainWrapper}
        style={{ height: `calc(100vh - ${stepperHeight}px)` }}
      >
        <div className={Styles.contentWrapper}>{content}</div>

        {errorShow && (
          <div className={Styles.errorWrapper}>
            <Icon icon={"information-solid"} color={"#ff3e13"} size={20} />
            <span className={Styles.errorText}>
              {"Terdapat kolom yang belum terisi"}
            </span>
          </div>
        )}

        {isNextButtonAvailable && (
          <div
            className={Styles.buttonWrapper}
            style={{
              paddingBottom:
                currentStep !== 1 && currentStep !== 6 ? "80px" : "",
            }}
          >
            <button
              // disabled={disableNext}
              onClick={onNext}
            >
              Selanjutnya
            </button>
          </div>
        )}
      </div>

      {isProcessing && (
        <SendingModal
          handleDone={handleDone}
          isDone={isSent}
          show={isProcessing}
          error={error}
          setShow={setIsProcessing}
          setError={setError}
        />
      )}

      {/* otp */}
      {query.get("v") === "true" ? (
        <ModalWrapper show={query.get("v") === "true"} forceCenter>
          <div
            style={{
              visibility: query.get("v") === "true" ? "visible" : "hidden",
            }}
          >
            <OTPConfirmationModal
              handleSubmit={handlePhoneVerification}
              data={personalData}
              errorApi={error}
              loading={loadingPhoneVerify}
            />
          </div>
        </ModalWrapper>
      ) : (
        loadingPhoneVerify && <LoadingModal />
      )}
    </div>
  );
}
