import Select from "@Atom/Select";
import { useEffect } from "react";
import Styles from "./pagination.module.scss";
import usePagination, { DOTS } from "@Hooks/usePagination";
import Icon from "@Atom/Icon";

const Pagination = ({
  total = 1,
  value = 5,
  setValue = () => {},
  pages = "",
  setPages = () => {},
  selectedPage = 1,
  setSelectedPage = () => {},
  pageList,
  setPageList,
  totalPage = 1,
  siblingCount = 1,
  isV2,
  bgColor,
  noPageList = false,
  noValueSetter = false,
  ...props
}) => {
  const paginationRange = usePagination({
    selectedPage,
    siblingCount,
    totalPage,
  });

  const onNext = () => {
    if (selectedPage < totalPage) {
      setSelectedPage(+selectedPage + 1);
    }
  };

  const onPrevious = () => {
    if (selectedPage > 1) {
      setSelectedPage(+selectedPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevDisabled = selectedPage === 1;
  const nextDisabled = selectedPage === lastPage;

  const options = [
    {
      label: "5",
      value: 5,
    },
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
  ];

  useEffect(() => {
    if (!noPageList && !selectedPage) {
      setPages(`1-${value}`);
      setSelectedPage(1);
    }
  }, [value, setPages, setSelectedPage, noPageList, selectedPage]);

  useEffect(() => {
    const list = [];
    if (!noPageList) {
      if (+total / +value >= 1) {
        for (let i = 1; i <= total / value; i++) {
          list.push({ number: i });
        }
      }
      if (+total / +value < 1) {
        list.push({ number: 1 });
      }
      if (+total / +value >= 1 && +total % +value > 0) {
        list.push({ number: Math.floor(total / value + 1) });
      }

      setPageList(list);
    }
  }, [value, total, setPageList, noPageList]);

  useEffect(() => {
    if (!noPageList) {
      if (+selectedPage > 1) {
        setPages(
          `${+(selectedPage - 1) * +value + 1}-${
            +(selectedPage - 1) * +value + +value <= total
              ? +(selectedPage - 1) * +value + +value
              : total
          }`
        );
      }
      if (+selectedPage <= 1) {
        setPages(`1-${value}`);
      }
    }
  }, [selectedPage, value, total, setPages, noPageList]);

  return (
    <div
      style={{ backgroundColor: bgColor || "auto" }}
      className={`${Styles.container} ${isV2 ? Styles.v2 : ""}`}
    >
      <div className={Styles.leftSide}>
        {!noPageList ? (
          isV2 ? (
            <div className={Styles.content}>
              View <span className={Styles.contentBold}>{pages}</span> from{" "}
              <span className={Styles.contentBold}>{total}</span> list
            </div>
          ) : (
            <div className={Styles.content}>Row per page</div>
          )
        ) : (
          <div className={Styles.content}>Table View</div>
        )}

        {!noValueSetter ? (
          <div className={Styles.selectWrapper}>
            <Select
              setSelectedValue={setValue}
              value={value}
              options={options}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={Styles.rightSide}>
        {isV2 ? null : (
          <div className={Styles.contentWrapper}>
            {" "}
            <span className={Styles.textContent}>Total data</span>
            <span className={Styles.contentBold}>{total}</span>
          </div>
        )}
        <div className={Styles.pageIndicator}>
          <div className={Styles.arrowWrapper}>
            <div
              className={`${Styles.arrow} ${
                prevDisabled ? Styles.disabled : ""
              }`}
              onClick={onPrevious}
            >
              <Icon icon="arrow-left" size={18} />
            </div>
          </div>
          {paginationRange.map((each) => {
            if (each === DOTS) {
              return (
                <div
                  key={each + Math.random()}
                  className={`${Styles.page} ${Styles.disabled}`}
                >
                  &#8230;
                </div>
              );
            }
            return (
              <li
                key={each + Math.random()}
                className={`${
                  +selectedPage === each ? Styles.selectedPage : ""
                } ${Styles.page}`}
                onClick={() => setSelectedPage(each)}
              >
                {each}
              </li>
            );
          })}
          <div className={Styles.arrowWrapper}>
            <div
              className={`${Styles.arrow} ${
                nextDisabled ? Styles.disabled : ""
              }`}
              onClick={onNext}
            >
              <Icon icon="arrow-right" size={18} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
