import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";

export default function NotifErrorDeployment({ handleClose, alertFound }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={"information-outline"} size={47} color={"#FFFFFF"} />
      </div>
      <span>
        {alertFound ===
        "Mohon untuk menambahkan data deployment terlebih dahulu"
          ? "Deployment belum dipilih"
          : alertFound?.includes("Harap pilih business manager untuk lokasi")
          ? "Business Manager belum dipilih"
          : alertFound === "Harap pilih recruitment officer untuk setiap lokasi"
          ? "RO Belum Dipilih"
          : alertFound || "-"}
      </span>
      <span>
        {alertFound ===
        "Mohon untuk menambahkan data deployment terlebih dahulu"
          ? "Silahkan lakukan penambahan data Deployment terlebih dahulu minimal 1 Kota."
          : alertFound?.includes("Harap pilih business manager untuk lokasi")
          ? alertFound
          : alertFound === "Harap pilih recruitment officer untuk setiap lokasi"
          ? "Silahkan lakukan penambahan minimal 1 data RO di kota manapun."
          : alertFound || "-"}
      </span>

      <span onClick={handleClose}>Tutup</span>
    </div>
  );
}
