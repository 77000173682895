// import PendingContractListLayout from '@Organism/BusinessSupport/Contracts/PendingContractList';
import Styles from "./style.module.scss";
import { getExtendCandidates } from "@Services/support/candidate";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import CompleteContractListLayout from "@Organism/BusinessSupport/Contracts/CompleteContractList";

export async function loader() {
  const packageExtendCandidateList = getExtendCandidates(
    10,
    1,
    "Joined",
    "",
    [],
    "",
    "",
    "",
    "",
    "Extend"
  );

  return defer({
    packageExtendCandidateList: packageExtendCandidateList,
  });
}

export default function CompleteContractList() {
  const { packageExtendCandidateList } = useLoaderData();
  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Selesai Perpanjangan</title>
      </Helmet>
      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageExtendCandidateList}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageExtendCandidateList) => (
              <CompleteContractListLayout
                initialData={packageExtendCandidateList?.response}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
