import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";

export default function FinishedModal(props) {
  const { setShow, clearStorage, submittingType, isServices } = props;

  const navigate = useNavigate();

  const query = useQuery();
  const name = query.get("n");
  const code = query.get("c");

  const handleDone = () => {
    setShow(false);
    clearStorage();
    if (isServices) {
      navigate(
        `/clients/details?n=${name}&c=${code}&tt=2&h=${String(
          Math.random()
        ).slice(2)}`
      );
    } else {
      navigate(
        `/clients/details?n=${name}&c=${code}&h=${String(Math.random()).slice(
          2
        )}`
      );
    }
  };

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <span className={Styles.circle}>
          <Icon icon="check" size={53} className={Styles.checkIcon} />
        </span>
        <h4>{submittingType === "send" ? "Sent!" : "Saved!"}</h4>
        <p>
          {submittingType === "send"
            ? "Your order has been successfully sent for processing"
            : "Your order has been successfully saved as draft"}
        </p>
        <button onClick={handleDone}>Done</button>
      </div>
    </div>
  );
}
