import Styles from "./uploadBox.module.scss";
import Icon from "@Atom/Icon";
import loadingAnimationData from "@Assets/Lotties/imageLoading.json";
import Lottie from "lottie-react";

const Loading = () => {
  return (
    <div className={Styles.loadingContainer}>
      <Lottie animationData={loadingAnimationData} height={10} width={10} />
    </div>
  );
};
const Uploading = () => {
  return (
    <div className={Styles.loadingContainer}>
      <Lottie animationData={loadingAnimationData} height={10} width={10} />
    </div>
  );
};

const UploadBox = ({
  onClick,
  type,
  isUploaded,
  fileImage,
  defaultPreview,
  defaultName,
  fileName,
  uploading,
  isPdf = true,
  isError = false,
  disabled = false,
  optional = false,
  ...props
}) => {
  return (
    <div
      className={`${Styles.container} ${isError ? Styles.error : ""} ${
        type === "light" && Styles.light
      } ${disabled && Styles.disabled}`}
    >
      <div className={Styles.content}>
        {isUploaded || defaultPreview || defaultName ? (
          <>
            {uploading ? (
              <>
                <Uploading />
                <div className={`${Styles.text} ${Styles.filenameText}`}>
                  File sedang dalam proses upload
                </div>
                <div
                  className={`${Styles.text} ${Styles.linkText} ${Styles.transparent}`}
                  onClick={onClick}
                >
                  Change File
                </div>
              </>
            ) : (
              <>
                {defaultPreview || fileImage ? (
                  <>
                    <img
                      src={fileImage || defaultPreview}
                      className={Styles.fileImage}
                      alt="file"
                    />
                  </>
                ) : (
                  <Loading />
                )}

                <div className={`${Styles.text} ${Styles.filenameText}`}>
                  {fileName || defaultName}
                </div>
                <div
                  className={`${Styles.text} ${Styles.linkText}`}
                  onClick={onClick}
                >
                  Change File
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {uploading ? (
              <>
                <Uploading />
                <div className={`${Styles.text} ${Styles.filenameText}`}>
                  File sedang dalam proses upload
                </div>
                <div
                  className={`${Styles.text} ${Styles.linkText} ${Styles.transparent}`}
                  onClick={onClick}
                >
                  Change File
                </div>
              </>
            ) : (
              <>
                <Icon className={Styles.uploadIcon} icon="upload" size="32px" />
                <div className={`${Styles.text} ${Styles.mainText}`}>
                  {isPdf
                    ? `Drag file ke sini untuk upload (PDF)`
                    : `Drag file ke sini untuk upload`}
                </div>
                {optional && (
                  <div className={`${Styles.text} ${Styles.middleText}`}>
                    File wajib JPG / JPEG / PNG
                  </div>
                )}
                <div className={`${Styles.text} ${Styles.middleText}`}>
                  atau pilih file dengan
                </div>
                <div
                  className={`${Styles.text} ${Styles.linkText} ${
                    disabled && Styles.disabled
                  }`}
                  onClick={onClick}
                >
                  Klik di sini
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadBox;
