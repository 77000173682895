import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { makeRandomString } from "@Helpers/makeRandomString";
import { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import { Tooltip } from "@mui/material";
import moment from "moment";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { sendCredential, sendSurvey } from "@Services/manager/bm";

export default function InformationContactSection({
  data,
  setData,
  setTrigger,
  setShowAdd,
  setShowEdit,
  setSelectedData,
  rbmView = false,
  setIsSuccesData,
  isSuccessData,
  setIsSuccess,
  isSuccess,
  setErrorData,
}) {
  // eslint-disable-next-line no-unused-vars
  const [isProcessing, setIsProcessing] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const startIndex = 0;
  const endIndex = 1;

  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && isActive) {
      setIsActive(null);
    }
  }, [boxOutsideClick, isActive]);

  const handleDelete = async (e, id, row) => {
    try {
      e.preventDefault();
      setIsSuccess(false);
      // const { response } = await deleteClientContact(id);
      setIsSuccess(true);
      setTrigger(makeRandomString(5));
      setIsSuccesData({
        name: "delete-account",
        data: row,
      });
    } catch (err) {
      setIsSuccess(true);
      setErrorData({
        name: "delete-account",
        error: err?.response?.data?.error,
      });
      console.log(err);
    }
  };

  const handleEdit = (defaultValue) => {
    setShowEdit(true);
    setSelectedData(defaultValue);
  };

  const handleSendCredential = async (e, row) => {
    try {
      e.preventDefault();
      setIsSuccess(false);
      const { response } = await sendCredential(row?.id);
      setIsSuccesData({
        name: "send-credential",
        data: response,
      });
      setIsSuccess(true);
    } catch (error) {
      setErrorData({
        name: "send-credential",
        error: error?.response?.data?.error,
      });
      setIsSuccess(true);
      console.log(error);
    }
  };

  const handleSendSurvey = async (e, row) => {
    try {
      e.preventDefault();
      setIsSuccess(false);
      const { response } = await sendSurvey(row?.id);
      setIsSuccesData({
        name: "send-survey",
        data: {
          name: response?.response?.responden?.name,
          Client: {
            name: response?.response?.responden?.company,
          },
        },
      });
      setIsSuccess(true);
    } catch (error) {
      setErrorData({
        name: "send-survey",
        error: error?.response?.data?.error,
      });
      setIsSuccess(true);
      console.log(error);
    }
  };

  // const isNew = true;

  const columns = [
    {
      name: "name",
      title: "Name",
      renderData: (row) => (
        <div className={Styles.nameRow}>{row?.name || "-"}</div>
      ),
    },
    {
      name: "flbNumbers",
      title: "Document Number",
      renderData: (row) => {
        return (
          <div className={Styles.flbNumbersRow}>
            <span className={Styles.number}>
              {row?.flbNumbers?.slice(startIndex, endIndex)?.length
                ? row?.flbNumbers
                    ?.slice(startIndex, endIndex)
                    ?.map((item) => item)
                    .join(", ")
                : "-"}
            </span>

            {row?.flbNumbers?.length > 1 ? (
              <Tooltip title={<span>{row?.flbNumbers?.join(", ")}</span>}>
                <span className={Styles.more}>
                  +{row?.flbNumbers?.slice(endIndex)?.length}
                </span>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
    {
      name: "jobTitle",
      title: "Position",
    },
    {
      name: "email",
      title: "Email",
    },
    {
      name: "phoneNumber",
      title: "Phone",
    },
    {
      name: "workUnit",
      title: "Unit Kerja",
    },
    {
      name: "lastLogin",
      title: "Login Terakhir",
      renderData: (row) => {
        return (
          <div className={Styles.lastLoginRow}>
            {row?.lastLogin
              ? moment(new Date(row.lastLogin)).format("DD MMMM YYYY, HH:mm")
              : "-"}
          </div>
        );
      },
    },
    {
      name: "",
      title: "Action",
      align: "right",
      renderData: (row) => (
        <div className={Styles.actions}>
          <div
            className={Styles.dots}
            onClick={() => {
              setIsActive(row);
            }}
          >
            <Icon icon={"three-dots"} size={24} />
          </div>

          {isActive === row && (
            <div className={Styles.dropdownOptions} ref={boxRef}>
              <div onClick={() => handleEdit(row)}>
                <span>Ubah</span>
                <button disabled={isProcessing}>
                  <Icon icon="edit" size="20px" color={"#1571DE"} />
                </button>
              </div>
              <div onClick={(e) => handleSendCredential(e, row)}>
                <span>Kirim Akun ke klien</span>
                <button>
                  <Icon icon="send-solid" size="20px" color={"#1571DE"} />
                </button>
              </div>
              <div onClick={(e) => handleSendSurvey(e, row)}>
                <span>Kirim Survey</span>
                <button>
                  <Icon icon="whatsapp" size="20px" color={"#1571DE"} />
                </button>
              </div>
              <div onClick={(e) => handleDelete(e, row?.id, row)}>
                <span className={`${Styles.red}`}>Hapus</span>
                <button disabled={isProcessing}>
                  <Icon icon="trash" size="20px" color={"#FF3E13"} />
                </button>
              </div>
            </div>
          )}

          {/* <button disabled={isProcessing} onClick={() => handleEdit(row)}>
            <Icon icon="edit" size="20px" color={"#1571DE"} />
          </button>
          <button
            disabled={isProcessing}
            onClick={(e) => handleDelete(e, row?.id)}
          >
            <Icon icon="trash" size="20px" color={"#FF3E13"} />
          </button> */}
        </div>
      ),
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Information Contact</h3>
        {!rbmView && (
          <div className={Styles.topButton} onClick={() => setShowAdd(true)}>
            <Button
              type="primary"
              title={
                <span>
                  <Icon icon="circle-plus" size={20} color="white" />{" "}
                  Information Contact
                </span>
              }
            />
          </div>
        )}
      </div>
      <div className={Styles.content}>
        <table>
          <thead>
            <tr>
              {columns?.map((each, i) => (
                <th key={i} style={{ textAlign: each?.align || "left" }}>
                  {each?.title}
                </th>
              ))}
            </tr>
          </thead>
          {!!data?.length && (
            <tbody>
              {data?.map((each, i) => (
                <tr key={i} className={i % 2 === 0 ? Styles.odd : ""}>
                  {columns?.map((c, iC) => (
                    <td key={iC} style={{ textAlign: c?.align || "left" }}>
                      {c?.renderData ? c?.renderData(each) : each[c?.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {!data?.length && <TableEmptyHandler />}
        {/* {data?.map((each, i) => {
          return (
            <InformationContact
              key={i}
              title={`Contact ${i + 1}`}
              name={each?.name}
              position={each?.jobTitle}
              email={each?.email}
              phone={each?.phoneNumber}
              groupName={each?.workUnit}
              onDelete={(e) => handleDelete(e, each?.id)}
              onEdit={(e) => handleEdit(each)}
            />
          );
        })} */}
      </div>
      <div className={Styles.bottomButton} onClick={() => setShowAdd(true)}>
        <Button
          type="primary"
          title={
            <span>
              <Icon icon="circle-plus" size={20} color="white" /> Information
              Contact
            </span>
          }
        />
      </div>
    </div>
  );
}
