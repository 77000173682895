import ModalWrapper from "@Atom/ModalWrapper";
import Styles from "./style.module.scss";
import Rotation from "@Atom/Rotation";
import { useMemo } from "react";
import Icon from "@Atom/Icon";

export default function SendFLBModal({
  show,
  type,
  onClose = () => {},
  onNext = () => {},
  error,
}) {
  const title = useMemo(() => {
    switch (type) {
      case "submit":
        return "Mengirim...";
      case "draft":
        return "Menyimpan...";
      case "success":
        return "Terkirim";
      case "success-draft":
        return "Tersimpan";
      default:
        return "Gagal";
    }
  }, [type]);

  const text = useMemo(() => {
    switch (type) {
      case "submit":
        return "Mohon tunggu FLB sedang dalam proses pengiriman";
      case "draft":
        return "Mohon tunggu FLB sedang dalam proses penyimpanan";
      case "success":
        return "FLB berhasil dikirim untuk diproses";
      case "success-draft":
        return "Perubahan pada FLB berhasil disimpan";
      default:
        return error?.message || "Terjadi Kesalahan";
    }
  }, [type, error]);

  return (
    <ModalWrapper
      show={show}
      handleClose={
        type === "success" ? onNext : type === "failure" ? onClose : () => {}
      }
    >
      <div className={Styles.container}>
        {(type === "submit" || type === "draft") && <Rotation width="62px" />}
        {type?.includes("success") && (
          <Icon icon={"check-circle"} size={86} color={"#1571DE"} />
        )}

        {type?.includes("failure") && (
          <Icon icon={"information-solid"} size={86} color={"red"} />
        )}
        <h3>{title}</h3>
        <span>{text}</span>
        {(type === "submit" || type === "draft") && (
          <div className={Styles.notes}>
            <Icon icon={"information-solid"} size={20} color={"#1571DE"} />
            <p>
              Bila proses pengiriman memakan waktu lama, mohon jangan melakukan{" "}
              <span>refresh</span> pada halaman ini karena dapat menyebabkan
              hilangnya data.
            </p>
          </div>
        )}
        {type?.includes("success") && <button onClick={onNext}>Selesai</button>}
      </div>
    </ModalWrapper>
  );
}
