// import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import RecruiterDoneModal from "@Molecule/_modal/Recruiter/DoneModal";
import SuccessApprovalModal from "@Molecule/_modal/SuccessApprovalModal";
import UploadExcelModal from "@Molecule/_modal/UploadExcelModal";
import { candidateTransferType } from "@Services/officer/recruitment";
// import CandidateInformationModal from "@Molecule/_recruitment/CandidateInformationModal";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailCandidateRecruitment from "../Recruitment/RecruitmentList/DetailCandidateRekrutment";
import AddModal from "./AddModal";
import RecruiterApprovalModal from "./ApprovalModal";
import ListTableRecruiterCandidate from "./ListTable";
import TransferModal from "./TransferModal";
import Styles from "./styles.module.scss";

export default function RecruiterCandidateListLayout({
  mode,
  data,
  sync,
  limit,
  setLimit,
  page,
  setPage,
  search,
  setSearch,
  searchPosition,
  setSearchPosition,
  debouncedSearch,
  debouncedSearchPosition,
  setTrigger,
}) {
  // const query = useQuery();
  const navigate = useNavigate();
  // const { pathname } = useLocation();

  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [isLoadingdata, setIsLoadingData] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalTransfer, setShowModalTransfer] = useState(false);
  const [showModalApproval, setShowModalApproval] = useState(false);
  const [showModalDone, setShowModalDone] = useState(false);
  const [showSnackDone, setShowSnackDone] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [pages, setPages] = useState(`1-${limit}`);
  const [pageList, setPageList] = useState([]);
  const [type, setType] = useState("");
  const [doneTitle, setDoneTitle] = useState("");
  const [doneText, setDoneText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [defaultExpandId, setDefaultExpandId] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isSuccessAdding, setIsSuccessAdding] = useState(false);

  useEffect(() => {
    if (data?.totalPage < page) {
      setPage(1);
    }
  }, [data?.totalPage, page, setPage]);

  const listEducation = useMemo(() => {
    return sync?.educationOptions?.map((value) => {
      return value?.value;
    });
  }, [sync]);

  const listDomicile = useMemo(() => {
    return sync?.domicileOptions?.map((value) => {
      return value?.name;
    });
  }, [sync]);

  const handleCloseInfo = () => {
    setShowModalDetail(false);
    setSelectedCandidate({});
    setDefaultExpandId(1);
  };

  // console.log(selectedCandidate?.lastPosition);

  const handleSubmitPublicOrPrivate = async () => {
    try {
      console.log("submitted");
      await candidateTransferType(
        selectedCandidate?.id,
        type,
        encodeURIComponent(selectedCandidate?.lastPosition),
        {}
      );
      setTrigger(Math.random());
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      // setTextError(errorMessage)
      console.log(errorMessage);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.searchWrapper}>
          <OutlinedSearchBar
            value={search}
            setValue={setSearch}
            placeholder={"Cari Nama Kandidat"}
          />

          <OutlinedSearchBar
            value={searchPosition}
            setValue={setSearchPosition}
            placeholder={"Cari Posisi"}
            icon={"bag-job"}
          />
        </div>

        <div className={Styles.rightBox}>
          <div
            className={`${Styles.buttonUpload}`}
            onClick={() => setShowAddModal(true)}
          >
            <span>Upload Excel</span>
            <Icon icon={"excel"} size={20} color={"#1571DE"} />
            {/* <Icon icon={"excel"} size={20} color={"#9e9e9e"} /> */}
          </div>
          <div
            className={`${Styles.addButton}`}
            onClick={() => {
              setShowModalAdd(true);
            }}
          >
            <span>Tambah Kandidat</span>
            {/* <Icon icon={"page-plus"} size={20} color={"#9e9e9e"} /> */}
            <Icon icon={"page-plus"} size={20} color={"#FFFFFF"} />
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        {mode !== "public" && (
          <div className={Styles.selectorWrapper}>
            <button
              className={`${Styles.selectorBox} ${
                mode === "private" ? Styles.active : ""
              }`}
              onClick={() => {
                navigate("/daftar-kandidat-private");
              }}
            >
              <span>{"Kandidat Private"}</span>
            </button>
            <button
              className={`${Styles.selectorBox} ${
                mode === "unprocessed" ? Styles.active : ""
              }`}
              onClick={() => {
                navigate("/daftar-kandidat-unprocessed");
              }}
            >
              <span>{"Kandidat Proses Tertunda"}</span>
            </button>
          </div>
        )}

        {mode === "unprocessed" && (
          <div className={Styles.messageWrapper}>
            <div className={Styles.iconDash}>
              <Icon icon={"information-solid"} color={"#1571DE"} size={20} />
            </div>
            <span>
              {
                "Berikut adalah daftar kandidat yang belum diproses selama lebih dari 2 minggu."
              }
            </span>
          </div>
        )}

        <div className={Styles.tableBox}>
          <ListTableRecruiterCandidate
            data={data?.data}
            isLoading={isLoadingdata}
            setIsLoadingData={setIsLoadingData}
            selectedCandidate={selectedCandidate}
            setSelectedCandidate={setSelectedCandidate}
            setShowModalDetail={setShowModalDetail}
            setShowModalTransfer={setShowModalTransfer}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            totalPage={data?.totalPage}
            totalCandidates={data?.totalCandidates}
            setType={setType}
            mode={mode}
            setShowModalApproval={setShowModalApproval}
          />
        </div>
      </div>

      {showModalDetail && selectedCandidate && (
        <ModalWrapper show={showModalDetail} handleClose={() => {}}>
          <DetailCandidateRecruitment
            handleClose={() => {
              handleCloseInfo();
            }}
            showDetailCandidate={selectedCandidate}
          />
        </ModalWrapper>
      )}

      <ModalWrapper
        show={showModalTransfer}
        handleClose={() => {
          setShowModalTransfer(false);
          setSelectedCandidate({});
        }}
      >
        <TransferModal
          mode={mode}
          type={type}
          setType={setType}
          data={selectedCandidate}
          setData={setSelectedCandidate}
          setShowModalTransfer={setShowModalTransfer}
          setShowModalDone={setShowModalDone}
          setDoneText={setDoneText}
          setDoneTitle={setDoneTitle}
          setTrigger={setTrigger}
          // setType={setType}
        />
      </ModalWrapper>

      <ModalWrapper
        show={showModalAdd}
        handleClose={() => {
          setShowModalAdd(false);
        }}
      >
        <AddModal
          setShowModalAdd={setShowModalAdd}
          setDoneText={setDoneText}
          setDoneTitle={setDoneTitle}
          setShowModalDone={setShowModalDone}
          educationOptions={listEducation}
          domicileOptions={listDomicile}
          setTrigger={setTrigger}
        />
      </ModalWrapper>

      {showModalDone && (
        <RecruiterDoneModal
          handleDone={() => {
            setShowModalDone(false);
          }}
          title={doneTitle}
          description={doneText}
          showButton={true}
          titleButton={"Tutup"}
          show={showModalDone}
        />
      )}

      {showModalApproval && (
        <RecruiterApprovalModal
          handleDone={() => {
            setShowSnackDone(true);
            setShowModalApproval(false);
          }}
          onCancel={() => {
            setSelectedCandidate(null);
            setType(null);
            setShowModalApproval(false);
          }}
          show={showModalApproval}
          data={selectedCandidate}
          type={type}
        />
      )}

      {showSnackDone && (
        <SuccessApprovalModal
          message={`Kandidat ${selectedCandidate?.name} berhasil dialihkan ke daftar kandidat ${type}`}
          handleDone={() => {
            handleSubmitPublicOrPrivate();
            setSelectedCandidate(null);
            setType(null);
            setShowSnackDone(false);
          }}
          onBack={() => {
            setSelectedCandidate(null);
            setType(null);
            setShowSnackDone(false);
          }}
          cancelType={"button"}
        />
      )}

      <ModalWrapper show={showAddModal} handleClose={() => {}}>
        <UploadExcelModal
          setShow={setShowAddModal}
          setIsSuccess={setIsSuccessAdding}
          positionID={""}
          setTrigger={setTrigger}
          show={showAddModal}
          isSuccessAdding={isSuccessAdding}
        />
      </ModalWrapper>
    </div>
  );
}
