import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import useQuery from "@Hooks/useQuery";
import Stepper from "@Molecule/Stepper";
import { useNavigate } from "react-router-dom";

export default function SchemeEditFormNavigation({
  stepsOptions,
  currentStep,
  contractType,
  handleClickStep = () => {},
  isProceed,
  alertMessage = "",
}) {
  const navigate = useNavigate();
  const query = useQuery();
  const name = query.get("n");
  // const code = query.get('c')

  const handleClickBack = () => {
    // navigate(`/clients/orders?n=${name}&c=${code}`);
    sessionStorage?.clear();
    navigate(-1);
  };
  const handleNavigateToList = () => {
    // navigate(`/clients/orders?n=${name}&c=${code}`);
    navigate(-1);
  };

  const handleNavigateToDetails = () => {
    // navigate(`/clients/details?n=${name}&c=${code}`);
    navigate(-1);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper}>
        <div className={Styles.breadcrumbWrapper}>
          <button onClick={handleClickBack}>
            <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
          </button>
          {!isProceed ? (
            <CustomBreadCrumb>
              <span
                onClick={handleNavigateToList}
                className={Styles.breadcrumbText}
              >
                Job Orders
              </span>
              <span
                onClick={handleNavigateToDetails}
                className={Styles.breadcrumbText}
              >
                {name}
              </span>
              <span onClick={handleClickBack} className={Styles.breadcrumbText}>
                Order
              </span>
              <span className={Styles.breadcrumbTextBold}>Edit</span>
            </CustomBreadCrumb>
          ) : (
            <CustomBreadCrumb>
              <span
                onClick={handleNavigateToList}
                className={Styles.breadcrumbText}
              >
                Service Client
              </span>
              <span
                onClick={handleNavigateToDetails}
                className={Styles.breadcrumbText}
              >
                {name}
              </span>
              <span className={Styles.breadcrumbTextBold}>FLB Delegation</span>
            </CustomBreadCrumb>
          )}
        </div>
        {contractType && !isProceed && (
          <div className={Styles.typeWrapper}>
            <Icon icon="file" size={27} className={Styles.contractIcon} />
            <div className={Styles.descWrapper}>
              <span>{contractType?.type}</span>
              <span>{contractType?.label}</span>
            </div>
          </div>
        )}
      </div>
      {!isProceed && (
        <div className={Styles.stepperWrapper}>
          <Stepper
            type="filled"
            options={stepsOptions}
            currentStep={currentStep}
            onClick={handleClickStep}
            noDoneIcon
            noWhiteSpace
            // disableClick
          />
        </div>
      )}
      {alertMessage ? (
        <div className={Styles.infoWrapper}>
          <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
          <span>{alertMessage}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
