import Icon from "@Atom/Icon";
import { Grid } from "../Second";
import Styles from "./styles.module.scss";
import { DeploymentCard } from "../Fourth";

export default function Sixth({ delegationsForm }) {
  //   console.log(delegationsForm, "test");
  return (
    <div className={Styles.container}>
      <span>Deployment</span>
      <Grid gridTemplateColumns="1fr">
        {delegationsForm?.map((d, dI) => (
          <Grid
            key={dI}
            border="1px solid #E0E0E0"
            title={
              <p className={Styles.dCity}>
                <Icon icon={"company-outline"} size={20} color={"#9E9E9E"} />
                <span>{d?.cityName}</span>
              </p>
            }
            padding="16px"
          >
            {d?.deployments?.map((de, deI) => (
              <DeploymentCard
                key={deI}
                role={de?.role}
                delegations={de?.delegations?.filter((obj) => obj?.isPicked)}
              />
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
