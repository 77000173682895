import InputField from "@Molecule/InputField";
import Styles from "./style.module.scss";
import SelectOptionsToggle from "@Molecule/SelectOptionToggle";
import { useEffect, useMemo, useState } from "react";
import { makeRandomString } from "@Helpers/makeRandomString";
import { bankOptions } from "@Assets/Datas/bankOptions";

export default function AccountSection(props) {
  const { form, setForm, setTrigger } = props;
  const [bankName, setBankName] = useState(form?.recipientsAccount?.bankName);
  const bankRecipient = "PT Permata Indo Sejahtera";

  const bankNumber = useMemo(() => {
    return bankOptions.find((el) => el?.name === bankName)?.number;
  }, [bankName]);

  useEffect(() => {
    const newForm = form;
    newForm.recipientsAccount.bankRecipient = bankRecipient;
    newForm.recipientsAccount.bankName = bankName;
    newForm.recipientsAccount.bankNumber = bankNumber;
    setForm(newForm);
    setTrigger(makeRandomString(5));
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
  }, [form, setForm, bankRecipient, bankName, bankNumber, setTrigger]);

  return (
    <div className={Styles.container}>
      <h3>Rekening Penerima Invoice</h3>

      <div className={Styles.content}>
        <div className={Styles.wrapper}>
          <InputField
            title="Perusahaan Penerima"
            value={bankRecipient}
            disabled={true}
          />
        </div>
        <div className={Styles.wrapper}>
          <SelectOptionsToggle
            title="Bank"
            placeholder="Select Bank"
            options={bankOptions}
            value={bankName}
            setValue={setBankName}
            autoClose={true}
            required
            overFlow
          />
        </div>
        <div className={Styles.wrapper}>
          <InputField
            title="Nomor Rekening"
            value={bankNumber}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}
