import TipsAndTricks from "@Organism/Recruiter/TipsAndTricks";
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";
import { getTipsList } from "@Services/officer/tipsAndTrick";
import moment from "moment";
import { defer } from "react-router-dom";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const query = url.searchParams
  // const limit = query.get("l") || 15;
  const page = query.get("p") || 1;
  const search = query.get('s') || ''
  const sort = query.get('sr') || ''
  const status = query.get('st') || ''
  const dateFrom = query.get('df') ? moment(new Date(query.get('df')))?.format('YYYY-MM-DD') : ''
  const dateTo = query.get('dt') ? moment(new Date(query.get('dt')))?.format('YYYY-MM-DD') : ''


  const packageTipsListPromise = getTipsList(15, page, sort, dateFrom, dateTo, status, search)

  return defer({
    packageTipsList: packageTipsListPromise
  })
}

const TipsAndTricksPage = () => {
  const underMaintenance = false

  if (underMaintenance) {
    return <UnderMaintenacePage />
  }
  return (
    <>
      <TipsAndTricks />
    </>
  );
};

export default TipsAndTricksPage;
