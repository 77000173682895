import { Box, Skeleton, Typography } from "@mui/material";

export default function SkeletonChart() {
  return (
    <Box
      sx={{
        position: "relative",
        width: 200,
        height: 200,
      }}
    >
      {/* Circular Skeleton */}
      <Skeleton
        variant="circular"
        width={200}
        height={200}
        sx={{
          borderRadius: "50%",
        }}
      />

      {/* Inner "hole" to create thinner doughnut effect */}
      <Box
        sx={{
          position: "absolute",
          top: "10%", // Inner circle is larger
          left: "10%",
          width: "80%", // Larger inner circle makes outer ring thinner
          height: "80%",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      />

      {/* Text in the center */}
      <Typography
        variant="h6"
        component="div"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Centers the text
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          alignItems: "center",
        }}
      >
        <Skeleton
          duration={2}
          //   className={Styles.defaultSkeleton}
          height={30}
          width={57}
          sx={{ borderRadius: "12px" }}
        />

        <Skeleton
          duration={2}
          //   className={Styles.defaultSkeleton}
          height={16}
          width={60}
          sx={{ borderRadius: "12px" }}
        />
      </Typography>
    </Box>
  );
}
