import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import CustomTable from "@Molecule/CustomTable";
import { useDebounce } from "@Hooks/useDebounce";
import { getJobPostPerID } from "@Services/officer/jobPosting";

export default function PlacementListModal({ handleClose, data }) {
  const [searchLocation, setSearchLocation] = useState("");
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const debouncedSearchTerm = useDebounce(searchLocation, 300);

  const [isFecthing, setIsFetching] = useState(false);
  const [jobPost, setJobPost] = useState(data || null);

  const refetchData = useCallback(async () => {
    try {
      setIsFetching(true);
      const { response } = await getJobPostPerID(data?._id);
      setJobPost(response);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      refetchData();
    }
  }, [data, refetchData]);

  const filteredData = useMemo(() => {
    if (jobPost?.allPlacement?.length > 0) {
      return jobPost?.allPlacement.filter((obj) =>
        obj.city.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
    } else {
      return [];
    }
  }, [debouncedSearchTerm, jobPost]);

  const template = useMemo(() => {
    return {
      data:
        filteredData?.length > 0
          ? filteredData.map((obj, idx) => ({
              no: idx + 1,
              location: obj.city,
              status: obj.myCity,
            }))
          : [],
      columns: [
        {
          name: "no",
          label: "No",
          align: "center",
          renderData: (row) => (
            <div className={`${Styles.rowText}`}>{row?.no || "-"}</div>
          ),
        },
        {
          name: "location",
          label: "Lokasi Untuk Job Posting",
          renderData: (row) => (
            <div className={`${Styles.rowText} ${Styles.rowLocation}`}>
              {row?.location || "-"}
            </div>
          ),
        },
        {
          name: "status",
          label: "",
          renderData: (row) => (
            <div className={Styles.dedicatedBoxRow}>
              {row?.status ? <span>Lokasi Anda</span> : null}
            </div>
          ),
        },
      ],
    };
  }, [filteredData]);

  useEffect(() => {
    const totalPage = Math.ceil(template?.data?.length / limit);
    if (totalPage === 1 || totalPage === 0) {
      setPage(1); // Move to the previous page if no data on current page
    } else {
      const validPage = Math.max(1, Math.min(page, totalPage));
      setPage(validPage);
    }
  }, [template, limit, page]);

  return (
    <div className={Styles.placementContainer}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <span>Lokasi • {data?.title || "-"}</span>
        </div>
        <div className={Styles.right} onClick={handleClose}>
          <Icon icon={"cross"} size={24} />
        </div>
      </div>

      <div className={Styles.content}>
        <div className={Styles.status}>
          <span className={Styles.left}>Status job posting</span>
          <div
            className={`${Styles.right} ${
              data?.status?.toLowerCase() === "draft" && Styles.yellow
            } ${
              data?.status?.toLowerCase() === "publish" ||
              data?.status?.toLowerCase() === "active"
                ? Styles.green
                : ""
            } ${data?.status?.toLowerCase() === "inactive" && Styles.grey} ${
              data?.type &&
              data?.type?.toLowerCase() === "peralihan" &&
              data?.status?.toLowerCase() === "draft" &&
              Styles.grey
            } ${data?.status?.toLowerCase() === "expired" && Styles.red}`}
          >
            <div className={`${Styles.dots}`}></div>
            <span>
              {data?.status
                ? data?.type &&
                  data?.type?.toLowerCase() === "peralihan" &&
                  data?.status?.toLowerCase() === "draft"
                  ? "Inactive"
                  : data?.status
                : "-"}
            </span>
          </div>
        </div>

        <div className={Styles.tableBox}>
          <div className={Styles.searchBox}>
            <OutlinedSearchBar
              value={searchLocation}
              setValue={setSearchLocation}
              placeholder={"Cari lokasi job posting"}
            />
          </div>
          <CustomTable
            columns={template?.columns}
            data={template?.data?.slice((page - 1) * limit, page * limit) || []}
            totalData={template?.data?.length || 0}
            // type={"sticky-left"}
            value={limit || 5}
            setValue={setLimit}
            selectedPage={page}
            setSelectedPage={setPage}
            totalPage={Math.ceil(template?.data?.length / limit) || 0}
            noPageList
            stickyTop
            isLoadingSkeleton={isFecthing}
          />
        </div>
      </div>
    </div>
  );
}
