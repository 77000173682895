import AutocompleteV2 from "@Atom/AutocompleteV2";
import Checkbox from "@Atom/Checkbox";
import Spinner from "@Atom/Spinner";
import { getSyncTransferBM, transferOwnerhip } from "@Services/manager/bm";
import Images from "@Theme/Images";
import { useCallback, useEffect, useState } from "react";
import DoneGif from "../../../../assets/Gifs/done.gif";
import Styles from "./styles.module.scss";

export default function OwnerShipModal({ handleClose, data, setTrigger }) {
  const [searchBM, setSearchBM] = useState("");
  const [approved, setApproved] = useState(false);
  const [objValueBM, setObjValueBM] = useState(null);
  const [isLoadingData, setIsLodingData] = useState(false);
  const [dataBM, setDataBM] = useState(null);

  const refetchData = useCallback(async () => {
    try {
      setIsLodingData(true);
      const { response } = await getSyncTransferBM();
      setDataBM(response);
      setIsLodingData(false);
    } catch (err) {
      setIsLodingData(false);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (data) {
      refetchData();
    }
  }, [refetchData, data]);

  const [errorText, setErrorText] = useState("");
  const [errorValidate, setErrorValidate] = useState({
    objValueBM: false,
    approved: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successModal, setSuccessModal] = useState(null);

  const handleSubmit = async (e) => {
    try {
      setIsSubmitting(true);
      if (!searchBM || !approved) {
        setErrorText("Masih ada field yang belum terisi");
        setIsSubmitting(false);
        if (!searchBM) {
          setErrorValidate((prev) => ({
            ...prev,
            objValueBM: true,
          }));
        } else {
          setErrorValidate((prev) => ({
            ...prev,
            objValueBM: false,
          }));
        }

        if (!approved) {
          setErrorValidate((prev) => ({
            ...prev,
            approved: true,
          }));
        } else {
          setErrorValidate((prev) => ({
            ...prev,
            approved: false,
          }));
        }
      } else {
        const response = await transferOwnerhip({
          clientCode: data?.code,
          businessManager: objValueBM?.employeeId,
        });
        // navigate(
        //   `/daftar-bm/${id}?p=${query?.get("p") || 1}&l=${
        //     query?.get("l") || 10
        //   }&n=${query?.get("n") || ""}&t=${query?.get("t")}&u=${query?.get(
        //     "u"
        //   )}`
        // );

        setTrigger(Math.random());
        setSuccessModal(response?.response);
        setIsSubmitting(false);
        setErrorValidate((prev) => ({
          objValueBM: false,
          approved: false,
        }));
        setErrorText("");
      }
    } catch (error) {
      const errorText = error?.response?.data?.error;
      console.log(errorText);
      setErrorText(errorText);
      setIsSubmitting(false);
    }
  };

  return successModal ? (
    <div className={Styles.successModal}>
      <img
        src={DoneGif}
        style={{ width: "202px", aspectRatio: 1, objectFit: "cover" }}
        alt=""
      />

      <div>
        Berhasil Mengganti Kepemilikan Klien {successModal?.client?.name || "-"}
      </div>

      <div>
        Klien {successModal?.client?.name || "-"} telah berhasil dipindahkan
        kepemilikannya kepada BM {successModal?.businessManager?.name || "-"}
      </div>

      <div
        onClick={() => {
          handleClose();
          setSuccessModal(null);

          setSearchBM("");
          setObjValueBM(null);
          setApproved(false);
        }}
      >
        Done
      </div>
    </div>
  ) : (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <span>Ganti Kepemilikan Klien</span>
        <span>{data?.name || "-"}</span>
        <div>
          <img src={Images.BLOG_AND_ARTICLE_2} alt="" />
        </div>
      </div>

      <div className={Styles.inputBox}>
        <div className={Styles.selectedBM}>
          <span>Pilih BM</span>
          <div className={Styles.autoComplete}>
            <AutocompleteV2
              isError={errorValidate?.objValueBM && !searchBM}
              isLoading={isLoadingData}
              value={searchBM}
              setValue={setSearchBM}
              //   isLoading={isLoadingPksList}
              placeholder={"Pilih BM"}
              options={
                dataBM?.businessManagers?.map((el, idx) => {
                  return {
                    id: idx,
                    name: `${el?.User?.name || "-"} - ${
                      el?.User?.region || "-"
                    }`,
                    employeeId: el?.User?.employeeId || null,
                  };
                }) || []
              }
              onClickOption={setObjValueBM}
            />
          </div>
        </div>

        <div className={Styles.checkBoxWrapper}>
          <div className={Styles.checkBox}>
            <div>
              {/* <input
                type="checkbox"
                defaultChecked={approved}
                onChange={(e) => setApproved(e.target.checked)}
                className={`${Styles.checkbox} ${
                  errorValidate?.approved && Styles.boderRed
                }`}
              /> */}

              <Checkbox
                type={"job-post"}
                isChecked={approved}
                setIsChecked={setApproved}
                isError={errorValidate?.approved && !approved}
              />
            </div>
            <span>
              Saya yakin ingin melakukan perubahan kepemilikan atas klien ini.
            </span>
          </div>
        </div>
      </div>

      <div className={Styles.buttonSection}>
        {errorText ? <span>*{errorText}</span> : null}
        <div>
          <span onClick={handleClose}>Cancel</span>
          <span onClick={isSubmitting ? null : handleSubmit}>
            {isSubmitting ? <Spinner /> : "Save"}
          </span>
        </div>
      </div>
    </div>
  );
}
