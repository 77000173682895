import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import { useState } from "react";
import NewAddPositionModal from "@Molecule/_modal/NewAddPositionModal";

export default function Counter(props) {
  const { onAdd, onSubtract, value, setValue = () => { }, disabled } = props;
  const [editMode, setEditMode] = useState(false)
  return (
    <div className={Styles.container} disabledField={disabled ? 'true' : ''}>
      <div className={Styles.subtract} onClick={disabled ? () => { } : onSubtract}>
        <Icon icon="minus" size={14} className={Styles.icon} />
      </div>
      <div onClick={() => disabled ? () => { } : setEditMode(true)} className={Styles.value}>{value || 1}</div>
      <div className={Styles.add} onClick={disabled ? () => { } : onAdd}>
        <Icon icon="add" size={14} className={Styles.icon} />
      </div>

      {
        editMode
        &&
        <NewAddPositionModal
          show={editMode}
          setShow={setEditMode}
          editQtyMode
          defaultQty={value}
          onAdd={(newVal) => setValue(newVal)}
        />
      }
    </div>
  );
}
