import Styles from "./styles.module.scss";

const CheckboxJobPosting = ({
  checked,
  onChange = () => {},
  disabled,
  readOnly = false,
  type,
  // isError,
  ...props
}) => {
  if (type === "radio-section") {
    return (
      <label className={Styles.container}>
        <input
          type="checkbox"
          checked={checked}
          onChange={!readOnly ? onChange : () => {}}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span className={`${Styles.checkmark}`}></span>
      </label>
    );
  }

  if (type === "lainnya") {
    return (
      <label className={Styles.containerOther}>
        <div className={Styles.disabled}></div>
      </label>
    );
  }

  return (
    <label {...props} className={Styles.containerRecrutment}>
      <input
        type="checkbox"
        checked={disabled ? true : checked}
        onChange={
          !readOnly
            ? (e) => {
                // e.stopPropagation()
                onChange(e);
              }
            : () => {}
        }
        disabled={disabled}
        readOnly={readOnly}
      />
      <span
        className={`${Styles.checkmarkRecrutment} ${
          readOnly ? Styles.readOnly : ""
        }`}
      ></span>
    </label>
  );
};

export default CheckboxJobPosting;
