import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const API2 = axios.create({
  baseURL: "https://hris-api-dev.permataindonesia.com",
});
export const APIIOS = axios.create({
  baseURL: "http://ios.permataindonesia.com/api",
});

export const fileBaseUrl = process.env.REACT_APP_FILE_BASE_URL;
export const fileBaseUrlDropbox = "https://devapi.kerja365.id/api/dropbox/";
