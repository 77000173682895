import { useRef } from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale
);

export default function SkeletonChartLine() {
  const boxRef = useRef();

  const labels = [
    "1 Jan",
    "2 Jan",
    "3 Jan",
    "4 Jan",
    "5 Jan",
    "6 Jan",
    "7 Jan",
    "8 Jan",
    "9 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
    "31 Jan",
  ];

  const dataTemporary = [
    0, 20, 20, 20, 20, 20, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 50, 50,
    150, 50, 100, 100, 100, 156, 156, 140, 156, 156, 100, 100,
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataTemporary,
        backgroundColor: "#EDEDED",
        label: "Total",
        borderWidth: 0.5,
        borderColor: "#EDEDED",
        fill: true, // Ensure fill is set to true
        tension: 0.4, // Nilai tension untuk mode "bezier"
      },
    ],
  };

  const options = {
    pointRadius: 0,
    pointHoverRadius: 7,
    hoverBackgroundColor: "#EDEDED",
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      stacked100: { enable: true },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 4,
        },
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grace: "50%",
        ticks: {
          maxTicksLimit: 5,
          display: false,
        },
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
      //   plugins={[hoverLine]}
      ref={boxRef}
      // height={250}
    />
  );
}
