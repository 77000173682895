import React, { useState, useRef, useEffect, useCallback } from "react";
import Styles from "./multiSelect.module.scss";
import Icon from "@Atom/Icon";
import Radio from "./radio";
import ErrorBubble from "@Atom/ErrorBubble";

const MultiSelect = ({
  disabled,
  options,
  addLabel,
  labels,
  removeLabel,
  preventDeleteIndicator,
  isError = false,
  isErrorV2 = false,
  errorText = "",
  top = false,
  ...props
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);

  const activateDropdown = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [ref, isActive]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  useEffect(() => {
    if (disabled) {
      setCurrentValue(null);
    }
  }, [disabled]);

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        className={`${Styles.container} ${
          isActive ? Styles.activeContainer : null
        }
        ${disabled ? Styles.containerColorDisabled : null}
        ${isError ? Styles.error : ""}
        `}
        onClick={activateDropdown}
      >
        <div
          className={currentValue ? Styles.valueText : Styles.placeholderText}
        >
          Select
        </div>
        {isActive ? (
          <Icon icon="arrow-up" size="20px" />
        ) : (
          <Icon icon="arrow-down" size="20px" />
        )}
      </div>
      {isActive && !disabled ? (
        <div className={`${Styles.options} ${top && Styles.topOptions}`}>
          {options?.map((option, id) => {
            return (
              <div className={Styles.optionWrapper} key={id}>
                <Radio
                  option={option}
                  addLabel={addLabel}
                  labels={labels}
                  removeLabel={removeLabel}
                  preventDeleteIndicator={preventDeleteIndicator}
                />
                {id === options?.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      {isError &&
        !disabled &&
        (!isErrorV2 ? (
          <span className={Styles.errorText}>{errorText}</span>
        ) : (
          <ErrorBubble errorText={errorText} />
        ))}
    </div>
  );
};

export default MultiSelect;
