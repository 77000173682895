import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import { useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import moment from "moment";
import useQuery from "@Hooks/useQuery";

export default function JobPostingListHeader({
  data,
  trigger,
  setShowAction,
  showAction,
  isLoading,
  dataSync,
  pageQuery,
  searchTerm,
  setSearchTerm,
  setPage,
  onClickOption,
  setShowFilter,
  flb,
  client,
  location,
  dateFrom,
  dateTo,
  status,
  page,
}) {
  const query = useQuery();
  const range = useMemo(() => {
    if (data) {
      return `${(+pageQuery - 1) * 15 + 1} - ${
        (+pageQuery - 1) * 15 + data?.data?.length
      }`;
    } else {
      return "0 - 0";
    }
  }, [data, pageQuery]);

  useEffect(() => {
    const previousPage = page;
    if (data?.totalPage === 1 || data?.totalPage === 0) {
      setPage(1);
    } else {
      const validPage = Math.max(1, Math.min(previousPage, data?.totalPage));
      setPage(validPage);
    }
  }, [page, data, setPage]);

  return (
    <div
      className={Styles.container}
      onClick={() => {
        if (showAction) {
          setShowAction("");
        }
      }}
    >
      <div className={Styles.top}>
        <div className={Styles.searchBox}>
          <SearchBar
            isJobPosting={true}
            value={searchTerm}
            placeholder={"Cari Posisi"}
            onChange={setSearchTerm}
            isLoadingFilter={isLoading}
            onClickOption={onClickOption}
            options={
              dataSync?.positions?.map((el, idx) => {
                return {
                  id: idx,
                  name: el,
                };
              }) || []
            }
          />
        </div>

        <div
          className={Styles.filter}
          onClick={() => {
            setShowFilter(true);
          }}
        >
          <span>Filter</span>
          <Icon icon={"adjustment"} size={20} color={"#FFFFFF"} />
        </div>
      </div>

      <div className={Styles.middle}>
        <div className={Styles.descBox}>
          <div className={`${Styles.blueDark}`}>
            <div className={Styles.dots}></div>
            <span>Job Posting Saya</span>
          </div>

          <div className={`${Styles.grey}`}>
            <div className={Styles.dots}></div>
            <span>Job Posting Bayangan</span>
          </div>

          <div className={`${Styles.blueSky}`}>
            <div className={Styles.dots}></div>
            <span>Job Posting Harus Dilengkapi</span>
          </div>

          <div className={`${Styles.white}`}>
            <div className={Styles.dots}></div>
            <span>Job Posting Lama</span>
          </div>
        </div>

        <div className={Styles.paging}>
          <span>
            {range} dari {data?.totalData || 0}
          </span>
          <button
            disabled={+pageQuery === 1}
            onClick={() => setPage(+pageQuery - 1)}
          >
            <Icon icon={"arrow-left"} size={24} />
          </button>
          <button
            disabled={+pageQuery === data?.totalPage}
            onClick={() => setPage(+pageQuery + 1)}
          >
            <Icon icon={"arrow-right"} size={24} />
          </button>
        </div>
      </div>
      {flb || client || location || dateFrom || dateTo || status ? (
        <div className={Styles.bottom}>
          {flb ? (
            <div>
              <span>FLB :</span>
              <span>{flb || "-"}</span>
              <Icon
                icon={"cross"}
                size={16}
                color={"#1571DE"}
                className={Styles.iconCross}
                onClick={() => {
                  onClickOption({
                    flb: "",
                    name: query?.get("s") || "",
                    clientName: query?.get("cl") || "",
                    city: query?.get("lc") || "",
                    startDate: query?.get("df") || "",
                    endDate: query?.get("dt") || "",
                    statusName: query?.get("st") || "",
                  });
                }}
              />
            </div>
          ) : null}

          {client ? (
            <div>
              <span>Perusahaan :</span>
              <span>{client || "-"}</span>
              <Icon
                icon={"cross"}
                size={16}
                color={"#1571DE"}
                className={Styles.iconCross}
                onClick={() => {
                  onClickOption({
                    flb: query?.get("flb") || "",
                    name: query?.get("s") || "",
                    clientName: "",
                    city: query?.get("lc") || "",
                    startDate: query?.get("df") || "",
                    endDate: query?.get("dt") || "",
                    statusName: query?.get("st") || "",
                  });
                }}
              />
            </div>
          ) : null}

          {location ? (
            <div>
              <span>Lokasi :</span>
              <span>{location || "-"}</span>
              <Icon
                icon={"cross"}
                size={16}
                color={"#1571DE"}
                className={Styles.iconCross}
                onClick={() => {
                  onClickOption({
                    flb: query?.get("flb") || "",
                    name: query?.get("s") || "",
                    clientName: query?.get("cl") || "",
                    city: "",
                    startDate: query?.get("df") || "",
                    endDate: query?.get("dt") || "",
                    statusName: query?.get("st") || "",
                  });
                }}
              />
            </div>
          ) : null}

          {dateFrom ? (
            <div>
              <span>Tanggal Awal :</span>
              <span>
                {moment(new Date(dateFrom))?.format("YYYY-MM-DD") || "-"}
              </span>
              <Icon
                icon={"cross"}
                size={16}
                color={"#1571DE"}
                className={Styles.iconCross}
                onClick={() => {
                  onClickOption({
                    flb: query?.get("flb") || "",
                    name: query?.get("s") || "",
                    clientName: query?.get("cl") || "",
                    city: query?.get("lc") || "",
                    startDate: "",
                    endDate: query?.get("dt") || "",
                    statusName: query?.get("st") || "",
                  });
                }}
              />
            </div>
          ) : null}

          {dateTo ? (
            <div>
              <span>Tanggal Akhir :</span>
              <span>
                {moment(new Date(dateTo))?.format("YYYY-MM-DD") || "-"}
              </span>
              <Icon
                icon={"cross"}
                size={16}
                color={"#1571DE"}
                className={Styles.iconCross}
                onClick={() => {
                  onClickOption({
                    flb: query?.get("flb") || "",
                    name: query?.get("s") || "",
                    clientName: query?.get("cl") || "",
                    city: query?.get("lc") || "",
                    startDate: query?.get("df") || "",
                    endDate: "",
                    statusName: query?.get("st") || "",
                  });
                }}
              />
            </div>
          ) : null}

          {status ? (
            <div>
              <span>Status :</span>
              <span>{status || "-"}</span>
              <Icon
                icon={"cross"}
                size={16}
                color={"#1571DE"}
                className={Styles.iconCross}
                onClick={() => {
                  onClickOption({
                    flb: query?.get("flb") || "",
                    name: query?.get("s") || "",
                    clientName: query?.get("cl") || "",
                    city: query?.get("lc") || "",
                    startDate: query?.get("df") || "",
                    endDate: query?.get("dt") || "",
                    statusName: "",
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
