const getSalaryInMonth = (periodStr, baseSalary) => {
  switch (periodStr) {
    case "Harian":
      return +baseSalary * 20;
    case "Mingguan":
      return +baseSalary * 4;
    default:
      return +baseSalary;
  }
};

export function BPURangeSalary(salary, salaryPeriod) {
  const salaryRanges = [
    {
      min: 0,
      max: 1099000,
      jkk: "10000",
      jkm: "6800",
      jht: "20000",
    },
    {
      min: 1100000,
      max: 1299000,
      jkk: "12000",
      jkm: "6800",
      jht: "24000",
    },
    {
      min: 1300000,
      max: 1499000,
      jkk: "14000",
      jkm: "6800",
      jht: "28000",
    },
    {
      min: 1500000,
      max: 1699000,
      jkk: "16000",
      jkm: "6800",
      jht: "32000",
    },
    {
      min: 1700000,
      max: 1899000,
      jkk: "18000",
      jkm: "6800",
      jht: "36000",
    },
    {
      min: 1900000,
      max: 2099000,
      jkk: "20000",
      jkm: "6800",
      jht: "40000",
    },
    {
      min: 2100000,
      max: 2299000,
      jkk: "22000",
      jkm: "6800",
      jht: "44000",
    },
    {
      min: 2300000,
      max: 2499000,
      jkk: "24000",
      jkm: "6800",
      jht: "48000",
    },
    {
      min: 2500000,
      max: 2699000,
      jkk: "26000",
      jkm: "6800",
      jht: "52000",
    },
    {
      min: 2700000,
      max: 3199000,
      jkk: "29500",
      jkm: "6800",
      jht: "59000",
    },
    {
      min: 3200000,
      max: 3699000,
      jkk: "34500",
      jkm: "6800",
      jht: "69000",
    },
    {
      min: 3700000,
      max: 4199000,
      jkk: "39500",
      jkm: "6800",
      jht: "79000",
    },
    {
      min: 4200000,
      max: 4699000,
      jkk: "44500",
      jkm: "6800",
      jht: "89000",
    },
    {
      min: 4700000,
      max: 5199000,
      jkk: "49500",
      jkm: "6800",
      jht: "99000",
    },
    {
      min: 5200000,
      max: 5699000,
      jkk: "54500",
      jkm: "6800",
      jht: "109000",
    },
    {
      min: 5700000,
      max: 6199000,
      jkk: "59500",
      jkm: "6800",
      jht: "119000",
    },
    {
      min: 6200000,
      max: 6699000,
      jkk: "64500",
      jkm: "6800",
      jht: "129000",
    },
    {
      min: 6700000,
      max: 7199000,
      jkk: "69500",
      jkm: "6800",
      jht: "139000",
    },
    {
      min: 7200000,
      max: 7699000,
      jkk: "74500",
      jkm: "6800",
      jht: "149000",
    },
    {
      min: 7700000,
      max: 8199000,
      jkk: "79500",
      jkm: "6800",
      jht: "159000",
    },
    {
      min: 8200000,
      max: 9199000,
      jkk: "87000",
      jkm: "6800",
      jht: "174000",
    },
    {
      min: 9200000,
      max: 10199000,
      jkk: "97000",
      jkm: "6800",
      jht: "194000",
    },
    {
      min: 10200000,
      max: 11199000,
      jkk: "107000",
      jkm: "6800",
      jht: "214000",
    },
    {
      min: 11200000,
      max: 12199000,
      jkk: "117000",
      jkm: "6800",
      jht: "234000",
    },
    {
      min: 12200000,
      max: 13199000,
      jkk: "127000",
      jkm: "6800",
      jht: "254000",
    },
    {
      min: 13200000,
      max: 14199000,
      jkk: "137000",
      jkm: "6800",
      jht: "274000",
    },
    {
      min: 14200000,
      max: 15199000,
      jkk: "147000",
      jkm: "6800",
      jht: "294000",
    },
    {
      min: 15200000,
      max: 16199000,
      jkk: "157000",
      jkm: "6800",
      jht: "314000",
    },
    {
      min: 16200000,
      max: 17199000,
      jkk: "167000",
      jkm: "6800",
      jht: "334000",
    },
    {
      min: 17200000,
      max: 18199000,
      jkk: "177000",
      jkm: "6800",
      jht: "354000",
    },
    {
      min: 18200000,
      max: 19199000,
      jkk: "187000",
      jkm: "6800",
      jht: "374000",
    },
    {
      min: 19200000,
      max: 20199000,
      jkk: "197000",
      jkm: "6800",
      jht: "394000",
    },
    {
      min: 20200000,
      max: Infinity,
      jkk: "207000",
      jkm: "6800",
      jht: "414000",
    },
  ];

  const totalSalary = getSalaryInMonth(salaryPeriod, salary);

  let jkk = "0";
  let jkm = "0";
  let jht = "0";

  const range = salaryRanges.find(
    (range) => +totalSalary >= +range.min && +totalSalary <= +range.max
  );

  if (range) {
    jkk = range?.jkk;
    jkm = range?.jkm;
    jht = range?.jht;
  } else {
    jkk = "0";
    jkm = "0";
    jht = "0";
  }

  return {
    jkk,
    jkm,
    jht,
  };
}
