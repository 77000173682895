import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import SearchBar from "@Atom/SearchBar";
import { useEffect, useRef } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";

export default function OrderResponsiveModal({
  type,
  handleClose,
  delegationsForm,
  setShowAdd,
  search,
  setSearch,
  setActiveCityID,
  activeCityID,
  positionsForm,
  activePositionID,
  setActivePositionID,
  handleChangePosition,
  setActiveExactPositionID,
  setPositionsForm,
  setSelectedPositionToEdit,
  activeExactPositionID,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
  }, [boxOutsideClick, handleClose]);

  if (type === "deployment") {
    return (
      <div className={Styles.container}>
        <div ref={boxRef}>
          <div className={Styles.headerBox}>
            <div className={Styles.header}>
              <div className={Styles.left}>
                <div className={Styles.iHLogo}>
                  <Icon icon={"contracted"} size={16} color={"#1571DE"} />
                </div>
                <span>Daftar Lokasi</span>
              </div>

              <div className={Styles.iconCross} onClick={handleClose}>
                <Icon icon={"cross"} color={"#FFFFFF"} size={18} />
              </div>
            </div>
            {delegationsForm?.length > 0 ? (
              <div className={Styles.searchBox}>
                <button
                  onClick={() => {
                    setShowAdd(true);
                    handleClose();
                  }}
                >
                  <Icon icon="add-circle" size={20} />
                  <span>Tambah Lokasi</span>
                </button>

                <div className={Styles.searchWrapper}>
                  <SearchBar
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    placeholder={"Cari"}
                  />
                </div>
              </div>
            ) : null}
          </div>

          {delegationsForm?.length > 0 ? (
            <div className={Styles.content}>
              <div className={Styles.iList}>
                {delegationsForm
                  ?.filter((obj) =>
                    obj?.cityName
                      ?.toUpperCase()
                      ?.includes(search?.toUpperCase())
                  )
                  ?.map((d, dI) => (
                    <div
                      key={dI}
                      onClick={() => {
                        setActiveCityID(d?.cityCode);
                        handleClose();
                      }}
                      className={`${Styles.each} ${
                        Styles[activeCityID === d?.cityCode ? "active" : ""]
                      }`}
                    >
                      <Icon icon={"building"} size={20} />
                      <span>{d?.cityName}</span>
                      <Icon icon={"arrow-right"} size={20} />
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className={Styles.emptyHandler}>
              <img src={Images.HIGH_BUILDING} alt="" />
              <span>Belum ada data lokasi ditambahkan</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (type === "position") {
    return (
      <div className={Styles.container}>
        <div ref={boxRef}>
          <div className={Styles.headerBox}>
            <div className={Styles.header}>
              <div className={Styles.left}>
                <div className={Styles.iHLogo}>
                  <Icon icon={"contracted"} size={16} color={"#1571DE"} />
                </div>
                <span>Daftar Posisi</span>
              </div>

              <div className={Styles.iconCross} onClick={handleClose}>
                <Icon icon={"cross"} color={"#FFFFFF"} size={18} />
              </div>
            </div>
            {positionsForm?.length > 0 ? (
              <div className={Styles.searchBox}>
                <button
                  onClick={() => {
                    setShowAdd(true);
                    handleClose();
                  }}
                >
                  <Icon icon="add-circle" size={20} />
                  <span>Tambah Posisi</span>
                </button>

                <div className={Styles.searchWrapper}>
                  <SearchBar
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    placeholder={"Cari"}
                  />
                </div>
              </div>
            ) : null}
          </div>

          {positionsForm?.length > 0 ? (
            <div className={Styles.content}>
              <div className={Styles.iListPosition}>
                {positionsForm
                  ?.filter((obj) =>
                    obj?.positionName
                      ?.toUpperCase()
                      ?.includes(search?.toUpperCase())
                  )
                  ?.map((d, dI) => (
                    <div key={dI} className={`${Styles.each}`}>
                      <div
                        // eslint-disable-next-line eqeqeq
                        className={`${Styles.eHeader}  ${
                          Styles[activePositionID === d?.id ? "active" : ""]
                        }`}
                        onClick={() => {
                          setActivePositionID(d?.id);
                          handleChangePosition(d?.id, "isExpanded", true);
                          setActiveExactPositionID("");
                        }}
                      >
                        <div className={Styles.ehTop}>
                          <Icon icon={"bag-job"} size={20} />
                          <span>{d?.positionName}</span>
                          <div className={Styles?.cityAmount}>
                            {
                              d?.citiesData?.filter((obj) => obj?.isApplied)
                                ?.length
                            }
                          </div>
                          <button
                            onClick={(e) => {
                              e?.stopPropagation();
                              handleChangePosition(
                                d?.id,
                                "isExpanded",
                                !d?.isExpanded
                              );
                            }}
                          >
                            <Icon
                              icon={d?.isExpanded ? "arrow-up" : "arrow-down"}
                              size={20}
                            />
                          </button>
                        </div>
                        <div className={Styles.ehBottom}>
                          <Icon icon={"business-man"} size={20} />
                          <span>{d?.dedicatedROName}</span>
                          {!d?.preventDelete && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setPositionsForm(
                                  positionsForm?.filter(
                                    (obj) => obj?.id !== d?.id
                                  )
                                );
                                setActivePositionID("");
                                setActiveExactPositionID("");
                              }}
                            >
                              <Icon
                                icon={"trash"}
                                size={18}
                                color={"#ff3e13"}
                              />
                            </button>
                          )}

                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPositionToEdit(d);
                              handleClose();
                            }}
                          >
                            <Icon icon={"edit"} size={18} />
                          </button>
                        </div>
                      </div>
                      {d?.isExpanded && (
                        <div className={Styles.eContent}>
                          <div className={Styles.eSearchWrapper}>
                            <OutlinedSearchBar
                              value={d?.search}
                              setValue={(newVal) =>
                                handleChangePosition(d?.id, "search", newVal)
                              }
                              placeholder={"Cari Kota"}
                            />
                          </div>
                          <div className={Styles.ecList}>
                            {d?.citiesData
                              ?.filter((obj) =>
                                d?.search
                                  ? obj?.cityName
                                      ?.toLowerCase()
                                      ?.includes(d?.search?.toLowerCase())
                                  : obj
                              )
                              ?.map((c, cI) => (
                                <div
                                  key={cI}
                                  className={`${Styles.ecCity} ${
                                    activeExactPositionID ===
                                    d?.id + "-" + c?.id
                                      ? Styles.active
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setActivePositionID(d?.id);
                                    setActiveExactPositionID(
                                      d?.id + "-" + c?.id
                                    );
                                    handleClose();
                                  }}
                                >
                                  <div
                                    is-completed={
                                      c?.salaryValue &&
                                      c?.expectedDate &&
                                      c?.salaryDenom &&
                                      !c?.allowances?.find(
                                        (obj) => !obj?.value
                                      ) &&
                                      !c?.personalEquipments?.find(
                                        (obj) => !obj?.value
                                      )
                                        ? "true"
                                        : "false"
                                    }
                                    is-applied={c?.isApplied ? "true" : "false"}
                                  >
                                    <span>{c?.cityName}</span>
                                    {c?.isApplied && (
                                      <Icon
                                        icon={"check-circle-outline-v2"}
                                        size={16}
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className={Styles.emptyHandler}>
              <img src={Images.HIGH_BUILDING} alt="" />
              <span>Belum ada data posisi ditambahkan</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
