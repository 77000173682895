const { API } = require("@Config/api");

export function getPipeline(
  page,
  limit,
  name,
  contractType,
  clientName,
  position,
  stage
) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("limit", limit);
      formData.append("page", page);
      formData.append("name", name || "");
      formData.append("contractType", contractType || "");
      formData.append("clientName", clientName || "");
      formData.append("position", position || "");
      formData.append("stage", stage || "");

      const queryString = new URLSearchParams(formData).toString();

      const { data } = await API.get(`/candidate/pipeline?${queryString}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPipelineDetail(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await API.get(`/candidate/pipeline/${id}`, {
                headers: {
                    // Authorization: sessionStorage.getItem('accessToken'),
                    Authorization: localStorage.getItem('accessToken'),
                },
            });
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}