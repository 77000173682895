import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import SearchBar from '@Atom/SearchBar'
import CustomTable from '@Molecule/CustomTable'
import Images from '@Theme/Images'

export default function DelegationsModal({
  show,
  onClose = () => { },
  data,
  delegationsForm=[],
  setDelegationsForm = ()=> {}
}) {

  const [activeRole, setActiveRole] = useState(data?.deployments[0]?.role)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [selectedPage, setSelectedPage] = useState(1)


  useEffect(() => {
    setActiveRole(data?.deployments[0]?.role)
    setSearch('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const users = useMemo(() => {
    return data?.deployments
      ?.find(obj => obj?.role === activeRole)
      ?.delegations
      ?.filter(obj => obj?.isPicked)
  }, [activeRole, data?.deployments])

  const columns = [
    {
      name: 'name',
      label: 'Name',
      renderData: (row) => (
        <div className={Styles.profile}>
          <img src={Images.AVA_DEFAULT} alt='' />
          <span>{row?.name}</span>
        </div>
      )
    },
    {
      name: '',
      label: '',
      align: 'right',
      renderData: (row) => (
        activeRole !== data?.deployments[0]?.role
          ?
          <button className={Styles.delete} onClick={()=> onSelect(row)}>
            <Icon icon={'cross-circle'} size={16} />
          </button>
          :
          <></>
      )
    }
  ]

  const onSelect = useCallback(
    (user) => {
      // if (!user?.isPicked) {
      const activeData = delegationsForm?.find(obj => obj?.cityCode === data?.cityCode)
      const newData = {
        ...activeData,
        deployments: activeData?.deployments?.map((obj) => {
          if (obj?.role === activeRole) {
            return {
              ...obj,
              delegations: obj?.delegations?.map((u) => {
                if (u?.id === user?.id) {
                  return {
                    ...u,
                    isPicked: !u?.isPicked,
                  };
                } else {
                  return u;
                }
              }),
            };
          } else {
            return obj;
          }
        }),
      };
      // }
      // else {

      // }
      setDelegationsForm(delegationsForm?.map(obj => obj?.cityCode === data?.cityCode ? newData : obj));
    },
    [activeRole, data?.cityCode, delegationsForm, setDelegationsForm]
  );

  return (
    <ModalWrapper show={show} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <Icon icon={'hand-check'} size={24} color={'#1571DE'} />
            <h3>Detail Delegasi • {data?.cityName}</h3>
            <button onClick={onClose}>
              <Icon icon={'cross'} size={24} />
            </button>
          </div>
          <div className={Styles.roles}>
            {
              data?.deployments?.map((d, dI) => (
                <div
                  key={dI}
                  className={`${Styles.role} ${activeRole === d?.role ? Styles.active : ''}`}
                  onClick={() => setActiveRole(d?.role)}
                >
                  <span>{d?.role}</span>
                  {
                    activeRole === d?.role
                    &&
                    <div className={Styles.border} />
                  }
                </div>
              ))
            }
          </div>
        </div>
        <div className={Styles.content}>
          <div className={Styles.searchWrapper}>
            <SearchBar
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
              placeholder={'Search'}
            />
          </div>
          <div>
            <CustomTable
              data={users
                ?.filter((obj) =>
                  obj?.name?.toUpperCase()?.includes(search?.toUpperCase())
                )
                ?.slice((selectedPage - 1) * limit, selectedPage * limit)
              }
              columns={columns}
              totalData={users?.length}
              value={limit}
              setValue={setLimit}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              totalPage={Math.ceil(users?.length / limit) || 0}
              oddRowClassName={Styles.odd}
              withNumbering
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}