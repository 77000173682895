/* eslint-disable no-unused-vars */
import CandidateStatusIndicator from "@Atom/CandidateStatusIndicator";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import SearchBar from "@Atom/SearchBar";
import StackedProfile from "@Atom/StackedProfile";
import Tooltip from "@Atom/Tooltip";
import { fileBaseUrl } from "@Config/api";
import { useDebounce } from "@Hooks/useDebounce";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
import CustomTable from "@Molecule/CustomTable";
import FilterSlider from "@Molecule/FilterSlider";
import MenuListPopup from "@Molecule/MenuListPopup";
import CancelJoinModal from "@Molecule/_modal/BusinessSupport/CancelJoinModal";
import LogModal from "@Molecule/_modal/BusinessSupport/LogModal";
import RejoinModal from "@Molecule/_modal/BusinessSupport/RejoinModal";
import RevertStageModal from "@Molecule/_modal/BusinessSupport/RevertStageModal";
import SecondVerificationModal from "@Molecule/_modal/BusinessSupport/SecondVerificationModal";
import SentContractModal from "@Molecule/_modal/BusinessSupport/SentContractModal";
import ResendWa from "@Molecule/_modal/ResendContratWaConfirm";
import DownloadExcelFilterPopup from "@Molecule/_popup/DownloadExcelFilterPopup";
import {
  getCandidates,
  getCandidatesDataForExport,
  getCandidatesSync,
  resendContract,
} from "@Services/support/candidate";
import Images from "@Theme/Images";
import { Tooltip as TooltipMUI } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import DetailCandidateRecruitment from "@Organism/Recruiter/Recruitment/RecruitmentList/DetailCandidateRekrutment";

const ExcelJS = require("exceljs");

export default function CandidateListLayout({ initialData }) {
  // const [activeCandidateStep, setActiveCandidateStep] = useState(null)
  // const [activeController, setActiveController] = useState(null)

  const query = useQuery();

  const [dataToInject, setDataToInject] = useState(null);

  const [expandFilterSlider, setExpandFilterSlider] = useState(false);
  // const [showRO, setShowRO] = useState(false)
  const [showLog, setShowLog] = useState(false);
  const [expandAction, setExpandAction] = useState(null);

  const [idToCancel, setIdToCancel] = useState(null);
  const [idToRejoin, setIdToRejoin] = useState(null);
  const [idToRevert, setIdToRevert] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedLog, setSelectedLog] = useState(null);

  const maxLength = 20;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");

  const [stage, setStage] = useState("");
  const [search, setSearch] = useState("");
  const [searchName, setSearchName] = useState("");

  const [clientSearch, setClienteSearch] = useState("");
  const [selectedClient, setSelecetedClient] = useState(null);

  const [roSearch, setRoSearch] = useState("");
  const [selectedRo, setSelectedRo] = useState(null);

  const [statusCandidate, setStatusCandidate] = useState([]);

  const [placement, setPlacement] = useState("");
  const [placementObj, setPlacementObj] = useState(null);

  const [positionName, setPossitionName] = useState("");
  const [valueIdCandiate, setValueIdCandidate] = useState(null);

  const [trigger, setTrigger] = useState("");
  const [data, setData] = useState(initialData || null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const debouncedSearch = useDebounce(search, 300);
  const debouncedSearchName = useDebounce(searchName || "", 300);
  const [dataSync, setDataSync] = useState(null);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [selectedButtonStepContract, setSelectedButtonStepContract] =
    useState("Verifikasi Pertama");

  useEffect(() => {
    if (selectedButtonStepContract) {
      setSelectedButtonStepContract(selectedButtonStepContract);
    } else {
      setSelectedButtonStepContract("Verifikasi Pertama");
    }
  }, [selectedButtonStepContract]);

  const refetchDataSync = useCallback(async () => {
    try {
      setIsLoadingSync(true);
      const { response } = await getCandidatesSync(debouncedSearchName || "?");
      setDataSync(response);
      setIsLoadingSync(false);
    } catch (err) {
      setIsLoadingSync(false);
      console.log(err);
    }
  }, [debouncedSearchName]);

  useEffect(() => {
    refetchDataSync();
  }, [refetchDataSync]);

  const refetchData = useCallback(async () => {
    try {
      setIsFetchingData(true);
      const previousPage = page;
      const { response } = await getCandidates(
        limit,
        page,
        selectedButtonStepContract,
        selectedClient?.code || "",
        statusCandidate || [],
        selectedRo?.employeeId || "",
        positionName || "",
        placementObj?.code || "",
        valueIdCandiate?.id || ""
      );
      setData(response);
      setIsFetchingData(false);

      if (response?.totalPage === 1 || response?.totalPage === 0) {
        setPage(1);
      } else {
        const validPage = Math.max(
          1,
          Math.min(previousPage, response?.totalPage)
        );
        setPage(validPage);
      }
    } catch (err) {
      setIsFetchingData(false);
      console.log(err);
    }
  }, [
    limit,
    page,
    selectedButtonStepContract,
    selectedClient?.code,
    statusCandidate,
    selectedRo?.employeeId,
    positionName,
    placementObj?.code,
    valueIdCandiate?.id,
  ]);

  useEffect(() => {
    refetchData();
  }, [refetchData, trigger]);

  const logData = useMemo(() => {
    return [];
    // return [
    //   {
    //     label: 'Verifikasi pertama',
    //     value: moment(new Date()).format('lll'),
    //     remark: null
    //   },
    //   {
    //     label: 'Kontak dibuat',
    //     value: moment(new Date()).format('lll'),
    //     remark: null
    //   },
    //   {
    //     label: 'Kontrak Disetujui BM',
    //     value: moment(new Date()).format('lll'),
    //     remark: null
    //   },
    //   {
    //     label: 'Menunggu verifikasi kedua',
    //     value: moment(new Date()).format('lll'),
    //     remark: null
    //   },
    //   {
    //     label: 'Verifikasi Kedua • With Deviation',
    //     value: moment(new Date()).format('lll'),
    //     remark: 'Catatan: Foto bisa di perjelas lagi'
    //   },
    //   {
    //     label: 'Selesai & Masuk ke IOS/HRIS',
    //     value: moment(new Date()).format('lll'),
    //     remark: null
    //   },
    // ]
  }, []);

  const dataStep = useMemo(() => {
    return [
      {
        id: 1,
        name: "Verifikasi Pertama",
        code: "Verifikasi Pertama",
      },
      {
        id: 2,
        name: "Generate Kontrak",
        code: "Generate Kontrak",
      },
      {
        id: 3,
        name: "TTD Kontrak",
        code: "TTD Kontrak",
      },
      {
        id: 4,
        name: "Verifikasi Kedua",
        code: "Verifikasi Kedua",
      },
      {
        id: 5,
        name: "Joined",
        code: "Joined",
      },
    ];
  }, []);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // useEffect(() => {
  //   if (stage) {
  //     setStatus('')
  //   }
  // }, [stage, setStatus])

  // useEffect(() => {
  //   if (status) {
  //     setStage("");
  //   }
  // }, [status, setStage]);

  const [expandFilter, setExpandFilter] = useState(false);
  const [expandDownload, setExpandDownload] = useState(false);

  useEffect(() => {
    if (expandFilter) {
      setExpandDownload(false);
    }
  }, [expandFilter]);

  useEffect(() => {
    if (expandDownload) {
      setExpandFilter(false);
    }
  }, [expandDownload]);

  // const candidatesStepperOptions = [
  //   {
  //     id: 1,
  //     title: "Register",
  //     label: "by TKO",
  //     value: "REGISTER",
  //   },
  //   {
  //     id: 2,
  //     title: "Verifikasi Pertama",
  //     label: "Business Support",
  //     value: "FIRST-VERIFICATION",
  //   },
  //   {
  //     id: 3,
  //     title: "Generate Kontrak",
  //     label: "Business Support",
  //     value: "GENERATE-CONTRACT",
  //   },
  //   {
  //     id: 4,
  //     title: "Approval Kontrak",
  //     label: "Business Manager",
  //     value: "APPROVE-CONTRACT",
  //   },
  //   {
  //     id: 5,
  //     title: "Tanda Tangan Kontrak",
  //     label: "Kandidat",
  //     value: "SIGN-CONTRACT",
  //   },
  //   {
  //     id: 6,
  //     title: "Verifikasi Kedua",
  //     label: "Business Support",
  //     value: "SECOND-VERIFICATION",
  //   },
  //   {
  //     id: 7,
  //     title: "Selesai",
  //     label: "Sistem",
  //     value: "DONE",
  //   },
  // ];

  const [downloading, setDownloading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getType = (statusFilter, stageFilter, tagFilter) => {
    if (statusFilter?.toUpperCase()?.includes("HOLD")) {
      return "Hold";
    } else if (statusFilter?.toUpperCase()?.includes("CONTRACTED")) {
      return "Contracted";
    } else if (
      statusFilter?.toUpperCase() === "BS-REGISTERED" &&
      stageFilter?.toUpperCase() === "REGISTER"
    ) {
      return "Menunggu Verifikasi";
    } else if (
      statusFilter?.toUpperCase() === "BS-REGISTERED" &&
      stageFilter?.toUpperCase() === "FIRST-VERIFICATION" &&
      tagFilter?.toUpperCase() === "DECLINED"
    ) {
      return "Perbaikan Data";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "GENERATE-CONTRACT" &&
      tagFilter?.toUpperCase() !== "DECLINED"
    ) {
      return "Belum Generate";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "GENERATE-CONTRACT" &&
      tagFilter?.toUpperCase() === "DECLINED"
    ) {
      return "Perbaikan Kontrak";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "APPROVE-CONTRACT"
    ) {
      return "Menunggu Approval BM";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "SIGN-CONTRACT" &&
      tagFilter?.toUpperCase() === "ACCEPTED"
    ) {
      return "Menunggu Verifikasi";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "SECOND-VERIFICATION" &&
      tagFilter?.toUpperCase() === "DECLINED"
    ) {
      return "Perbaikan Data";
    } else if (
      statusFilter?.toUpperCase() === "BS-CONTRACT" &&
      stageFilter?.toUpperCase() === "DONE"
    ) {
      return "Gagal Inject";
    } else {
      return statusFilter;
    }
  };

  const downloadOptions = useMemo(() => {
    return [
      {
        title: downloading ? "Exporting to Excel..." : "Export to Excel",
        disabled: downloading,
        icon: "document-arrow-down",
        iconColor: "#404040",
        textColor: "#0a0a0a",
        action: async () => {
          try {
            setDownloading(true);
            const { response } = await getCandidatesDataForExport(
              status,
              stage,
              debouncedSearch,
              "",
              query.get("df") || "",
              query.get("dt") || "",
              query?.get("dn") || "",
              query?.get("rid") || ""
            );
            const dataToExport = response?.map((obj) => ({
              name: obj.Candidate_Name,
              email: obj.Candidate_Email,
              jobLevel: obj.Job_Position,
              location: obj.Job_Location,
              clientName: obj?.Client_Name,
              roName: obj?.Recruiter_Name,
              roCode: obj?.Recruiter_NRK,
              status: getType(
                obj?.Candidate_Status,
                obj?.Candidate_Stage,
                obj?.Candidate_Tag
              ),
            }));

            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("My Sheet");
            sheet.properties.defaultRowHeight = 80;

            sheet.getRow(1).font = {
              // name: "Noto Sans",
              size: 16,
              bold: true,
            };

            const columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: "Name",
                key: "name",
                width: 20,
              },
              {
                header: "Email",
                key: "email",
                width: 20,
              },
              {
                header: "Position",
                key: "jobLevel",
                width: 20,
              },
              {
                header: "Location",
                key: "location",
                width: 20,
              },
              {
                header: "Client",
                key: "clientName",
                width: 20,
              },
              {
                header: "Recruitment Officer",
                key: "roName",
                width: 20,
              },
              {
                header: "RO Code",
                key: "roCode",
                width: 20,
              },
              {
                header: "Status",
                key: "status",
                width: 20,
              },
            ];

            sheet.columns = columns;

            let promise;

            promise = Promise.all(
              dataToExport?.map(async (el, index) => {
                sheet.addRow({
                  ...el,
                  no: index + 1 + ".",
                });
              })
            );

            promise.then(() => {
              workbook.xlsx.writeBuffer().then(function (report) {
                const blob = new Blob([report], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = `Candidate_List/${stage || "-"}/${
                  status || "-"
                }.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
                setDownloading(false);
                setExpandDownload(false);
              });
            });

            // setDownloading(false)
          } catch (err) {
            setDownloading(false);
          }
        },
      },
      {
        title: "Export Pengalaman Kerja to Excel",
        disabled: false,
        icon: "document-arrow-down",
        iconColor: "#404040",
        textColor: "#0a0a0a",
        action: async () => {
          try {
            setDownloading(true);
            const { response } = await getCandidatesDataForExport(
              status,
              stage,
              debouncedSearch,
              "workExperience",
              query.get("df") || "",
              query.get("dt") || "",
              query?.get("dn") || "",
              query?.get("rid") || ""
            );
            const dataToExport = response?.map((obj) => ({
              name: obj.Candidate_Name,
              email: obj.Candidate_Email,
              phoneNumber: obj?.Candidate_Phone_Number,
              experiences: obj?.Work_Experience?.length
                ? obj?.Work_Experience?.map(
                    (exp) =>
                      `${exp?.Job_Position} (${exp?.Company_Name}/${exp?.Period_Year})`
                  )?.join(", ")
                : "",
              // lastPosition: obj?.Work_Experience[0]?.Job_Position,
              // lastCompany: obj?.Work_Experience[0]?.Company_Name,
              // period: obj?.Work_Experience[0]?.Period_Year
            }));

            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("My Sheet");
            sheet.properties.defaultRowHeight = 80;

            sheet.getRow(1).font = {
              // name: "Noto Sans",
              size: 16,
              bold: true,
            };

            const columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: "Name",
                key: "name",
                width: 20,
              },
              {
                header: "Email",
                key: "email",
                width: 20,
              },
              {
                header: "Phone Number",
                key: "phoneNumber",
                width: 20,
              },
              {
                header: "Experiences",
                key: "experiences",
                width: 50,
              },
              // {
              //   header: 'Last Position',
              //   key: "lastPosition",
              //   width: 20,
              // },
              // {
              //   header: 'Last Company',
              //   key: "lastCompany",
              //   width: 20,
              // },
              // {
              //   header: 'Work Period',
              //   key: "period",
              //   width: 20,
              // },
            ];

            sheet.columns = columns;

            let promise;

            promise = Promise.all(
              dataToExport?.map(async (el, index) => {
                sheet.addRow({
                  no: index + 1 + ".",
                  name: el?.name || "-",
                  email: el?.email || "-",
                  phoneNumber: el?.phoneNumber || "-",
                  experiences: el?.experiences || "-",
                  // lastPosition: el?.lastPosition || '-',
                  // lastCompany: el?.lastCompany || '-',
                  // period: el?.period || '-',
                });
              })
            );

            promise.then(() => {
              workbook.xlsx.writeBuffer().then(function (report) {
                const blob = new Blob([report], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = `Candidate_Work_Experience/${stage || "-"}/${
                  status || "-"
                }.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
                setDownloading(false);
                setExpandDownload(false);
              });
            });

            // setDownloading(false)
          } catch (err) {
            setDownloading(false);
          }
        },
      },
      {
        title: "Export Data Keluarga ke Excel",
        disabled: false,
        icon: "document-arrow-down",
        iconColor: "#404040",
        textColor: "#0a0a0a",
        action: async () => {
          try {
            setDownloading(true);
            const { response } = await getCandidatesDataForExport(
              status,
              stage,
              debouncedSearch,
              "familyMember",
              query.get("df") || "",
              query.get("dt") || "",
              query?.get("dn") || "",
              query?.get("rid") || ""
            );
            const dataToExport = response?.map((obj) => ({
              name: obj.Candidate_Name,
              email: obj.Candidate_Email,
              phoneNumber: obj?.Candidate_Phone_Number,
              familyMembers: obj?.Family_Member?.length
                ? obj?.Family_Member?.map(
                    (m) =>
                      `${m?.Name} (${m?.Relation}/${m?.NIK}/${m?.Phone_Number}/${m?.Address})`
                  )?.join(", ")
                : "",
              // lastPosition: obj?.Work_Experience[0]?.Job_Position,
              // lastCompany: obj?.Work_Experience[0]?.Company_Name,
              // period: obj?.Work_Experience[0]?.Period_Year
            }));

            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("My Sheet");
            sheet.properties.defaultRowHeight = 80;

            sheet.getRow(1).font = {
              // name: "Noto Sans",
              size: 16,
              bold: true,
            };

            const columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: "Name",
                key: "name",
                width: 20,
              },
              {
                header: "Email",
                key: "email",
                width: 20,
              },
              {
                header: "Phone Number",
                key: "phoneNumber",
                width: 20,
              },
              {
                header: "Family Members",
                key: "familyMembers",
                width: 50,
              },
              // {
              //   header: 'Last Position',
              //   key: "lastPosition",
              //   width: 20,
              // },
              // {
              //   header: 'Last Company',
              //   key: "lastCompany",
              //   width: 20,
              // },
              // {
              //   header: 'Work Period',
              //   key: "period",
              //   width: 20,
              // },
            ];

            sheet.columns = columns;

            let promise;

            promise = Promise.all(
              dataToExport?.map(async (el, index) => {
                sheet.addRow({
                  no: index + 1 + ".",
                  name: el?.name || "-",
                  email: el?.email || "-",
                  phoneNumber: el?.phoneNumber || "-",
                  familyMembers: el?.familyMembers || "-",
                  // lastPosition: el?.lastPosition || '-',
                  // lastCompany: el?.lastCompany || '-',
                  // period: el?.period || '-',
                });
              })
            );

            promise.then(() => {
              workbook.xlsx.writeBuffer().then(function (report) {
                const blob = new Blob([report], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = `Candidate_Family_Members/${stage || "-"}/${
                  status || "-"
                }.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
                setDownloading(false);
                setExpandDownload(false);
              });
            });

            // setDownloading(false)
          } catch (err) {
            setDownloading(false);
          }
        },
      },
    ];
  }, [debouncedSearch, downloading, query, stage, status, getType]);

  // console.log(data);
  const [isLoadingCandidate, setIsloadingCandidate] = useState(false);
  const [showConfirmResendCandidate, setShowConfirmResendCandidate] =
    useState("");

  const [successConfirm, setSuccessConfirm] = useState(false);
  const [error, setError] = useState("");

  const handleResendCandidate = useCallback(async (id) => {
    try {
      setIsloadingCandidate(true);
      const payload = {
        candidateID: id,
        type: "candidate",
      };

      await resendContract(payload);
      setIsloadingCandidate(false);
      setShowConfirmResendCandidate("");
      setSuccessConfirm(true);
    } catch (error) {
      console.log(error);
      setIsloadingCandidate(false);
      setError(error?.response?.data?.error || "Something went wrong");
    }
  }, []);

  const [isLoadingBM, setIsloadingBM] = useState(false);
  const [showConfirmResendBM, setShowConfirmResendBM] = useState("");
  const [errorBM, setErrorBM] = useState("");

  const [successConfirmBM, setSuccessConfirmBM] = useState(false);

  const handleResendBM = useCallback(async (id) => {
    try {
      setIsloadingBM(true);
      const payload = {
        candidateID: id,
        type: "businessManager",
      };

      await resendContract(payload);
      setIsloadingBM(false);
      setShowConfirmResendBM("");
      setSuccessConfirmBM(true);
    } catch (error) {
      console.log(error);
      setIsloadingBM(false);
      setErrorBM(error?.response?.data?.error || "Something went wrong");
    }
  }, []);

  useEffect(() => {
    if (showConfirmResendCandidate === "") {
      setError("");
    } else if (showConfirmResendBM === "") {
      setErrorBM("");
    }
  }, [showConfirmResendBM, showConfirmResendCandidate]);

  const findIDCandidate = useMemo(() => {
    if (data?.data?.length > 0) {
      return data?.data?.find(
        (el) =>
          el?.id === showConfirmResendCandidate ||
          el?.id === showConfirmResendBM
      );
    }
  }, [data?.data, showConfirmResendBM, showConfirmResendCandidate]);

  const candidatesTemplate = useMemo(() => {
    if (
      selectedButtonStepContract === "Verifikasi Pertama" ||
      selectedButtonStepContract === "Generate Kontrak" ||
      selectedButtonStepContract === "Verifikasi Kedua"
    ) {
      return {
        data: data?.data?.map((obj) => ({
          ...obj,
          id: obj.id,
          name: obj.name,
          profilePicture: obj.CandidateDocuments
            ? obj?.CandidateDocuments?.url?.includes("http")
              ? obj?.CandidateDocuments?.url?.replace(
                  "https://myworkspace",
                  "https://api1.myworkspace"
                )
              : fileBaseUrl + obj?.CandidateDocuments?.url
            : Images.AVA_DEFAULT,
          phoneNumber: obj.phoneNumber,
          roName: obj?.recruiter?.name,
          roProfilePicture: obj?.recruiter?.profilePicture
            ? obj?.recruiter?.profilePicture?.includes("http")
              ? obj?.recruiter?.profilePicture?.replace(
                  "https://myworkspace",
                  "https://api1.myworkspace"
                )
              : fileBaseUrl + obj?.recruiter?.profilePicture
            : Images.AVA_DEFAULT,
          region: obj?.ExpectedPosition?.City?.name,
          stage: obj?.stage,
          step: obj?.step,
          status: obj?.status,
          jobLevel: obj?.ExpectedPosition?.name,
          clientName: obj?.ExpectedPosition?.OrderScheme?.Client?.name,
          tag: obj?.tag,
          roPhoneNumber: obj?.recruiter?.phoneNumber,
          personId: obj?.personId ? obj?.personId : null,
          portal: obj?.portal?.includes("Peralihan") ? "Peralihan" : "Normal",
          stepStatus: obj?.stepStatus,
          employeeId: obj?.ContractDocument
            ? obj?.ContractDocument.employeeId
            : null,
        })),
        columns: [
          {
            name: "name",
            label: "Nama",
            renderData: (row) => (
              <div className={Styles.stackedProfileRow}>
                <StackedProfile
                  profilePicture={row?.profilePicture}
                  title={row?.name}
                  label={row?.phoneNumber}
                  // extraTitle={
                  //   row?.status === "BS-REGISTERED" ? null : row?.status ===
                  //     "BS-PRE-CONTRACT" ? (
                  //     <CompletionIndicatorIcons times={1} />
                  //   ) : (
                  //     <CompletionIndicatorIcons times={2} />
                  //   )
                  // }
                />
              </div>
            ),
          },
          {
            name: "nrk",
            label: "NRK",
            hideOnMobile: true,
            renderData: (row) => <span>{row?.employeeId || "-"}</span>,
          },
          {
            name: "region",
            label: "Lokasi Kerja",
            hideOnMobile: true,
          },
          {
            name: "clientName",
            label: "Klien",
            hideOnMobile: true,
            renderData: (row) => (
              <div className={Styles.clientNameRow}>
                <TooltipMUI title={<span>{row?.clientName || "-"}</span>}>
                  <span>
                    {row?.clientName
                      ? row?.clientName?.length > maxLength
                        ? row?.clientName
                            ?.substring(0, maxLength)
                            ?.toUpperCase() + "..."
                        : row?.clientName?.toUpperCase()
                      : "-"}
                  </span>
                </TooltipMUI>
              </div>
            ),
          },
          {
            name: "type",
            label: "Tipe",
            hideOnMobile: true,
            renderData: (row) => <span>{row?.portal || "-"}</span>,
          },
          {
            name: "roName",
            label: "RO",
            hideOnMobile: true,
            renderData: (row) => (
              <div className={Styles.roProfileRow}>
                <StackedProfile
                  profilePicture={row?.roProfilePicture}
                  title={row?.roName}
                  label={row?.roPhoneNumber}
                />
              </div>
            ),
          },
          {
            name: "status",
            label: "Status",
            hideOnMobile: true,
            renderData: (row) => (
              <CandidateStatusIndicator
                type={getType(row?.status, row?.stage, row?.tag)}
              />
            ),
          },
          {
            name: "action",
            label: "Aksi",
            renderData: (row) => (
              <div className={Styles.actionsWrapper}>
                {getType(row?.status, row?.stage, row?.tag) === "Hold" ||
                getType(row?.status, row?.stage, row?.tag) === "Contracted" ? (
                  <div className={`${Styles.button} ${Styles.expand}`}>
                    <Icon
                      icon={"ellipsis-horizontal-circle-2"}
                      size={24}
                      color={"#9E9E9E"}
                    />
                  </div>
                ) : getType(row?.status, row?.stage, row?.tag) ===
                  "Menunggu Verifikasi" ? (
                  <Tooltip content={"Verifikasi Data"} noWhiteSpace>
                    <button
                      className={Styles.button}
                      onClick={() => navigate(`${pathname}/${row?.id}`)}
                    >
                      <Icon color={"#616161"} icon={"shield-check"} size={24} />
                    </button>
                  </Tooltip>
                ) : getType(row?.status, row?.stage, row?.tag) ===
                  "Perbaikan Data" ? (
                  <div>
                    <div
                      className={`${Styles.button} ${Styles.expand}`}
                      onClick={() => setExpandAction(row?.id)}
                    >
                      <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                      {expandAction === row?.id && (
                        <div className={Styles.options}>
                          <MenuListPopup
                            withIcon
                            isMultipleSection
                            options={[
                              {
                                title: "Action",
                                options: [
                                  {
                                    title: "Rejoin",
                                    icon: "arrow-right-circle",
                                    iconColor: "#404040",
                                    action: () => {
                                      setIdToRejoin(row?.id);
                                    },
                                  },
                                  {
                                    title: "Verify Data",
                                    icon: "shield-check",
                                    iconColor: "#404040",
                                    action: () => {
                                      window.open(
                                        `${pathname}/${expandAction}`
                                      );
                                      // navigate(`${pathname}/${expandAction}`);
                                    },
                                  },
                                ],
                              },

                              {
                                title: "Kirim Ulang",
                                options: [
                                  {
                                    title: "Kandidat",
                                    icon: "user-group",
                                    iconColor: "#404040",
                                    action: () => {
                                      setShowConfirmResendCandidate(row?.id);
                                    },
                                    // disabled: true,
                                  },
                                ],
                              },
                            ]}
                            onClose={() => setExpandAction(null)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${Styles.button} ${Styles.expand}`}
                    onClick={() => setExpandAction(row?.id)}
                  >
                    <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                    {expandAction === row?.id && (
                      <div className={Styles.options}>
                        <MenuListPopup
                          withIcon
                          isMultipleSection
                          options={[
                            {
                              title: "Action",
                              options: [
                                {
                                  title: "Generate Contract",
                                  icon: "document",
                                  iconColor: "#404040",
                                  action: () => {
                                    window.open(
                                      `${pathname}/${expandAction}?g=true`
                                    );
                                    // navigate(`${pathname}/${expandAction}?g=true`);
                                  },
                                  disabled:
                                    row?.status !== "BS-PRE-CONTRACT" ||
                                    row?.stage !== "GENERATE-CONTRACT",
                                },
                                {
                                  title: "Verify Data",
                                  icon: "shield-check",
                                  iconColor: "#404040",
                                  action: () => {
                                    window.open(`${pathname}/${expandAction}`);
                                    // navigate(`${pathname}/${expandAction}`);
                                  },
                                },
                                {
                                  title: "Batalkan Proses",
                                  icon: "shield-cross",
                                  iconColor: "#D42701",
                                  action: () => {
                                    setIdToRevert(row?.id);
                                  },
                                  disabled:
                                    row?.stage === "FIRST-VERIFICATION" ||
                                    row?.stage === "GENERATE-CONTRACT" ||
                                    row?.stage === "APPROVE-CONTRACT" ||
                                    row?.stage === "SIGN-CONTRACT" ||
                                    row?.stage === "SECOND-VERIFICATION"
                                      ? false
                                      : true,
                                },
                              ],
                            },
                            {
                              title: "Kirim Ulang",
                              options: [
                                {
                                  title: "Business Manager",
                                  icon: "user-circle",
                                  iconColor: "#404040",
                                  action: () => {
                                    setShowConfirmResendBM(row?.id);
                                  },
                                  disabled:
                                    row?.stage === "APPROVE-CONTRACT" &&
                                    row?.status === "BS-PRE-CONTRACT" &&
                                    row?.tag === null
                                      ? false
                                      : true,
                                },
                                {
                                  title: "Kandidat",
                                  icon: "user-group",
                                  iconColor: "#404040",
                                  action: () => {
                                    setShowConfirmResendCandidate(row?.id);
                                  },
                                  disabled:
                                    row?.stage === "SIGN-CONTRACT" &&
                                    row?.tag === "Invited"
                                      ? false
                                      : true,
                                },
                              ],
                            },
                            {
                              title: "Inject",
                              options: [
                                {
                                  title: "Inject Ulang",
                                  icon: "file-inject",
                                  iconColor: "#404040",
                                  action: () => {
                                    setDataToInject(row);
                                  },
                                  disabled:
                                    row?.stage !== "DONE" ||
                                    !row?.ContractDocument?.signature,
                                },
                              ],
                            },
                          ]}
                          onClose={() => setExpandAction(null)}
                        />
                      </div>
                    )}
                  </div>
                )}

                <Tooltip content={"Riwayat Log"} noWhiteSpace>
                  <button
                    className={Styles.button}
                    onClick={() => {
                      setSelectedLog(row);
                      setShowLog(true);
                    }}
                  >
                    <Icon icon={"clock-back"} size={24} />
                  </button>
                </Tooltip>
              </div>
            ),
          },
        ],
      };
    } else if (
      selectedButtonStepContract === "TTD Kontrak" ||
      selectedButtonStepContract === "Joined"
    ) {
      return {
        data: data?.data?.map((obj) => ({
          ...obj,
          id: obj.id,
          name: obj.name,
          profilePicture: obj.CandidateDocuments
            ? obj?.CandidateDocuments?.url?.includes("http")
              ? obj?.CandidateDocuments?.url?.replace(
                  "https://myworkspace",
                  "https://api1.myworkspace"
                )
              : fileBaseUrl + obj?.CandidateDocuments?.url
            : Images.AVA_DEFAULT,
          phoneNumber: obj.phoneNumber,
          roName: obj?.recruiter?.name,
          roProfilePicture: obj?.recruiter?.profilePicture
            ? obj?.recruiter?.profilePicture?.includes("http")
              ? obj?.recruiter?.profilePicture?.replace(
                  "https://myworkspace",
                  "https://api1.myworkspace"
                )
              : fileBaseUrl + obj?.recruiter?.profilePicture
            : Images.AVA_DEFAULT,
          region: obj?.ExpectedPosition?.City?.name,
          stage: obj?.stage,
          step: obj?.step,
          status: obj?.status,
          jobLevel: obj?.ExpectedPosition?.name,
          clientName: obj?.ExpectedPosition?.OrderScheme?.Client?.name,
          tag: obj?.tag,
          roPhoneNumber: obj?.recruiter?.phoneNumber,
          personId: obj?.personId ? obj?.personId : null,
          portal: obj?.portal?.includes("Peralihan") ? "Peralihan" : "Normal",
          stepStatus: obj?.stepStatus,
          employeeId: obj?.ContractDocument
            ? obj?.ContractDocument.employeeId
            : null,
        })),
        columns: [
          {
            name: "name",
            label: "Nama",
            renderData: (row) => (
              <div className={Styles.stackedProfileRow}>
                <StackedProfile
                  profilePicture={row?.profilePicture}
                  title={row?.name}
                  label={row?.phoneNumber}
                  // extraTitle={
                  //   row?.status === "BS-REGISTERED" ? null : row?.status ===
                  //     "BS-PRE-CONTRACT" ? (
                  //     <CompletionIndicatorIcons times={1} />
                  //   ) : (
                  //     <CompletionIndicatorIcons times={2} />
                  //   )
                  // }
                />
              </div>
            ),
          },
          {
            name: "nrk",
            label: "NRK",
            hideOnMobile: true,
            renderData: (row) => <span>{row?.employeeId || "-"}</span>,
          },
          {
            name: "region",
            label: "Lokasi Kerja",
            hideOnMobile: true,
          },
          {
            name: "clientName",
            label: "Klien",
            hideOnMobile: true,
            renderData: (row) => (
              <div className={Styles.clientNameRow}>
                <TooltipMUI title={<span>{row?.clientName || "-"}</span>}>
                  <span>
                    {row?.clientName
                      ? row?.clientName?.length > maxLength
                        ? row?.clientName
                            ?.substring(0, maxLength)
                            ?.toUpperCase() + "..."
                        : row?.clientName?.toUpperCase()
                      : "-"}
                  </span>
                </TooltipMUI>
              </div>
            ),
          },
          {
            name: "type",
            label: "Tipe",
            hideOnMobile: true,
            renderData: (row) => <span>{row?.portal || "-"}</span>,
          },
          {
            name: "roName",
            label: "RO",
            hideOnMobile: true,
            renderData: (row) => (
              <div className={Styles.roProfileRow}>
                <StackedProfile
                  profilePicture={row?.roProfilePicture}
                  title={row?.roName}
                  label={row?.roPhoneNumber}
                />
              </div>
            ),
          },
          // {
          //   name: "status",
          //   label: "Status",
          //   hideOnMobile: true,
          //   renderData: (row) => (
          //     <CandidateStatusIndicator
          //       type={getType(row?.status, row?.stage, row?.tag)}
          //     />
          //   ),
          // },
          {
            name: "action",
            label: "Aksi",
            renderData: (row) => (
              <div className={Styles.actionsWrapper}>
                {getType(row?.status, row?.stage, row?.tag) === "Hold" ||
                getType(row?.status, row?.stage, row?.tag) === "Contracted" ? (
                  <div className={`${Styles.button} ${Styles.expand}`}>
                    <Icon
                      icon={"ellipsis-horizontal-circle-2"}
                      size={24}
                      color={"#9E9E9E"}
                    />
                  </div>
                ) : getType(row?.status, row?.stage, row?.tag) ===
                  "Menunggu Verifikasi" ? (
                  <Tooltip content={"Verifikasi Data"} noWhiteSpace>
                    <button
                      className={Styles.button}
                      onClick={() => navigate(`${pathname}/${row?.id}`)}
                    >
                      <Icon color={"#616161"} icon={"shield-check"} size={24} />
                    </button>
                  </Tooltip>
                ) : getType(row?.status, row?.stage, row?.tag) ===
                    "Perbaikan Data" ||
                  getType(row?.status, row?.stage, row?.tag) ===
                    "Perbaikan Kontrak" ? (
                  <div>
                    <div
                      className={`${Styles.button} ${Styles.expand}`}
                      onClick={() => setExpandAction(row?.id)}
                    >
                      <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                      {expandAction === row?.id && (
                        <div className={Styles.options}>
                          <MenuListPopup
                            withIcon
                            isMultipleSection
                            options={[
                              {
                                title: "Action",
                                options: [
                                  {
                                    title: "Rejoin",
                                    icon: "arrow-right-circle",
                                    iconColor: "#404040",
                                    action: () => {
                                      setIdToRejoin(row?.id);
                                    },
                                  },
                                ],
                              },
                              {
                                title: "Kirim Ulang",
                                options: [
                                  {
                                    title: "Kandidat",
                                    icon: "user-group",
                                    iconColor: "#404040",
                                    action: () => {
                                      setShowConfirmResendCandidate(row?.id);
                                    },
                                    // disabled: true,
                                  },
                                ],
                              },
                            ]}
                            onClose={() => setExpandAction(null)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${Styles.button} ${Styles.expand}`}
                    onClick={() => setExpandAction(row?.id)}
                  >
                    <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                    {expandAction === row?.id && (
                      <div className={Styles.options}>
                        <MenuListPopup
                          withIcon
                          isMultipleSection
                          options={[
                            {
                              title: "Action",
                              options: [
                                {
                                  title: "Generate Contract",
                                  icon: "document",
                                  iconColor: "#404040",
                                  action: () => {
                                    window.open(
                                      `${pathname}/${expandAction}?g=true`
                                    );
                                    // navigate(`${pathname}/${expandAction}?g=true`);
                                  },
                                  disabled:
                                    row?.status !== "BS-PRE-CONTRACT" ||
                                    row?.stage !== "GENERATE-CONTRACT",
                                },
                                {
                                  title: "Verify Data",
                                  icon: "shield-check",
                                  iconColor: "#404040",
                                  action: () => {
                                    window.open(`${pathname}/${expandAction}`);
                                    // navigate(`${pathname}/${expandAction}`);
                                  },
                                },
                                {
                                  title: "Batalkan Proses",
                                  icon: "shield-cross",
                                  iconColor: "#D42701",
                                  action: () => {
                                    setIdToRevert(row?.id);
                                  },
                                  disabled:
                                    row?.stage === "FIRST-VERIFICATION" ||
                                    row?.stage === "GENERATE-CONTRACT" ||
                                    row?.stage === "APPROVE-CONTRACT" ||
                                    row?.stage === "SIGN-CONTRACT" ||
                                    row?.stage === "SECOND-VERIFICATION"
                                      ? false
                                      : true,
                                },
                              ],
                            },
                            {
                              title: "Kirim Ulang",
                              options: [
                                {
                                  title: "Business Manager",
                                  icon: "user-circle",
                                  iconColor: "#404040",
                                  action: () => {
                                    setShowConfirmResendBM(row?.id);
                                  },
                                  disabled:
                                    row?.stage === "APPROVE-CONTRACT" &&
                                    row?.status === "BS-PRE-CONTRACT" &&
                                    row?.tag === null
                                      ? false
                                      : true,
                                },
                                {
                                  title: "Kandidat",
                                  icon: "user-group",
                                  iconColor: "#404040",
                                  action: () => {
                                    setShowConfirmResendCandidate(row?.id);
                                  },
                                  disabled:
                                    row?.stage === "SIGN-CONTRACT" &&
                                    row?.tag === "Invited"
                                      ? false
                                      : true,
                                },
                              ],
                            },
                            {
                              title: "Inject",
                              options: [
                                {
                                  title: "Inject Ulang",
                                  icon: "file-inject",
                                  iconColor: "#404040",
                                  action: () => {
                                    setDataToInject(row);
                                  },
                                  disabled:
                                    row?.stage !== "DONE" ||
                                    !row?.ContractDocument?.signature,
                                },
                              ],
                            },
                          ]}
                          onClose={() => setExpandAction(null)}
                        />
                      </div>
                    )}
                  </div>
                )}

                <Tooltip content={"Riwayat Log"} noWhiteSpace>
                  <button
                    className={Styles.button}
                    onClick={() => {
                      setSelectedLog(row);
                      setShowLog(true);
                    }}
                  >
                    <Icon icon={"clock-back"} size={24} />
                  </button>
                </Tooltip>
              </div>
            ),
          },
        ],
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedButtonStepContract,
    data,
    fileBaseUrl,
    Images,
    maxLength,
    navigate,
    pathname,
    // setExpandAction,
    // setShowConfirmResendBM,
    // setShowConfirmResendCandidate,
    // setDataToInject,
    // setIdToRejoin,
    // setIdToCancel,
    // setSelectedLog,
    // setShowLog,
    // setIdToRevert,
    getType,
  ]);

  // const statusFilter = useMemo(() => {
  //   return statusOptions?.find((obj) => obj?.query === (query.get("ss") || ""))
  //     ?.title;
  // }, [query, statusOptions]);

  const expandDownloadRef = useRef();
  const outsideDownloadRef = UseOutsideClick(expandDownloadRef);

  useEffect(() => {
    if (outsideDownloadRef) {
      setExpandDownload(false);
    }
  }, [outsideDownloadRef]);

  // console.log(selectedLog?.id);

  const onClickOption = (status, stage, tag, jobType, obj, name) => {
    if (
      (status === "BS-REGISTERED" && stage === "REGISTER") ||
      (status === "BS-REGISTERED" &&
        tag === "Declined" &&
        stage === "FIRST-VERIFICATION") ||
      (status?.includes("BS-REGISTERED-HOLD") && stage === "REGISTER") ||
      (status?.includes("BS-REGISTERED-HOLD") &&
        tag === "Declined" &&
        stage === "FIRST-VERIFICATION") ||
      (status?.includes("BS-REGISTERED-CONTRACTED") && stage === "REGISTER") ||
      (status?.includes("BS-REGISTERED-CONTRACTED") &&
        tag === "Declined" &&
        stage === "FIRST-VERIFICATION")
    ) {
      setSelectedButtonStepContract("Verifikasi Pertama");
    } else if (
      (status === "BS-PRE-CONTRACT" &&
        stage === "GENERATE-CONTRACT" &&
        tag !== "Declined") ||
      (status === "BS-PRE-CONTRACT" &&
        tag === "Declined" &&
        stage === "GENERATE-CONTRACT") ||
      (status === "BS-PRE-CONTRACT" && stage === "APPROVE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        tag === "Declined" &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "APPROVE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        tag === "Declined" &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "APPROVE-CONTRACT")
    ) {
      setSelectedButtonStepContract("Generate Kontrak");
    } else if (
      (status === "BS-PRE-CONTRACT" &&
        stage === "SIGN-CONTRACT" &&
        tag === "Invited") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Invited") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Invited")
    ) {
      setSelectedButtonStepContract("TTD Kontrak");
    } else if (
      (status === "BS-PRE-CONTRACT" &&
        stage === "SIGN-CONTRACT" &&
        tag === "Accepted") ||
      (status === "BS-PRE-CONTRACT" &&
        tag === "Declined" &&
        stage === "SECOND-VERIFICATION") ||
      (status === "BS-CONTRACT" && stage === "DONE") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Accepted") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        tag === "Declined" &&
        stage === "SECOND-VERIFICATION") ||
      (status?.includes("BS-CONTRACT-HOLD") && stage === "DONE") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Accepted") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        tag === "Declined" &&
        stage === "SECOND-VERIFICATION") ||
      (status?.includes("BS-CONTRACT-CONTRACTED") && stage === "DONE")
    ) {
      setSelectedButtonStepContract("Verifikasi Kedua");
    } else if (stage === "IOS-SUCCESS") {
      setSelectedButtonStepContract("Joined");
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.stickyTop}>
        {/* <div className={Styles.stepperWrapper}>
          <Stepper
            type={"candidates"}
            disableBorderTop
            disableBorderBottom
            options={candidatesStepperOptions}
            currentStep={
              candidatesStepperOptions?.find((obj) => obj?.value === stage)?.id
            }
            onClick={(id) => {
              // const found = candidatesStepperOptions?.find(obj => obj?.id === id)
              // if (stage === found?.value) {
              //   setStage('')
              // } else {
              //   setStage(found?.value)
              // }
            }}
          />
        </div> */}

        <div className={Styles.controllerWrapper}>
          <div className={Styles.topSearch}>
            <div className={Styles.total}>
              <h4>Total Kandidat</h4>
              <div className={Styles.divider} />
              <div>{data?.totalData || "0"}</div>
            </div>
            <div className={Styles.searchbar}>
              <SearchBar
                placeholder={"Cari Nama Kandidat"}
                value={searchName}
                onChange={setSearchName}
                isRecruitment={true}
                isBS={true}
                options={dataSync?.candidates || []}
                type={"none"}
                isLoadingFilter={isLoadingSync}
                onClickOption={onClickOption}
                setValueIdCandidate={setValueIdCandidate}
                valueIdCandidate={valueIdCandiate}
              />
            </div>
          </div>

          <div className={Styles.lastHeader}>
            <div className={`${Styles.stepperBox}`}>
              {dataStep?.map((el, index) => {
                return (
                  <div
                    className={`${Styles.step}`}
                    key={index}
                    onClick={() => {
                      setSelectedButtonStepContract(el?.code);
                      if (selectedButtonStepContract !== el?.code) {
                        setStatusCandidate([]);
                        setPage(1);
                      }
                    }}
                  >
                    {index === 0 ? (
                      <img
                        src={
                          selectedButtonStepContract === el?.code
                            ? Images.STEPPER_ACTIVE_1
                            : Images.STEPPER_1
                        }
                        alt=""
                      />
                    ) : index === dataStep?.length - 1 ? (
                      <img
                        src={
                          selectedButtonStepContract === el?.code
                            ? Images.STEPPER_ACTIVE_3
                            : Images.STEPPER_3
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        src={
                          selectedButtonStepContract === el?.code
                            ? Images.STEPPER_ACTIVE_2
                            : Images.STEPPER_2
                        }
                        alt=""
                      />
                    )}
                    <div className={`${index === 0 && Styles.leftPlace}`}>
                      <div>{el?.id}</div>
                      <span>{el?.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={Styles.controllers}>
            <div className={Styles.activeFilterWrapper}>
              {statusCandidate?.length === 0 &&
              !roSearch &&
              !clientSearch &&
              !placement &&
              !positionName ? null : (
                <span className={Styles.result}>
                  {data?.totalData} {data?.totalData > 1 ? "Results" : "Result"}
                </span>
              )}

              {statusCandidate?.length === 0 &&
              !roSearch &&
              !clientSearch &&
              !placement &&
              !positionName ? null : (
                <span className={Styles.active}>Active Filter:</span>
              )}

              {statusCandidate?.length > 0 ? (
                <span className={Styles.status}>
                  <span>Status: {statusCandidate?.join(", ")}</span>
                  <Icon
                    icon={"cross"}
                    size={16}
                    className={Styles.icon}
                    onClick={() => {
                      setStatusCandidate([]);
                    }}
                  />
                </span>
              ) : null}

              {roSearch ? (
                <span className={Styles.status}>
                  <span>RO: {roSearch}</span>
                  <Icon
                    icon={"cross"}
                    size={16}
                    className={Styles.icon}
                    onClick={() => {
                      setRoSearch("");
                      setSelectedRo(null);
                    }}
                  />
                </span>
              ) : null}

              {clientSearch ? (
                <span className={Styles.status}>
                  <span>Klien: {clientSearch}</span>
                  <Icon
                    icon={"cross"}
                    size={16}
                    className={Styles.icon}
                    onClick={() => {
                      setClienteSearch("");
                      setSelecetedClient(null);
                    }}
                  />
                </span>
              ) : null}

              {placement ? (
                <span className={Styles.status}>
                  <span>Kota: {placement}</span>
                  <Icon
                    icon={"cross"}
                    size={16}
                    className={Styles.icon}
                    onClick={() => {
                      setPlacement("");
                      setPlacementObj(null);
                    }}
                  />
                </span>
              ) : null}

              {positionName ? (
                <span className={Styles.status}>
                  <span>Posisi: {positionName}</span>
                  <Icon
                    icon={"cross"}
                    size={16}
                    className={Styles.icon}
                    onClick={() => {
                      setPossitionName("");
                    }}
                  />
                </span>
              ) : null}
            </div>

            <div className={Styles.right}>
              <button
                className={Styles.filterButton}
                onClick={() => setExpandFilterSlider(true)}
              >
                <span>Filter</span>
                <Icon icon={"adjustment"} size={24} />
              </button>
              <div className={Styles.divider} />
              <div
                ref={expandDownloadRef}
                className={Styles.downloadExcelWrapper}
              >
                <button
                  className={`${Styles.downloadFilter} ${
                    expandFilter ? Styles.active : ""
                  }`}
                  onClick={() => setExpandFilter(!expandFilter)}
                >
                  <Icon icon={"funnel"} size={20} />
                </button>
                <button
                  className={`${Styles.downloadExpander} ${
                    expandDownload ? Styles.active : ""
                  }`}
                  onClick={() => setExpandDownload(!expandDownload)}
                >
                  <span>Download Excel</span>
                  <Icon
                    icon={expandDownload ? "arrow-up" : "arrow-down"}
                    size={20}
                  />
                </button>

                {expandDownload && (
                  <div className={Styles.downloadPopupWrapper}>
                    <MenuListPopup
                      title="Download Excel"
                      options={downloadOptions}
                      withIcon
                    />
                  </div>
                )}
                {expandFilter && (
                  <div className={Styles.filterPopupWrapper}>
                    <DownloadExcelFilterPopup
                      setShow={setExpandFilter}
                      options={dataSync?.contracts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedButtonStepContract?.includes("Verifikasi") &&
      !isFetchingData &&
      candidatesTemplate?.data?.length > 0 &&
      candidatesTemplate?.data?.filter(
        (el) => el?.stepStatus?.toLowerCase() === "revision"
      )?.length > 0 ? (
        <div className={Styles.warningBox}>
          <div>
            <Icon icon={"information-solid"} size={20} color={"#FFC43B"} />
          </div>
          <span>
            Terdapat Kandidat yang sudah melakukan revisi, mohon untuk melakukan
            pengecekan terhadap kandidat tersebut.
          </span>
        </div>
      ) : null}

      <div className={Styles.tableWrapper}>
        <CustomTable
          data={candidatesTemplate?.data}
          columns={candidatesTemplate?.columns}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={data?.totalPage}
          isV2
          noContainerBorder
          isLoading={isFetchingData}
          // activeController={stage ? null : (status || 'BS-REGISTERED')}
          // setActiveController={setStatus}
          // searchTerm={search}
          // setSearchTerm={setSearch}
          // noStickyPagination={windowSize[0] <= 480 ? true : false}
          stickyTop
          totalData={data?.totalData}
          isBs={true}
        />
      </div>
      {dataToInject && (
        <ModalWrapper
          show={dataToInject}
          handleClose={() => setDataToInject(null)}
        >
          <SecondVerificationModal
            data={dataToInject}
            customID={dataToInject?.id}
            handleClose={() => setDataToInject(null)}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
      )}
      {showConfirmResendCandidate === findIDCandidate?.id && (
        <ResendWa
          handleDone={() => setShowConfirmResendCandidate("")}
          handleClose={() => setShowConfirmResendCandidate("")}
          action={() => {
            handleResendCandidate(findIDCandidate?.id);
          }}
          isLoading={isLoadingCandidate}
          error={error}
        />
      )}

      {showConfirmResendBM === findIDCandidate?.id && (
        <ResendWa
          handleDone={() => setShowConfirmResendBM("")}
          handleClose={() => setShowConfirmResendBM("")}
          action={() => {
            handleResendBM(findIDCandidate?.id);
          }}
          isLoading={isLoadingBM}
          error={errorBM}
        />
      )}

      {successConfirm && (
        <ModalWrapper show={successConfirm} forceCenter>
          <SentContractModal
            onClickBack={() => setSuccessConfirm(false)}
            type={"candidate"}
          />
        </ModalWrapper>
      )}

      {successConfirmBM && (
        <ModalWrapper show={successConfirmBM} forceCenter>
          <SentContractModal
            onClickBack={() => setSuccessConfirmBM(false)}
            type={"bm"}
          />
        </ModalWrapper>
      )}

      {/* <ModalWrapper
        show={showRO}
      >
        <ROCodeModal
          setShow={setShowRO}
          options={sync?.recruitmentOfficers}
        />
      </ModalWrapper> */}
      {/* <ModalWrapper show={showLog}>
        <LogModal setShow={setShowLog} data={logData} />
      </ModalWrapper> */}

      {selectedLog && (
        <ModalWrapper show={selectedLog} handleClose={() => {}}>
          <DetailCandidateRecruitment
            handleClose={() => setSelectedLog(null)}
            showDetailCandidate={selectedLog}
            isBS={true}
          />
        </ModalWrapper>
      )}

      <ModalWrapper show={idToCancel}>
        <CancelJoinModal
          id={idToCancel}
          stage={
            candidatesTemplate?.data?.find((obj) => obj?.id === idToCancel)
              ?.stage
          }
          onClose={() => setIdToCancel(null)}
          setTrigger={setTrigger}
        />
      </ModalWrapper>
      <ModalWrapper show={idToRejoin}>
        <RejoinModal
          id={idToRejoin}
          stage={
            candidatesTemplate?.data?.find((obj) => obj?.id === idToRejoin)
              ?.stage
          }
          onClose={() => setIdToRejoin(null)}
          setTrigger={setTrigger}
        />
      </ModalWrapper>
      <ModalWrapper show={idToRevert}>
        <RevertStageModal
          id={idToRevert}
          onClose={() => setIdToRevert(null)}
          setTrigger={setTrigger}
        />
      </ModalWrapper>

      <FilterSlider
        show={expandFilterSlider}
        type="candidate"
        onOutsideClick={() => setExpandFilterSlider(false)}
        dataSync={dataSync}
        selectedButtonStepContract={selectedButtonStepContract}
        isLoadingSync={isLoadingSync}
        // setStageFilter={setStage}
        // setSearchFilter={setSearch}
        setClientFilter={setClienteSearch}
        clientFilter={clientSearch}
        setRoSearchFilter={setRoSearch}
        roSearchFilter={roSearch}
        setStatusFilter={setStatusCandidate}
        statusFilter={statusCandidate}
        placementFilter={placement}
        setPlacementFilter={setPlacement}
        positionNameFilter={positionName}
        setPossitionNameFilter={setPossitionName}
        selectedClient={selectedClient}
        setSelecetedClient={setSelecetedClient}
        selectedRo={selectedRo}
        setSelectedRo={setSelectedRo}
        placementObj={placementObj}
        setPlacementObj={setPlacementObj}
      />
    </div>
  );
}
